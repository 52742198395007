import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LogoImage from "../headers/common/logo";
import { SlideUpDown } from "../../../services/script";
import moment from "moment";
import {getAllSettings} from "../../../actions";

class ReaslEstateFooter extends Component {
  componentDidMount() {
    var contentwidth = window.innerWidth;
    if (contentwidth < 750) {
      SlideUpDown("footer-title");
    } else {
      var elems = document.querySelectorAll(".footer-title");
      [].forEach.call(elems, function(elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: block";
      });
    }
  }

  render() {
    const {store_address,store_phone,store_email,store_name,instagram_link,youtube_link,twitter_link,facebook_link,footer_text} = this.props.settings;

    const links = this.props.footer_category||[]
    return (
        <footer id="footer_id" className="footer-light pet-layout-footer">
          <section className="section-b-space light-layout">
            <div className="container-fluid">
              <div className="row footer-theme partition-f mx-md-5 mx-sm-1">
                <div className="col-lg-4 col-md-6">
                  {/*<div className="footer-title footer-mobile-title">
                    <h4>about</h4>
                  </div>*/}
                  <div className="footer-contant">
                    <div className="footer-logo">
                      <LogoImage logo={this.props.logoName} />
                    </div>
                    <p>{footer_text == "" ? "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s," : footer_text}</p> {/* todo: hide static data*/}
                    <div className="footer-social ">
                      <ul>
                        <li>
                          <Link to={facebook_link||""} ><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                        </li>
                        <li>
                          <Link to={twitter_link||""}><i className="fa fa-twitter" aria-hidden="true"></i></Link>
                        </li>
                        <li>
                          <Link to={youtube_link||""} ><i className="fa fa-youtube-play" aria-hidden="true"></i></Link>
                        </li>
                        <li>
                          <Link to={instagram_link||""}><i className="fa fa-instagram" aria-hidden="true"></i></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 offset-xl-1">
                  <div className="sub-title">
                    <div className="footer-title">
                      <h4>Know More</h4>
                    </div>
                    <div className="footer-contant">
                      <ul>
                        {/* {links.map((cat, i) => (
                            <li key={i}>
                              <Link to={`${process.env.PUBLIC_URL}/category/${
                                        cat.id
                                    }/products`}
                              >
                                {cat.category_name}
                              </Link>
                            </li>
                        ))} */}
                        <li><Link to={`${process.env.PUBLIC_URL}/pages/about-us`}>About Us</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/pages/terms-and-conditions`}>Terms of Use</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/pages/privacy-policy`}>Privacy policy</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/pages/return-policy`}>Return Policy</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/pages/faq`}>FAQ</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col"> 
                  <div className="sub-title">
                    <div className="footer-title">
                      <h4>Career</h4>
                    </div>
                    <div className="footer-contant">
                      <ul> */}
                        {/* <li><Link to={`${process.env.PUBLIC_URL}/seller-registration`}>Sell on Infrawheel</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/how-to-become-a-service-provider`}>Become Service Provider</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/seller-registration`}>Join Us</Link></li> */}
                        {/* <li><Link to={`${process.env.PUBLIC_URL}/pages/privacy-policy`}>Privacy Policy</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/pages/return-policy`}>Return Policy</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/pages/faq`}>FAQ</Link></li> */}
                      {/* </ul>
                    </div>
                  </div>
                </div> */}
                <div className="col">
                  <div className="sub-title">
                    <div className="footer-title">
                      <h4>Happy to serve you</h4>
                    </div>
                    <div className="footer-contant">
                      <ul>
                      <li><Link to={`${process.env.PUBLIC_URL}/pages/contact`}>Contact Us</Link></li>
                      <li><Link to={`${process.env.PUBLIC_URL}/dashboard/account`}>Your Account</Link></li>
                      <li><Link to={`${process.env.PUBLIC_URL}/dashboard/neworders`}>Your Orders</Link></li>
                      <li><a href={`https://play.google.com/store/apps`} target="_blank">Infrawheel App Download</a></li>
                      <li><a href={`https://play.google.com/store/apps`} target="_blank">Infrawheel Vendor App Download</a></li>
                      <li> <Link to={`${process.env.PUBLIC_URL}/pages/contact`}>Help</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <strong className="footer-copyright-txt">@{ new Date().getFullYear()},  All Copyrights reserved by {store_name}.</strong>
                </div>
              </div>
            </div>
          </section>
        </footer>



    );
  }
}

const mapStateToProps = (state) => ({
  footer_category: state.home.footer_category,
  settings : state.settings,
});

export default connect(
  mapStateToProps,
  {}
)(ReaslEstateFooter);
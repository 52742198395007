import React, { Component } from 'react'
import { getHomeDashboardModules, getHomePageBrands, getHomeSliders, getoffers_home, getservicehomepage } from "../../../actions/index";
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import {
    getOffersList_api, getservicehomepage_api
} from "../../../api/home";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Servicecategory from "../../features/theme/servicecategory";
import categories from "../../features/theme/categories";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ServiceHomePageComp1 from '../../pages/ServiceHomePageComp1';
import ServiceHomePageComp2 from '../../pages/ServiceHomePageComp2';
import ServiceHomePageComp3 from '../../pages/ServiceHomePageComp3';
import serchIcon from "../../../assets/images/search_icon.png"


class ServiceProviderMain extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dashboardData: {}
        };
    }


    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#`);
        // get all home page sliders & brands
        this.props.getservicehomepage();
        this.getSlider()
    }
    getofferlist = async () => {

        try {
            this.setState({ loaderForPlaceOrder: true });
            let apiParams = {
                language_id: 3
            };
            // console.log(apiParams);
            let offerlist = await getOffersList_api(apiParams);
            if (offerlist.success) {

            } else {
                toast.error(offerlist.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }

    };
    getSlider = async () => {

        try {
            this.setState({ loaderForPlaceOrder: true });
            let apiParams = {
                language_id: 3
            };
            // console.log(apiParams);
            let offerlist = await getservicehomepage_api(apiParams);
            this.setState({ dashboardData: offerlist.result.data })
            console.log(offerlist, "service data")

        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }

    };


    render() {
        const { dashboardModulesnew } = this.props;
        const { service } = this.props.service;
        const { dashboardData } = this.state


        let url;
        const settings = {
            dots: true,
            autoplay: false,
            infinite: true,
            lazyLoad: true,
            speed: 3000,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <>{
                dashboardData.slider ?
                    <div>
                        {/*Home Slider*/}
                        <section className='p-0 '>
                            <div className='banner_wrapper'>
                                <div>
                                    <h1 className="banner_txt">SERVICE ON-DEMAND</h1>
                                    <div className="input-group banner-input-group">
                                        <input type="text" className="form-control banner-input" placeholder="Search Here…" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="basic-addon2"> <img src={serchIcon} alt="serchIcon" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <section className='p-0 '>
                            <Slider className='slide-1 home-slider' {...settings}>
                                {dashboardData.slider.map((slider, i) => {
                                    // if (slider.offer_id !== 0 && slider.category_id === 0)
                                    //     url =
                                    //         `${process.env.PUBLIC_URL}/offer/` +
                                    //         slider.offer_id +
                                    //         `/products`;
                                    // else if (slider.category_id !== 0) {
                                    //     url = `category/${slider.category_id}/products`;
                                    // } else {
                                    // }
                                    if (slider.linked_too === "speciality") {
                                        url = `/services/${slider.service_or_speciality.name}/${slider.speciality_id}/0`;
                                    } else {
                                        url = `/services/${slider.service_or_speciality.name}/${slider.speciality_id}/0`;
                                    }
                                    return (
                                        <div key={i}>
                                            <Link to={url}>
                                                <div
                                                    className='home text-center'
                                                    style={{
                                                        backgroundImage: `url(${slider.image_url})`,
                                                    }}
                                                >
                                                    <div className='container'>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <div className='slider-contain'></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </section> */}
                        {/*Home Section End*/}

                        <section>
                            <Servicecategory />
                        </section>
                        <section>
                            <div className='banner-jobsec'>
                                {/* <div className=''>
                                    <h1 className='jobsec-txt'>FROM SMALL TOUCH-UPS TO FULL <br />
                                        PROPERTY REFURBISHMENT</h1>
                                        <h4 className="job-sm-txt">We Make a Neat Job of It All! </h4>
                                </div> */}
                            </div>
                            <ServiceHomePageComp1 servicelist={dashboardData.sp_dashboard} />
                        </section>
                        {/* Section 1 Component */}
                        {/* END Section 1 Component */}



                    </div> : ""
            }</>
        );
    }
}

const mapStateToProps = (state) => ({
    service: state.service
});

export default connect(
    mapStateToProps,
    {
        getservicehomepage
    }
)(ServiceProviderMain);

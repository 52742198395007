import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {resetTheCart} from "../../actions";
import {toast} from "react-toastify";
import {getProductCartTotal} from "../../services";
import {
    declareProductPrice,
    declareOrderType,
    declareAvailability,
    checkAndRedirectLogin,
} from "../../utils/helper";
import {applyGiftCard_api} from "../../api/product";
import Breadcrumb from '../common/breadcrumb'
import moment from "moment";
class OrderSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            delivery_address_id: localStorage.getItem("delivery_address_id"),
            billing_address_id: localStorage.getItem("billing_address_id")
        };
    }

    componentDidMount() {
        if (!checkAndRedirectLogin()) {
            toast.info("Please login to continue");
            this.props.history.push({
                pathname: "/login",
            });
        } else {
            // rset the cart after successful payment
            this.props.resetTheCart();
        }
    }

    declareTaxesOfProduct = () => {
        var total = 0;
        if (this.props.location.state) {
            const {items} = this.props.location.state;
            var taxTypesSum;
            for (var i = 0; i < items.length; i++) {
                taxTypesSum = 0;
                if (items[i].tax_price.length > 0) {
                    for (var j = 0; j < items[i].tax_price.length; j++) {
                        if (items[i].tax_price[j].tax_amount)
                            taxTypesSum += parseInt(items[i].tax_price[j].tax_amount);
                    }
                    total += parseInt(items[i].cart_quantity, 10) * taxTypesSum;
                }
            }
        }
        return total;
    };


    render() {
        console.log(this.props);

        let items = [];
        let order_data = {};
        let orderTotal = 0;
        let coupon_discount = 0;
        let ship_price = 0;
        var deliveryAddress, billingAddress;
        var deliveryaddress_type = "Home";
        var billingaddress_type = "Home";
        let symbol = "";

        let payment;


        var url_string = window.location;
        var url = new URL(url_string);
        var tvid = url.searchParams.get("id");

        console.log("order_data",this.props)
        console.log("order_data",this.props.location)

        if (this.props.location.state && tvid != 1) {
            payment = this.props.location.state.payment;
            symbol = this.props.location.state.symbol;

            items = this.props.location.state.items; // cart data
            orderTotal = this.props.location.state.orderTotal; // total
            coupon_discount = this.props.location.state.coupon_discount; // coupon discount
            ship_price = this.props.location.state.ship_price; // coupon discount
            order_data = this.props.location.state.order_data; // coupon discount


            const {
                delivery_address_id,
                billing_address_id,
            } = this.state;

            deliveryAddress = this.props.location.state.address.find(
                (item) => item.id == delivery_address_id
            );
            if (deliveryAddress.address_type === 2) {
                deliveryaddress_type = "Office";
            } else if (deliveryAddress.address_type === 3) {
                deliveryaddress_type = "Site";
            }
            billingAddress = this.props.location.state.address.find(
                (item) => item.id == billing_address_id
            );
            if (billingAddress.address_type === 2) {
                billingaddress_type = "Office";
            } else if (billingAddress.address_type === 3) {
                billingaddress_type = "Site";
            }
        } else {
            var url_string = window.location;
            var url = new URL(url_string);
            var tvid = url.searchParams.get("id");

            var localdata = localStorage.getItem("state");
            localdata = JSON.parse(localdata);
            items = (localdata && localdata.items) ? localdata.items : []; // cart data

            if (tvid == 1 && (items && items.length >= 1)) {
                var localdata = localStorage.getItem("state");

                localdata = JSON.parse(localdata);
                items = localdata.items; // cart data

                const {
                    delivery_address_id,
                    billing_address_id,
                } = localdata;

                deliveryAddress = localdata.address.find(
                    (item) => item.id == delivery_address_id
                );

                if (deliveryAddress && deliveryAddress.address_type === 2) {
                    deliveryaddress_type = "Office";
                } else if (deliveryAddress.address_type === 3) {
                    deliveryaddress_type = "Site";
                }
                billingAddress = localdata.address.find(
                    (item) => item.id == billing_address_id
                );
                if (billingAddress && billingAddress.address_type === 2) {
                    billingaddress_type = "Office";
                } else if (billingAddress && billingAddress.address_type === 3) {
                    billingaddress_type = "Site";
                }

                localStorage.setItem('state', JSON.stringify([]));

            }
        }

        const {cart_full} = this.props;

        return (this.props.location.state || (tvid == 1 && items && items.length >= 1)) ? (
                <div>
                    <Breadcrumb title={"CART"} />
                    <section className="cart-section section-b-space success-section paddingTop60">
                        <div className="container-fluid">
                            <div className="row mx-sm-3 mx-sm-1">
                                <div className=" col-lg-9 col-md-12 mb-3 leftTab">
                                    <div className="row success-row">
                                        <div className="col-md-2 col-4">
                                            <img
                                                src={`${
                                                    process.env.PUBLIC_URL
                                                }/assets/images/order_success.png`}
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                        <div className="col-md-6 col-8">
                                            <h4 style={{color: "#000"}}>
                                                Order Success{" "}
                                                <i
                                                    className="fa fa-check-circle"
                                                    aria-hidden="true"
                                                    style={{color: "#34c759"}}
                                                />{" "}
                                            </h4>{" "}
                                            {/* <br/> */}
                                            <h6 style={{color: "#000"}}>Thank you</h6> 
                                            {/* <br/> */}
                                            <p style={{color: "#000"}}>For your purchase with Infrawheel</p>
                                        </div>
                                    </div>
                                    <hr className="cart-d-none-mobile" />
                                    <div className="row cart-d-none-mobile">
                                        <div className="col-12 mb-3 success-row-details">
                                            <h5 className="font-weight-bold" style={{color: "#000"}}>Order Details</h5>
                                        </div>
                                        <div className="col-12">
                                            <strong style={{color: "#000"}}> Order Number :{items.orderId} </strong>
                                            <p>An email reciept including the details about your order has been sent to your email.</p>
                                            <br/>
                                        </div>
                                        <div className="col-12">
                                            <strong>Delivery Address</strong><br/>
                                            {deliveryAddress && (
                                                    <>
                                                        <strong className="theme_text_set">
                                                            {deliveryAddress.user_name}  <span className="badge badge-primary" style={{color: "#fff", backgroundColor: "#047bd5"}}>{deliveryaddress_type}</span>
                                                        </strong>{" "}
                                                        <br/>
                                                        {deliveryAddress.address_1} <br/>
                                                        <strong>Phone Number:</strong>{" "}
                                                        <span>{deliveryAddress.mobile_no}</span> <br/>
                                                        <strong>Supervisor Name:</strong>{" "}
                                                        <span>{deliveryAddress.supervision_name}</span> <br/>
                                                        <strong>Supervisor Number:</strong>{" "}
                                                        <span>{deliveryAddress.supervision_mobile_no}</span>
                                                    </>
                                                )}
                                        </div>
                                        <div className="col-12 mt-3">
                                            <strong className="">Billing Address</strong><br/>
                                            {billingAddress && (
                                                    <>
                                                        <strong className="theme_text_set">
                                                            {billingAddress.user_name} <span className="badge badge-primary" style={{color: "#fff", backgroundColor: "#047bd5"}}>{billingaddress_type}</span>
                                                        </strong>{" "}
                                                        <br/>
                                                        {billingAddress.address_1} <br/>
                                                        <strong>Phone Number:</strong>{" "}
                                                        <span>{billingAddress.mobile_no}</span> <br/>
                                                        <strong>Supervisor Name:</strong>{" "}
                                                        <span>{billingAddress.supervision_name}</span> <br/>
                                                        <strong>Supervisor Number:</strong>{" "}
                                                        <span>{billingAddress.supervision_mobile_no}</span>
                                                    </>
                                                )}
                                        </div>
                                        <div className="col-12 mt-3">
                                            <strong className="">Payment Method</strong><br/>
                                            <p>Credit Card ending with 5979</p>
                                        </div>
                                        <div className="col-12 mt-5">
                                            <h5 style={{color: "#000"}} className="font-weight-bold">Order Summary</h5><br/>
                                            {items.map((item, index) => {
                                            return (
                                            <div key={index} className="row">
                                                <div className="col-4">
                                                <Link to={`${process.env.PUBLIC_URL}/product/${item.id}`}>
                                                    <img src={item.product_image} style={{borderRadius: ".4rem"}} alt="" width="100px" height="100px" />
                                                </Link>
                                                </div>
                                                <div className="col-8">
                                                <Link
                                                            to={`${process.env.PUBLIC_URL}/product/${
                                                                item.id
                                                            }`} className="font-weight-bold" style={{color: "#000"}}
                                                        >
                                                            {item.product_name}
                                                        </Link>
                                                        <br/>
                                                        <span>
                                                            Availability: {declareAvailability(item)}
                                                        </span>
                                                        <br/>
                                                        {
                                                            (item && item.option_data && item.option_data.length > 0) ?

                                                                item.option_data.map((opt, i) => {

                                                                    return <span className="d-block">
                                      {(opt.option_name) ? opt.option_name + ": " + opt.option_value_name : ""}

                                    </span>

                                                                })
                                                                : ""
                                                        }
                                                        <span>Order Type: <samp
                                                            className={'check___' + declareAvailability(item)}>{declareOrderType(item)}</samp></span>
                                                        {(item.product_option && item.product_option.length > 0) && (
                                                            <hr className="mb-0"/>
                                                        )}


                                                        {item.delivery_slots.length > 0 && (
                                                            <hr className="mb-0"/>
                                                        )}
                                                        {item.delivery_slots.length > 0 &&
                                                        item.delivery_slots
                                                            .filter((fltr) => fltr.qty > 0)
                                                            .map((opt, i) => {
                                                                return (
                                                                    <span key={i}>
                                      <br/>
                                      <span>
                                        <strong>Slot:</strong>
                                          {i + 1} <strong>Quantity:</strong>
                                          {opt.qty} <strong>Date:</strong>
                                          {opt.date}{" "}
                                      </span>
                                    </span>
                                                                );
                                                            })}
                                                               <span>Get it On by <strong>{moment(item.product_delivery_date).format("DD MMMM YYYY")} </strong></span>
                                                         
                                                    <br/>
                                                    <div>
                                                        <strong>Quantity: {item.cart_quantity}</strong>
                                                        <strong className="theme_text_set float-right"> {symbol}
                                                            {item.cart_quantity * declareProductPrice(item)} </strong>
                                                    </div>

                                                </div>
                                            </div>
                                            )})}
                                        </div>
                                    </div>
                                    <table className="table cart-table table-responsive-md mt-5 mb-5 cart-d-none">
                                        <thead>
                                        <tr className="table-head">
                                            <th scope="col">product Details</th>
                                            {/* <th scope="col" className="text-left">product Details</th> */}
                                            <th scope="col">price</th>
                                            <th scope="col">quantity</th>
                                            <th scope="col">Subtotal</th>
                                        </tr>
                                        </thead>
                                        {items.map((item, index) => {

                                            console.log("item order", item)
                                            return (
                                                <tbody key={index}>
                                                <tr>
                                                    <td>
                                                        <div className="d_flex">
                                                        <div class="small_image_90 d-inline-block">
                                                            <Link to={`${process.env.PUBLIC_URL}/${item.product_slug}/p/${item.id}`}>
                                                                <img src={item.product_image} alt=""/>
                                                            </Link>
                                                        </div>
                                                    {/* </td>
                                                    <td className="text-left d-none-mobile-remove"> */}
                                                    <div className="d-inline-block  w-80 style_text_name pl">
                                                        <Link
                                                            to={`${process.env.PUBLIC_URL}/product/${
                                                                item.id
                                                            }`} className="font-weight-bold" style={{color: "#000"}}
                                                        >
                                                            {item.product_name}
                                                        </Link>
                                                        <br/>
                                                        <span>
                              Availability: {declareAvailability(item)}
                            </span>
                                                        {
                                                            (item && item.option_data && item.option_data.length > 0) ?

                                                                item.option_data.map((opt, i) => {

                                                                    return <span className="d-block">
                                      {(opt.option_name) ? opt.option_name + ": " + opt.option_value_name : ""}

                                    </span>

                                                                })
                                                                : ""
                                                        }
                                                        &nbsp;&nbsp;
                                                        <span>Order Type: <samp
                                                            className={'check___' + declareAvailability(item)}>{declareOrderType(item)}</samp></span>
                                                        
                                                        {(item.product_option && item.product_option.length > 0) && (
                                                            <hr className="mb-0"/>
                                                        )}


                                                        {item.delivery_slots.length > 0 && (
                                                            <hr className="mb-0"/>
                                                        )}
                                                        {item.delivery_slots.length > 0 &&
                                                        item.delivery_slots
                                                            .filter((fltr) => fltr.qty > 0)
                                                            .map((opt, i) => {
                                                                return (
                                                                    <span key={i}>
                                      <br/>
                                      <span>
                                        <strong>Slot:</strong>
                                          {i + 1} <strong>Quantity:</strong>
                                          {opt.qty} <strong>Date:</strong>
                                          {opt.date}{" "}
                                      </span>
                                    </span>
                                                                );
                                                            })}
                                                           
                                                            <span>Get it On by <strong>{moment(item.product_delivery_date).format("DD MMMM YYYY")} </strong></span>
                                                            </div></div>
                                                            <br />
                                                            
                                                    </td>
                                                    <td>
                                                        <h2 className="td-color">
                                                            {symbol}
                                                            {/* {item.product_price} */}
                                                            {declareProductPrice(item)}
                                                        </h2>
                                                    </td>
                                                    <td>
                                                        <div className="qty-box mb-2 float-right">
                                                        <input type="text" className="form-control w-50" value={item.cart_quantity} /> 
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h2 className="">
                                                            {symbol}
                                                            {/* {item.sum} */}
                                                            {item.cart_quantity * declareProductPrice(item)}
                                                        </h2>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            );
                                        })}
                                    </table>

                                    {/* <Link to="/" className="btn-solid btn">
                                        Continue Shopping
                                    </Link> */}
                                </div>
                                <div className="col-lg-3 col-md-12 rightTab">
                                    <div className="p-2 payment-success" style={{border: "1px solid #ced4da"}}>
                                        <h5 className="font-weight-bold " style={{color: "#000"}}>PAYMENT SUMMARY</h5>
                                        <div className="row">
                                            <div className="col-md-6 left">
                                                <strong>SubTotal</strong>
                                            </div>
                                            <div className="col-md-6 right">
                                                <strong className="float-right">{symbol}{parseFloat(orderTotal||0).toFixed(2)}{} </strong>
                                            </div>
                                            <div className="col-md-6 left">
                                                <strong className="">Shipping</strong>
                                            </div>
                                            <div className="col-md-6 right">
                                                <strong className="float-right">{symbol}{parseFloat(order_data.total_ship_price||0).toFixed(2)}</strong>
                                            </div>
                                            {coupon_discount >= 1 ?
                                               <>
                                                   <div className="col-md-6 left">
                                                       <strong className="">Coupon Discount</strong>
                                                   </div>
                                                   <div className="col-md-6 right">
                                                       <strong
                                                           className="float-right text-danger">{symbol}{parseFloat(coupon_discount||0).toFixed(2)}</strong>
                                                   </div>
                                               </>:""
                                            }
                                            <div className="col-md-6 left">
                                                <strong className="">Grand Total</strong>
                                            </div>
                                            <div className="col-md-6 right">
                                                <strong className="float-right">{symbol} {(order_data.total_amount||0).toFixed(2)}</strong>
                                            </div>
                                            <div className="col-12 col-md-12 col-sm-12 lr" style={{paddingTop: "1rem"}}>
                                                <Link to="/"> 
                                                <button type="button" style={{width: "100%", backgroundColor: "#fff", color: "#2472f0", border: "solid 1px #dcdcdc", padding: "10px 10px 10px 10px", fontWeight: "bold"}}> Continue Shopping </button>
                                                </Link>
                                            </div>
                                            <div className="col-12 col-md-12 col-sm-12 rl" style={{paddingTop: "1rem"}}>
                                                <Link to="/dashboard/neworders"> 
                                                <button type="button" style={{width:"100%", backgroundColor: "#2472f0", color: "#fff", border: "solid 1px #dcdcdc", padding: "10px 10px 10px 10px", fontWeight: "bold"}}> Go to My Orders </button>
                                                </Link>
                                            </div>
                                        </div>
                                        <table
                                            style={{
                                                // tableLayout: "fixed",
                                                width: "100%",
                                            }}
                                        >
                                            <tbody>
                                            {/*<tr>
                      <th  className="py-2" >SubTotal</th>
                      <td  className="py-2" style={{textAlign: "right"}}>
                        {symbol} {(cart_full&&cart_full.total)?(cart_full.total+cart_full.total_tax_price):0}
                      </td>
                    </tr>
                    <tr>
                      <th  className="py-2">Grand total excl. taxes</th>
                      <td  className="py-2"  style={{textAlign: "right"}}>
                        {symbol} {(cart_full&&cart_full.total)?cart_full.total:0}
                      </td>
                    </tr>
                    <tr>
                      <th  className="py-2" >Taxes</th>
                      <td  className="py-2"  style={{textAlign: "right"}}>
                        {symbol} {(cart_full&&cart_full.total)?cart_full.total_tax_price:0}
                      </td>
                    </tr>

                    <tr>
                      <th  className="py-2" >Coupon Discount</th>
                      <td  className="py-2 text-danger"  style={{textAlign: "right"}}>
                        {symbol} 0
                      </td>
                    </tr>
                    <tr>
                      <th className="py-2 w-70">Grand total inc. taxes</th>
                      <td className="py-2 w-30 theme_text_ligblue" style={{textAlign: "right"}}>
                        {symbol}{" "}
                        {(cart_full&&cart_full.total)?(cart_full.total+cart_full.total_tax_price-0):0}
                      </td>
                    </tr>*/}
                                            {/* <tr>
                                                <td>
                                                    <Link
                                                        to="/dashboard/orders"
                                                        className="btn-solid btn"
                                                    >
                                                        My orders
                                                    </Link>
                                                </td>
                                            </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div
                                        className="p-2 mt-2 cart-d-none"
                                        style={{border: "1px solid #ced4da"}}
                                    >
                                        <h4 className="font-weight-bold" style={{color: "#000"}}>Delivery Address</h4>
                                        <div className="row mt-1">
                                            <div className="col">
                                                {deliveryAddress && (
                                                    <>
                                                        <strong className="theme_text_set">
                                                            {deliveryAddress.user_name} ({deliveryaddress_type})
                                                        </strong>{" "}
                                                        <br/>
                                                        {`${deliveryAddress.flat_no != null  ? deliveryAddress.flat_no + ", " : ""}` +deliveryAddress.address_1}  <br/>
                                                        <strong style={{color: "#000"}}>Phone Number:</strong>{" "}
                                                        <span>{deliveryAddress.mobile_no}</span> <br/>
                                                        <strong style={{color: "#000"}}>Supervisor Name:</strong>{" "}
                                                        <span>{deliveryAddress.supervision_name}</span> <br/>
                                                        <strong style={{color: "#000"}}>Supervisor Number:</strong>{" "}
                                                        <span>{deliveryAddress.supervision_mobile_no}</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <h4 className="font-weight-bold mt-3" style={{color: "#000"}}>Billing Address</h4>
                                        <div className="row mt-1">
                                            <div className="col">
                                                {billingAddress && (
                                                    <>
                                                        <strong className="theme_text_set">
                                                            {billingAddress.user_name} ({billingaddress_type})
                                                        </strong>{" "}
                                                        <br/>
                                                        {`${billingAddress.flat_no != null  ? billingAddress.flat_no + ", " : ""}` +billingAddress.address_1}  <br/>
                                                        <strong style={{color: "#000"}}>Phone Number:</strong>{" "}
                                                        <span>{billingAddress.mobile_no}</span> <br/>
                                                        <strong style={{color: "#000"}}>Supervisor Name:</strong>{" "}
                                                        <span>{billingAddress.supervision_name}</span> <br/>
                                                        <strong style={{color: "#000"}}>Supervisor Number:</strong>{" "}
                                                        <span>{billingAddress.supervision_mobile_no}</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row">
              <div className="col-md-12">
                <div className="success-text">
                  <i className="fa fa-check-circle" aria-hidden="true" />
                  <h2>thank you</h2>
                  <p>Order Has Been Received Successfully</p>
                  {payment && (
                    <p>
                      Transaction ID:{" "}
                      {payment.paymentID ? payment.paymentID : payment.id}
                    </p>
                  )}
                </div>
              </div>
            </div> */}
                        </div>
                    </section>

                    <section className="section-b-space cart-d-none">
                        <div className="container">
                            <div className="row padding-cls"/>
                        </div>
                    </section>
                </div>
            ) :
            <>

                {(tvid == 2) ?
                    <section className="cart-section section-b-space ">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-9 mb-3">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <img
                                                src={`${
                                                    process.env.PUBLIC_URL
                                                }/assets/images/order_success.png`}
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <h4>
                                                Order not Success{" "}
                                                <i
                                                    className="fa fa-times-circle"
                                                    aria-hidden="true"
                                                    style={{color: "red"}}
                                                />{" "}
                                            </h4>{" "}
                                            <br/>
                                            <h6>Thank you</h6> <br/>
                                            <p>Please try again </p>
                                        </div>
                                    </div>

                                    <Link to="/" className="btn-solid btn">
                                        Continue Shopping
                                    </Link>
                                </div>

                            </div>

                        </div>
                    </section> :

                    <section className="p-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="error-section">
                                        <h1>404</h1>
                                        <h2>page not found</h2>
                                        <a href="/" className="btn btn-solid">
                                            back to home
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </>
    }
}

const mapStateToProps = (state) => ({
    cart_full: state.cartList.cart_full,
});

export default connect(
    mapStateToProps,
    {resetTheCart}
)(OrderSuccess);

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import "./index.scss";
import './App.css';

/*

import './components/layout/icontitle.css';
import './components/layout/nav.css';
import './components/layout/myAccount.css';
import './components/layout/detailDisplay.css';
*/

import axios from 'axios';
import apiurl from './api/ApiConstants'

// Import custom components
import store from "./store";
import translations from "./constants/translations";
import { getAllProducts } from "./actions";
import Landing from "./components/landing";

// Layouts
import Home from "./components/layouts/home/main";
import Home2 from "./components/layouts/home/Home";
import Fashion from "./components/layouts/fashion/main";
import Vegetables from "./components/layouts/vegetables/main";
import Kids from "./components/layouts/kids/main";
import Pets from "./components/layouts/pets/main";
import Furniture from "./components/layouts/furniture/main";
import Watch from "./components/layouts/watch/main";
import Beauty from "./components/layouts/beauty/main";
import Electronic from "./components/layouts/electronic/main";

//Collection Pages
import DashboardProductsCollections from "./components/collection/DashboardProductsCollections";
import CategoryProductsCollections from "./components/collection/CategoryProductsCollections";
import CategoryProductsCollectionsoffer from "./components/collection/CategoryProductsCollectionsoffer";
import CategoryProductsCollectionsbrand from "./components/collection/CategoryProductsCollectionsbrand";
import CategoriesList from "./components/collection/CategoriesList";
import SubCategoriesList from "./components/collection/SubCategoriesList";
import CategoryProductCollectionSearch from "./components/collection/CategoryProductCollectionSearch";
import CollectionLeftSidebar from "./components/collection/collection-left-sidebar";
import CollectionNoSidebar from "./components/collection/collection-no-sidebar";
import CollectionRightSidebar from "./components/collection/collection-right-sidebar";
import CollectionFullWidth from "./components/collection/collection-full-width";
import CollectionMetro from "./components/collection/collection-metro";

// Product Pages
import LeftSideBar from "./components/products/left-sidebar";
import RightSideBar from "./components/products/right-sidebar";
import NoSideBar from "./components/products/no-sidebar";
import LeftImage from "./components/products/left-image";
import RightImage from "./components/products/right-image";
import Accordian from "./components/products/accordian";
import ColumnLeft from "./components/products/column-left";
import ColumnRight from "./components/products/column-right";
import Column from "./components/products/column";
import Vertical from "./components/products/vertical";
import ProductDetail from "./components/products/ProductDetail";
import ProductDetailold from "./components/products/ProductDetailold";

// Features
import Layout from "./components/app";
import Cart from "./components/cart/ProductCartComponentNew";
import Compare from "./components/compare/index";
import wishList from "./components/wishlist/ProductWishList";
import CheckOut from "./components/checkout/CheckOut";
import orderSuccess from "./components/checkout/OrderSuccess";

// Extra Pages
import aboutUs from "./components/pages/about-us";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import PrivacyAndPolicy from "./components/pages/PrivacyAndPolicy";
import ReturnPolicy from "./components/pages/ReturnPolicy";
import PageNotFound from "./components/pages/404";
import lookbook from "./components/pages/lookbook";
import Login from "./components/pages/UserLogin";
import GoogleLogin from "./components/pages/googlogin";
import Register from "./components/pages/UserRegister";
import Search from "./components/pages/search";
import Collection from "./components/pages/collection";
import ForgetPassword from "./components/pages/forget-password";
import Contact from "./components/pages/contact";
import Dashboard from "./components/pages/dashboard";
import Faq from "./components/pages/faq";
import VideoHelp from "./components/pages/VideoHelp";
import VideoHelpDetails from "./components/pages/VideoHelpDetails";
import HelpAndSupport from "./components/pages/HelpAndSupport";
import Testimonial from "./components/pages/Testimonial";
import SellerRegister from "./components/pages/SellerRegister";

// Blog Pages
import RightSide from "./components/blogs/right-sidebar";
import Details from "./components/blogs/details";
import BlogPage from "./components/blogs/blog-page";

// Theme Element
import ElementTitle from "./components/features/theme/element-title";
import ElementBanner from "./components/features/theme/element-banner";
import ElementSlider from "./components/features/theme/element-slider";
import ElementCategory from "./components/features/theme/element-category";
import ElementService from "./components/features/theme/element-service";
import ElementRatio from "./components/features/theme/element-ratio";

// Product Elements
import ElementProductBox from "./components/features/product/element-product-box";
import ElementProductSlider from "./components/features/product/element-product-slider";
import ElementProductNoSlider from "./components/features/product/element-product-no-slider";
import ElementMultipleSlider from "./components/features/product/element-multiple-slider";
import ElementProductTab from "./components/features/product/element-product-tab";

// Portfolio Features
import GridCols from "./components/features/portfolio/grid-cols";
import MasonaryGridCols from "./components/features/portfolio/masonary-grid-cols";

// Auth Pages
import MyDashboard from "./components/pages/MyDashboard";
import EditProfile from "./components/pages/EditProfile";
import ManageAddress from "./components/pages/ManageAddress";
import ListOrders from "./components/pages/ListOrders";
import ListOrdersExtra from './components/pages/ListOrdersExtra';
// import NewProductDetails from "./components/pages/NewProductDetails"
import Services from "./components/pages/Services";
import ServiceProductListing from "./components/collection/common/ServiceProductListing";
import ServiceProviders from "./components/pages/ServiceProviders";
import ServiceProvidersDetail from "./components/pages/ServiceProvidersDetail";
import NewMyOrders from "./components/pages/NewMyOrders";
import NewOrderDetails from './components/pages/NewOrderDetails'
import WorkPortfolio from "./components/pages/WorkPortfolio";
import ServiceProviderMain from "./components/layouts/fashion/ServiceProviderMain";
import SerivcePortfolioDetails from "./components/pages/SerivcePortfolioDetails";
import SpecialityListing from "./components/pages/SpecialityListing";
import ListGiftCard from "./components/pages/ListGiftCard";
import CreateGiftCard from "./components/pages/CreateGiftCard";
import DownloadApp from "./components/pages/DownloadApp";
import HowToBecomeAServiceProvider from "./components/pages/HowToBecomeAServiceProvider";
import NewListOrder from "./components/pages/NewListOrder";
import SummaryCheckout from "./components/checkout/SummaryCheckout";
import PaymentOptionAndGiftCard from "./components/checkout/PaymentOptionAndGiftCard";
import SubServices from "./components/pages/SubServices";
import ServiceProviderType from "./components/pages/ServiceProviderType";


axios.defaults.baseURL = apiurl.BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';


class Root extends React.Component {
  render() {

    store.dispatch(getAllProducts());

    return (
      <Provider store={store}>
        <IntlProvider translations={translations} locale="en">
          <BrowserRouter basename={"/"}>
            <ScrollContext>
              <Switch>
                {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Landing}/> */}
                {/* <Route
                  path={`${process.env.PUBLIC_URL}/vegetables`}
                  component={Vegetables}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/electronic`}
                  component={Electronic}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/furniture`}
                  component={Furniture}
                /> */}
                <Route
                  path={`${process.env.PUBLIC_URL}/pets`}
                  component={Pets}
                />
                {/* <Route
                  path={`${process.env.PUBLIC_URL}/watch`}
                  component={Watch}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/kids`}
                  component={Kids}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/beauty`}
                  component={Beauty}
                /> */}

                <Layout>
                  {/*Landing page*/}
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    component={Fashion}
                  />

                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/home`}
                    component={Home}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/home2`}
                    component={Home2}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/login`}
                    component={Login}
                  />


                  <Route
                    path={`${process.env.PUBLIC_URL}/googlelogin`}
                    component={GoogleLogin}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/register`}
                    component={Register}
                  />

                  {/* Auth Pages */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard/account`}
                    component={MyDashboard}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard/profile/edit`}
                    component={EditProfile}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard/address`}
                    component={ManageAddress}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard/orders`}
                    component={ListOrders}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard/neworders`}
                    component={NewListOrder}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard/neworderdetials/:id`}
                    component={NewOrderDetails}
                  />
                  {/* <Route
                    path={`${process.env.PUBLIC_URL}/dashboard/orders2`}
                    component={ListOrdersExtra}
                  />*/}


                  <Route
                    path={`${process.env.PUBLIC_URL}/product/:id`}
                    component={ProductDetail}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/:name/o/:id`}
                    component={ProductDetail}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/:name/p/:id`}
                    component={ProductDetailold}
                  />
                  {/*Routes For Dahsboard Product Listing */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard/:id/products`}
                    component={DashboardProductsCollections}
                  />
                  {/*Routes For Category Product Listing */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/category/:id/products`}
                    component={CategoryProductsCollections}
                  />


                  {/*Routes For Category Product Listing */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/offer/:id/products`}
                    component={CategoryProductsCollectionsoffer}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/brand/:id/products`}
                    component={CategoryProductsCollectionsbrand}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/search`}
                    component={CategoryProductCollectionSearch}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/categories-list`}
                    component={CategoriesList}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/sub-categories-list/:id`}
                    component={SubCategoriesList}
                  />

                  {/*Routes For Layouts*/}
                  <Route
                    path={`${process.env.PUBLIC_URL}/fashion`}
                    component={Fashion}
                  />

                  {/*Routes For Features (Product Collection) */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/left-sidebar/collection`}
                    component={CollectionLeftSidebar}
                  />
                  {/* <Route
                    path={`${process.env.PUBLIC_URL}/no-sidebar/collection`}
                    component={CollectionNoSidebar}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/right-sidebar/collection`}
                    component={CollectionRightSidebar}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/full-width/collection`}
                    component={CollectionFullWidth}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/metro/collection`}
                    component={CollectionMetro}
                  /> */}

                  {/*Routes For Single Product*/}
                  <Route
                    path={`${process.env.PUBLIC_URL}/left-sidebar/product/:id`}
                    component={LeftSideBar}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/left-image/product/:id`}
                    component={LeftImage}
                  />
                  {/* <Route
                    path={`${process.env.PUBLIC_URL}/right-sidebar/product/:id`}
                    component={RightSideBar}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/no-sidebar/product/:id`}
                    component={NoSideBar}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/col-left/product/:id`}
                    component={ColumnLeft}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/col-right/product/:id`}
                    component={ColumnRight}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/accordian/product/:id`}
                    component={Accordian}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/column/product/:id`}
                    component={Column}
                  />
                 
                  <Route
                    path={`${process.env.PUBLIC_URL}/right-image/product/:id`}
                    component={RightImage}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/vertical/product/:id`}
                    component={Vertical}
                  /> */}

                  {/*Routes For custom Features*/}
                  <Route path={`${process.env.PUBLIC_URL}/cart`}
                    component={Cart}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/wishlist`}
                    component={wishList}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/compare`}
                    component={Compare}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/checkout`}
                    component={CheckOut}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/checkout-summary`}
                    component={SummaryCheckout}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/checkout-payment`}
                    component={PaymentOptionAndGiftCard}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/order-success`}
                    component={orderSuccess}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/sales/orders`}
                    component={aboutUs}
                  />

                  {/*Routes For Extra Pages*/}
                  <Route
                    path={`${process.env.PUBLIC_URL}/create-giftcard`}
                    component={CreateGiftCard}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/download-app`}
                    component={DownloadApp}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/how-to-become-a-service-provider`}
                    component={HowToBecomeAServiceProvider}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/list-giftcard`}
                    component={ListGiftCard}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/services/:name/:id`}
                    component={Services}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/sub-services/:name/:id/:sub`}
                    component={SubServices}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/services`}
                    exact component={Services}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/speciality-listing`}
                    component={SpecialityListing}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/service-providers/:id/:spType`}
                    component={ServiceProviders}
                  />
                  <Route path={`${process.env.PUBLIC_URL}/service-providertype/:id`} component={ServiceProviderType} />
                  <Route
                    path={`${process.env.PUBLIC_URL}/work-portfolio/:id`}
                    component={WorkPortfolio}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/portfolio-details/:id`}
                    component={SerivcePortfolioDetails}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/service-provider-home`}
                    component={ServiceProviderMain}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/new-myorders`}
                    component={NewMyOrders}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/new-orderdetails`}
                    component={NewOrderDetails}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/service-providers-details/:id`}
                    component={ServiceProvidersDetail}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/about-us`}
                    component={aboutUs}
                  />


                    <Route
                    path={`${process.env.PUBLIC_URL}/pages/spabout-us`}
                    component={aboutUs}
                  />
                   <Route
                    path={`${process.env.PUBLIC_URL}/pages/spprivacy-policy`}
                    component={PrivacyAndPolicy}
                  />

<Route
                    path={`${process.env.PUBLIC_URL}/pages/spterms-and-conditions`}
                    component={TermsAndConditions}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/terms-and-conditions`}
                    component={TermsAndConditions}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/privacy-policy`}
                    component={PrivacyAndPolicy}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/terms-and-conditions`}
                    component={TermsAndConditions}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/seller-registration`}
                    component={SellerRegister}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/return-policy`}
                    component={ReturnPolicy}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/404`}
                    component={PageNotFound}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/lookbook`}
                    component={lookbook}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/login`}
                    component={Login}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/register`}
                    component={Register}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/search-ols`}
                    component={Search}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/collection`}
                    component={Collection}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/forget-password`}
                    component={ForgetPassword}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/contact`}
                    component={Contact}
                  />
                   <Route
                    path={`${process.env.PUBLIC_URL}/pages/spcontact`}
                    component={Contact}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/dashboard`}
                    component={Dashboard}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/faq`}
                    component={Faq}
                  />

                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/video-help`}
                    component={VideoHelp}
                  />

                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/video-help/:id`}
                    component={VideoHelpDetails}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/help-and-support`}
                    component={HelpAndSupport}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/testimonial`}
                    component={Testimonial}
                  />


                  {/*Features*/}
                  {/*Theme Elements*/}
                  {/* <Route
                    path={`${process.env.PUBLIC_URL}/features/element-title`}
                    component={ElementTitle}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/features/element-banner`}
                    component={ElementBanner}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/features/element-slider`}
                    component={ElementSlider}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/features/element-category`}
                    component={ElementCategory}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/features/element-service`}
                    component={ElementService}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/features/element-ratio`}
                    component={ElementRatio}
                  /> */}

                  {/*Product Elements*/}
                  {/* <Route
                    path={`${
                      process.env.PUBLIC_URL
                    }/features/element-product-box`}
                    component={ElementProductBox}
                  />
                  <Route
                    path={`${
                      process.env.PUBLIC_URL
                    }/features/element-product-slider`}
                    component={ElementProductSlider}
                  />
                  <Route
                    path={`${
                      process.env.PUBLIC_URL
                    }/features/element-product-no-slider`}
                    component={ElementProductNoSlider}
                  />
                  <Route
                    path={`${
                      process.env.PUBLIC_URL
                    }/features/element-product-multiple-slider`}
                    component={ElementMultipleSlider}
                  />
                  <Route
                    path={`${
                      process.env.PUBLIC_URL
                    }/features/element-product-tab`}
                    component={ElementProductTab}
                  /> */}

                  {/*Portfolios*/}
                  {/* <Route
                    path={`${
                      process.env.PUBLIC_URL
                    }/features/portfolio-grid/:columns`}
                    component={GridCols}
                  />
                  <Route
                    path={`${
                      process.env.PUBLIC_URL
                    }/features/portfolio-masonary/:columns`}
                    component={MasonaryGridCols}
                  /> */}

                  {/*Blog Pages*/}
                  <Route
                    path={`${process.env.PUBLIC_URL}/blog/right-sidebar`}
                    component={RightSide}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/blog/details`}
                    component={Details}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/blog/blog-page`}
                    component={BlogPage}
                  />

                  {/* <Route exact path="*" component={PageNotFound} /> */}

                </Layout>
              </Switch>
            </ScrollContext>
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));

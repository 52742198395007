import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { IntlActions } from "react-redux-multilingual";
import Pace from "react-pace-progress";
// Import custom components
import store from "../../../store";
import NavBar from "./common/HomeNavBar";
import SideBar from "./common/HomeSideBar";
import CartContainer from "./../../../containers/ProductCartContainer";
import TopBar from "./common/topbar";
import Search_header from "./Search_header";
import {
  changeCurrency,
  getHomeFooterCats,
  getHomeHeaderMainMenu,
  getHomeSidebarCategories,
  setUserLogout,
  setCartdata, getHomePageBrands, getHomeSliders, getHomeDashboardModules,
} from "../../../actions";
import { getCartList_api } from "../../../api/auth";
import { connect } from "react-redux";
import LogoImage from "./common/logo";
import * as storageUtils from "../../../utils/storageUtils";
import { toast } from "react-toastify";

class RealEstateHeader extends Component {
  constructor(props) {
    super(props);
    this.showSearch = this.showSearch.bind(this);
    this.state = {
      isLoading: false,
      search: false
    };
  }

  showSearch() {
    this.setState({
      search: !this.state.search
    });
}

  /* =========================
    Pre loader
  ========================== */
  componentDidMount() {
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);

    // get footer categories
    this.props.getHomeFooterCats();
    this.props.getHomeHeaderMainMenu();
    this.props.getHomeSidebarCategories();
    this.props.getHomePageBrands();

    // get cart data if logged in
    if (this.props.auth.isLoggedIn) {
      this.setCartData();
    }
  }


  setCartData = async () => {
    try {
      // get cart list
      let apiParams = {
        user_id: this.props.auth.userData.id,
      };
      let apiData = await getCartList_api(apiParams);

      if(apiData.useractive == true){    
        if (apiData.success) {
          let data = JSON.parse(JSON.stringify(apiData.data));
          for (let index = 0; index < data.length; index++) {
            for (const x in data[index].product_option) {
              data[index].product_option[x].selected_options = [];
            }
            data[index].delivery_slots = [];
          }
          this.props.setCartdata(data);
      }
      }else{
            storageUtils.unsetUserData();
            storageUtils.unsetUserToken();
            this.props.setUserLogout();
            toast.error("Your Account Deactivate plz Contact Administrator");
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (number >= 300) {
      if (window.innerWidth < 576) {
        document.getElementById("sticky").classList.remove("fixed");
      } else document.getElementById("sticky").classList.add("fixed");
    } else {
      document.getElementById("sticky").classList.remove("fixed");
    }
  };

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    document.documentElement.classList.add("cm_overflow");
    if (openmyslide) {
      openmyslide.classList.add("open-side");

    }
  }
  openSearch() {
    document.getElementById("search-overlay").style.display = "block";
  }

  closeSearch() {
    document.getElementById("search-overlay").style.display = "none";
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };

  handleLogout = () => {
    localStorage.removeItem('cartlist');
    storageUtils.unsetUserData();
    storageUtils.unsetUserToken();
    this.props.setUserLogout();

    toast.success("You have logged out successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  render() {

    const {dashboardModulesnew,store_name} = this.props
    return (
      <div id="header_id">
        {/* <header id="sticky" className="sticky"> */}
        <header id="sticky" >
          {this.state.isLoading ? <Pace color="#27ae60" /> : null}
          <div className="mobile-fix-option" />
          {/*Top Header Component*/}
          <TopBar {...this.props} handleLogout={this.handleLogout} />

          <div>
          <div className="container-fluid">
            <div className="row mx-3 ">
              <div className="col-sm-12 ">
                <div className="main-menu border-section border-top-0 header-float-left">
                  <div className="menu-left">
                    <div className="navbar">

                      <a href="javascript:void(0)" onClick={this.openNav}>
                        <div className="bar-style">
                          {" "}
                          <i
                            className="fa fa-bars sidebar-bar"
                            aria-hidden="true"
                            style={{color: "#777777"}}
                          />
                        </div>
                      </a>
                      {/*SideBar Navigation Component*/}
                      <SideBar {...this.props} />
                    </div>
                    <div className="brand-logo layout2-logo cart-d-none">
                      <LogoImage logo={this.props.logoName} />
                    </div>
                  </div>
                  <div>
                    <Search_header class={"form_search cart-d-none"} {...this.props}/>
                  </div>
                  <div className="menu-right pull-right">
                    <div>
                      <div className="icon-nav icon-nav-responsive">
                        <ul>

                          {/* <li className="onhover-div mobile-dlv ">
                            <div className="placeholderdiv">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/map.png`} id="location" className="img-fluid" alt=""/> */}
                              {/* <i
                                className="fa fa-map-marker pr-1"
                                id="location"
                                aria-hidden="true"
                              /> */}
                              {" "}
                              {/* <b id="delto"> Deliver to
                              <p>USA</p>
                              </b>
                            </div>
                            <div className="show-div setting">
                              <h6>Country</h6>
                              <ul>
                                <li>
                                  <a
                                    href={null}
                                    onClick={() => this.changeLanguage("en")}
                                  >
                                    India
                                  </a>{" "}
                                </li>
                                <li>
                                  <a
                                    href={null}
                                    onClick={() => this.changeLanguage("fn")}
                                  >
                                    USA
                                  </a>{" "}
                                </li>
                                <li>
                                  <a
                                    href={null}
                                    onClick={() => this.changeLanguage("fn")}
                                  >
                                    Canada
                                  </a>{" "}
                                </li>
                                <li>
                                  <a
                                    href={null}
                                    onClick={() => this.changeLanguage("fn")}
                                  >
                                    Singapore
                                  </a>{" "}
                                </li>
                              </ul>
                            </div>
                          </li> */}
                          {/*hide  Deliver to for now */}
                          {/*Header Cart Component */}
                          <CartContainer />
                        </ul>


                      </div>
                    </div>
                  </div>
                </div>
                <div className="brand-logo layout2-logo cart-d-none-mobile">
                      <LogoImage logo={this.props.logoName} />
                </div>
              </div>
            </div>
          </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="main-nav-center justify-content-md-start ">
                  <NavBar {...this.props} show={this.showSearch} />
                </div>
                {this.state.search ?
                <div className="col-12">
                  <Search_header class={"form_search w-100"} {...this.props} />
                </div> : ""
                }
              </div>
            </div>
          </div>
        </header>

        <div id="search-overlay" className="search-overlay">
          <div>
            <span
              className="closebtn"
              onClick={this.closeSearch}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <form>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Search a Product"
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        <i className="fa fa-search" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mainMenu: state.home.mainMenu,
  brands: state.home.brands,
  sideBarMenus: state.home.sideBarMenus,
  dashboardModulesnew: state.home.dashboardModulesnew,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {
    changeCurrency,
    getHomeFooterCats,
    getHomeHeaderMainMenu,
    getHomeSidebarCategories,
    setUserLogout,
    setCartdata,
    getHomePageBrands,
  }
)(RealEstateHeader);


import React, { Component } from 'react'
import Slider from 'react-slick'
import Breadcrumb from '../common/breadcrumb'
import { getserviceListById_api, getserviceprovider_by_id, getserviceprovidersdetails_api } from "../../api/home"
import { toast } from "react-toastify";
import telephone from "../../access/icone/telephone.webp"
import provider_details from "../../access/icone/provider_details.png"

import facebook from '../../assets/images/facebook.png';
import insta from '../../assets/images/insta.png';
import youtube from '../../assets/images/youtube.png';

export default class ServiceProvidersDetail extends Component {
    state = {
        providerDetails: [],
    }

    componentDidMount() {
        this.serviceProviderDetails()
    }

    serviceProviderDetails = async () => {

        try {
            // this.setState({loaderForPlaceOrder: true});

            // console.log(apiParams);
            let serviceProviderDetails = await getserviceprovider_by_id(this.props.match.params.id);
            if (serviceProviderDetails.status) {
                this.setState({
                    providerDetails: serviceProviderDetails.result.userDetail,
                });
            } else {
                toast.error(serviceProviderDetails.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }

    };

    redirect(id) {
        this.props.history.push(`/portfolio-details/${id}`);
    }


    render() {
        let details = this.state.providerDetails
        return (
            <div>
                <Breadcrumb title={"Portfolio Details"} />
                {/* { this.state.providerDetails && this.state.providerDetails.map((details) => */}
                {/* <> 
                <div className="cart-d-none serVp_D">
                    <img className="service-provider-detail-img" src={details.sp_photo && details.sp_photo ? details.sp_photo : provider_details} 
                    >
                    </img>
                        <div className="container-fluid service-provider-shadow-boxUp">
                            <div className='service-provider-shadow-box'>
                                <div className="row justCent">
                                    <div className="col-md-3 servInd">
                                        <div className="service-provider-divider ">
                                            <h4 className="service-provider-title-1" style={{color: "#000"}}>{details.sp_full_name}</h4>
                                            <small>{details.sp_email}</small>
                                            <p className="service-provider-title-1-p break-all-words">{details.sp_block + "," + details.sp_street + "," + details.sp_landmark + "," + details.city + "," + details.state + "-" + details.pincode}</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 service-provider-divider servInd">
                                        <div className=''>
                                            <div className='pb20'>
                                                <h4 className="service-provider-title-2" style={{color: "#000"}}>Job Desription</h4>
                                                <small>{details.job_decription}</small>
                                            </div>
                                            <div  className='pb20'>
                                                <h4 className="service-provider-title-2" style={{color: "#000"}}>Experties</h4>
                                                <small>{details.expertise_business}</small>
                                            </div>
                                        </div>
                                    </div> 

                                    <div className="col-md-3 servInd">
                                        <div className="service-provider-padding ">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h4 className="service-provider-title-3">
                                                        Experience
                                                    </h4>
                                                </div>
                                                <div className="col-md-12 pb20">
                                                    <h4 className="service-provider-title-3-sub">
                                                        {details.no_year_experience} Years
                                                    </h4>
                                                </div>
                                                <div className="col-md-12">
                                                    <h4 className="service-provider-title-3">
                                                        Projects Completed
                                                    </h4>
                                                </div>
                                                <div className="col-md-12 pb20">
                                                    <h4 className="service-provider-title-3-sub">
                                                        {details.no_project_completed} Project
                                                    </h4>
                                                </div>
                                                <div className="col-md-12 ">
                                                    <h4 className="service-provider-title-3">
                                                        NO. OF Brand WORKS
                                                    </h4>
                                                </div>
                                                <div className="col-md-12 ">
                                                    <h4 className="service-provider-title-3-sub">
                                                        {details.no_brand} BRAND
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>   

                                </div>
                            </div>
                        </div>

                        <div className="row" style={{marginRight: "0"}}>
                            <div className="col-md-12 text-center service-provider-btns">
                            <button type="button" onClick={()=> this.redirect(details.id)} 
                            className="btn btn-solid mr-2">
                                View Portfolio
                            </button>
                            <button type="button" className="btn btn-success ml-2 btn-padding">                                
                                <span style={{padding: ".9rem"}}> 
                                    <img src={telephone} className="float-left pr-1" />Call Now
                                </span>
                            </button>
                            </div>
                        </div>
                </div>
                <div className="container cart-d-none-mobile serVp_M">
                    <div className="row">
                        <div className="col-12 imgCent">
                            <img src={details.sp_photo} width="350" height="200" style={{borderRadius: "10px"}} />
                        </div>
                        <div className="container servpCont">
                            <div className="row">
                                <div className="col-12 pt-3">
                                    <strong className="theme_text_set dtName">{details.sp_full_name}</strong>
                                </div>
                                <div className="col-12 pt-2 dmail">
                                    <span>{details.sp_email}</span>
                                </div>
                                <div className="col-12 pt-3 atadd">
                                    <p>{details.sp_block + "," + details.sp_street + "," + details.sp_landmark + "," + details.city + "," + details.state + "-" + details.pincode}</p>
                                    <hr/>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-6 dexp">
                                           <strong className="theme_text_set">Experience</strong> <br/>
                                           <span>{details.no_year_experience} Years</span>
                                        </div>
                                        <div className="col-6 dexp">
                                           <strong className="theme_text_set">Projects completed</strong> <br/>
                                           <span>{details.no_project_completed} Projects</span>
                                        </div>
                                        <div className="col-12 pt-3 dexp">
                                           <strong className="theme_text_set">No. of Brand Works</strong> <br/>
                                           <span>{details.no_brand} brand</span>
                                           <hr/>
                                        </div>
                                        <div className="col-12 djob">
                                            <strong className="theme_text_set">Job Desription</strong> <br/>
                                            <p className="pt-2">{details.job_decription}</p>
                                        </div>
                                        
                                        <div className="col-12 pt-3 djob">
                                            <strong className="theme_text_set">Experties</strong> <br/>
                                            <p className="pt-2">{details.expertise_business}</p>
                                        </div>
                                        <div className='flextMobBtn'>
                                        <div className="col-6 pt-3 pb-3 dbtn">
                                            <button type="button" className="btn btn-solid btn-green">
                                                <img src={telephone} className="float-left" /> Call Now
                                            </button>
                                        </div>
                                        <div className="col-6 pt-3 pb-3 dbtnb">
                                            <button type="button" onClick={()=> this.redirect(details.id)} className="btn btn-solid btn-white">
                                                View Portfolio
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </> */}

                <div className='services_dateils_wrapper'>
                    <div className='services_dateils_wrap'>
                        <div className='services_d_banner'>
                            <div className='img'>

                            </div>
                            {/* <img src="" alt="" /> */}
                        </div>
                        <div className='container-fluid'>
                            <div className='services_verified'>
                                <div className='services_verified_block'>
                                   {details.isVerify == 1 && <p className='bule_p'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 7.29289C19.0976 7.68342 19.0976 8.31658 18.7071 8.70711L11.4142 16C10.6332 16.781 9.36683 16.781 8.58579 16L5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929C5.68342 10.9024 6.31658 10.9024 6.70711 11.2929L10 14.5858L17.2929 7.29289C17.6834 6.90237 18.3166 6.90237 18.7071 7.29289Z" fill="black" />
                                        </svg>
                                        <span>Verified</span>
                                    </p> } 

                                    {details.isTrusted == 1 && <p className='orange_p'>
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 20.087H8.61029C8.95063 20.087 9.28888 20.1275 9.61881 20.2085L12.3769 20.8788C12.9753 21.0246 13.5988 21.0387 14.2035 20.9213L17.253 20.328C18.0585 20.1711 18.7996 19.7853 19.3803 19.2204L21.5379 17.1216C22.154 16.5233 22.154 15.5523 21.5379 14.953C20.9832 14.4133 20.1047 14.3526 19.4771 14.8102L16.9626 16.6447C16.6025 16.908 16.1643 17.0497 15.7137 17.0497H13.2855L14.8311 17.0497C15.7022 17.0497 16.4079 16.3632 16.4079 15.5158V15.209C16.4079 14.5054 15.9156 13.8919 15.2141 13.7218L12.8286 13.1416C12.4404 13.0475 12.0428 12.9999 11.6431 12.9999C10.6783 12.9999 8.93189 13.7987 8.93189 13.7987L6 15.0248M2 14.5999L2 20.3999C2 20.9599 2 21.24 2.10899 21.4539C2.20487 21.642 2.35785 21.795 2.54601 21.8909C2.75992 21.9999 3.03995 21.9999 3.6 21.9999H4.4C4.96005 21.9999 5.24008 21.9999 5.45399 21.8909C5.64215 21.795 5.79513 21.642 5.89101 21.4539C6 21.24 6 20.9599 6 20.3999V14.5999C6 14.0398 6 13.7598 5.89101 13.5459C5.79513 13.3577 5.64215 13.2048 5.45399 13.1089C5.24008 12.9999 4.96005 12.9999 4.4 12.9999H3.6C3.03995 12.9999 2.75992 12.9999 2.54601 13.1089C2.35785 13.2048 2.20487 13.3577 2.10899 13.5459C2 13.7598 2 14.0398 2 14.5999ZM17.1914 3.59215C16.5946 2.34329 15.2186 1.68168 13.8804 2.32027C12.5423 2.95886 11.9722 4.47328 12.5325 5.80272C12.8787 6.62435 13.8707 8.2199 14.5781 9.31893C14.8394 9.725 14.9701 9.92804 15.161 10.0468C15.3247 10.1487 15.5297 10.2036 15.7224 10.1972C15.9471 10.1898 16.1618 10.0793 16.5911 9.85832C17.7532 9.26021 19.4101 8.37445 20.1208 7.83602C21.2707 6.96481 21.5556 5.36347 20.6947 4.14614C19.8337 2.9288 18.3327 2.80902 17.1914 3.59215Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span>Trusted</span>
                                    </p>}
                                    

                                    <div className='services_verified_sec'>
                                        <div className='services_verified_inner'>
                                            <div className='services_verified_cont'>
                                                <h2>{details.name} {" "} {details.last_name}</h2>
                                                <p><span>Phone Nmber</span> : {details.mobile_no}</p>
                                                <p><span>Team Size</span> : {details.team_size}</p>
                                                <p><span>Location</span> : {details.area},{details.city}</p>
                                            </div>
                                        </div>
                                        <div className='services_verified_inner'>
                                            <div className='experience_block'>
                                                <div>
                                                    <h3>Experience</h3>
                                                    <p>2 years</p>
                                                </div>
                                                <div>
                                                    <h3>Projects/Jobs completed</h3>
                                                    <p>{details.no_project}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='services_verified_inner'>
                                            <div className='experience_scoial'>
                                                <p>Social Media</p>
                                                <ul>
                                                    <li><a href={details.facebook_url}> <img src={facebook} alt='facebook' /> </a></li>
                                                    <li><a href={details.youtube_url}> <img src={youtube} alt='youtube' /> </a></li>
                                                    <li><a href={details.instagram_url}> <img src={insta} alt='insta' /> </a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='portfolio_wrapper'>
                            <div className='container-fluid'>
                                <div className='portfolio_wrap'>
                                    <div className='portfolio_title'>
                                        <p>Portfolio</p>
                                    </div>
                                    <div className='portfolio_block'>
                                        {details.portfolio && details.portfolio.map((item) => {
                                            return <div className='portfolio_inner_block'>
                                                <div className='portfolio_conte'>
                                                    <ul>
                                                        {item.images && item.images.map((portfolio) => {
                                                            return <li>
                                                            <div className='portfolio_img'>
                                                                <div className='portfolio_inner_img'>
                                                                    <img src={portfolio.image_url} />
                                                                </div>
                                                            </div>
                                                        </li>
                                                        })}
                                                    
                                                        
                                                    </ul>
                                                    <p className='residency_title'>{item.title}</p>
                                                    <ul>
                                                        <li>
                                                            <div className='portfolio_img_cont'>
                                                                <h3>Area/ Location</h3>
                                                                <p>{item.areaLocation}</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='portfolio_img_cont'>
                                                                <h3>Year Completed</h3>
                                                                <p>{item.compiledYear}</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='portfolio_img_cont'>
                                                                <h3>Time Period</h3>
                                                                <p>{item.timePeriod}</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='portfolio_img_cont'>
                                                                <h3>Team Size</h3>
                                                                <p>{item.teamSize}</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        })}

                                     
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

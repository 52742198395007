import React, {Component, useCallback} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {setCartdata, setCartdatafull, applyCouponDiscount} from "../../actions";
import {getProductCartTotal} from "../../services";
import {getCartList_api} from "../../api/auth";
import {declareOrderType} from "../../utils/helper"
import {userPlaceOrder_api, userPayumoney_api} from "../../api/product";
import {applyCouponCode_api, applyGiftCard_api} from "../../api/product";
import {toast} from "react-toastify";
import Razorpay from "./Razorpay";


class PaymentOptionAndGiftCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            payment: "stripe",
            gift_code: "",
            gift_pin: "",
            gift_amount_new: 0,
            giftCardApplied: false,
            loaderForPlaceOrder: false,
            payumoney: "",
            delivery_address_id: localStorage.getItem("delivery_address_id"),
            billing_address_id: localStorage.getItem("billing_address_id"),
            address: JSON.parse(localStorage.getItem("addressData"))
        };
        // this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        this.setCartDatas()
    }

    setCartDatas = async (item = 0, code) => {
        try {
            console.log("this.props.cart_full", this.props.cart_full);
            this.setState({loaderForCartList: true});
            // get cart list

            let apiParams = {
                user_id: this.props.auth.userData.id,
                code: localStorage.getItem("code_set"),
                address_id: localStorage.getItem("delivery_address_id"),
            };
            localStorage.removeItem('code_set');
            let apiData = await getCartList_api(apiParams);
            // console.log(apiData);
            if (apiData.success) {
                let data = JSON.parse(JSON.stringify(apiData.data));
                for (let index = 0; index < data.length; index++) {
                    for (const x in data[index].product_option) {
                        data[index].product_option[x].selected_options = [];
                    }
                    data[index].delivery_slots = [];
                }
                // set the cart data
                this.props.setCartdata(data);
                this.props.applyCouponDiscount(
                    apiData.total_coupon_price ? apiData.total_coupon_price : 0
                );
                this.props.setCartdatafull(JSON.parse(JSON.stringify(apiData)));
            } else {
                toast.error(apiData.message);
            }
            this.setState({loaderForCartList: false});
        } catch (error) {
            this.setState({loaderForCartList: false});
            console.log(error);
            toast.error(error.message);
        }
    };

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    };

    setStateFromCheckbox = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.checked;
        this.setState(obj);

        // if (!this.validator.fieldValid(event.target.name)) {
        //   this.validator.showMessages();
        // }
    };

    checkhandle(value) {
        this.setState({
            payment: value,
        });
    }

// OLD FUN
    // StripeClick = () => {
    //     // if (this.validator.allValid()) {
    //     alert("You submitted the form and stuff!");

    //     var handler = window.StripeCheckout.configure({
    //        key: "pk_test_mZ6HfljXBzO7479xfG4u3Hbl", // pk_test_glxk17KhP7poKIawsaSgKtsL
    //         locale: "auto",
    //         token: (token) => {
    //             console.log(token);
    //             this.props.history.push({
    //                 pathname: "/order-success",
    //                 state: {
    //                     payment: token,
    //                     items: this.props.cartItems,
    //                     orderTotal: this.props.total,
    //                     symbol: this.props.symbol,
    //                 },
    //             });
    //         },
    //     });
    //     handler.open({
    //         name: "",
    //         description: "Online Fashion Store",
    //         amount: this.amount * 100,
    //     });
    // };

    placeOrder = async (tokenData) => {
        if (this.props.auth.isLoggedIn) {
            try {
                this.setState({loaderForPlaceOrder: true});

                var slotdata = [];
                this.props.cartItems.map((val, id) => {
                    if (val.product_slot && val.product_slot !== "") {
                        var slot_data = [];
                        JSON.parse(val.product_slot).map((value, key) => {
                            slot_data.push({
                                slot_no: key + 1,
                                slot_qty: value.qty,
                                slot_price: val.wholesale_price,
                                slot_total:
                                    parseFloat(val.wholesale_price) * parseInt(value.qty),
                                slot_date: value.date,
                                slot_tax: "0",
                            });
                        });
                        slotdata.push({
                            cart_id: val.cart_id,
                            product_id: val.id,
                            slot_data: [...slot_data],
                        });
                    }
                });

                let apiParams = {
                    transaction_id: tokenData && tokenData.id ? tokenData.id : "",
                    user_id: this.props.auth.userData.id,
                    delivery_address_id: this.state.delivery_address_id,
                    billing_address_id: this.state.billing_address_id,
                    is_billing_address: 0,
                    coupon_id:
                        this.props && this.props.cart_full && this.props.cart_full.coupon_id
                            ? this.props.cart_full.coupon_id
                            : "",
                    gift_id: localStorage.getItem("gift_id"),
                    gift_amount: localStorage.getItem("gift_amount"),
                    payment_id: this.state.payment === "COD" ? 1 : 2,
                    total:
                        this.props.total +
                        this.declareTaxesOfProduct() -
                        this.state.gift_amount_new -
                        this.props.coupon_discount +
                        parseFloat(
                            this.props.cart_full ? this.props.cart_full.total_ship_price : 0
                        ),
                    total_sp: 0,
                    gift_wrapping_id: 0,
                    slots:
                        slotdata && slotdata.length >= 1 ? JSON.stringify(slotdata) : "",
                };
                console.info(
                    "Place Order Click",
                    this.props.cartItems.map(
                        (val) =>
                            val.delivery_slots &&
                            val.delivery_slots.map((slots) => JSON.stringify(slots))
                    )
                );
                console.info("slotData", slotdata);
                // console.log('apiParams',apiParams);
                // return false;
                let orderApiData = await userPlaceOrder_api(apiParams);
                this.setState({loaderForPlaceOrder: false});
                // console.log(orderApiData);
                if (orderApiData.success) {
                    toast.success("Order placed successfully");
                    localStorage.removeItem("code_set");
                    this.props.history.push({
                        pathname: "/order-success",
                        state: {
                            payment: tokenData,
                            items: this.props.cartItems,
                            orderTotal: this.props.total,
                            symbol: this.props.symbol,
                            address: this.state.address,
                            delivery_address_id: this.state.delivery_address_id,
                            billing_address_id: this.state.billing_address_id,
                            coupon_discount: this.props.coupon_discount,
                            order_data: orderApiData.orderdata,
                        },
                    });
                } else {
                    toast.error(orderApiData.message);
                }
            } catch (error) {
                console.log(error);
                toast.error(error.message);
                this.setState({loaderForPlaceOrder: false});
            }
        }
    };

    // NEW FUN
    StripeClick = () => {
        localStorage.removeItem("code_set");
        console.log(this.state.payment, "this.state.payment")
        // rzp_test_0FDKYlrG8MBQ8Q

        if (this.state.payment !== "COD" && this.state.payment !== "Payumoney") {
            /*if (this.validator.allValid()) {
              alert("You submitted the form and stuff!");
            }*/

            var handler = window.StripeCheckout.configure({
                key: "pk_test_mZ6HfljXBzO7479xfG4u3Hbl", // pk_test_glxk17KhP7poKIawsaSgKtsL
                locale: "auto",
                token: (token) => {
                    this.placeOrder(token);
                },
            });

            handler.open({
                name: "",
                description: "Online Store",
                amount:
                    (this.props.total +
                        parseFloat(
                            this.props.cart_full ? this.props.cart_full.total_ship_price : 0
                        ) +
                        this.declareTaxesOfProduct() -
                        this.props.coupon_discount -
                        this.state.gift_amount_new) *
                    100,
                closed: function () {
                    this.setState({loaderForPlaceOrder: false});
                    // Stripe relode issue
                    // window.location.reload();
                }.bind(this),
            });
        }

        if (this.state.payment == "Payumoney") {
            this.placeOrderwithPayumoney(null);
        } else {
            // cod  payment
            this.placeOrder(null);
        }
    }

    handleApplygiftcard = async (e) => {
        e.preventDefault();
        console.log("this.state", this.state)
        if (this.state.couponCode !== "") {
            try {
                this.setState({loaderForCouponCode: true});
                let apiParams = {
                    user_id: this.props.auth.userData.id,
                    //   language_id: 1,
                    gift_code: this.state.gift_code,
                    total: this.props.cart_full.total,
                    total_sp: 1,
                };
                let couponApiData = await applyGiftCard_api(apiParams);
                console.log(couponApiData);
                if (couponApiData.success) {
                    // this.props.addGiftcode(couponApiData.data.gift_id,this.state.gift_code,couponApiData.data.gift_amount)
                    let totalDiscount = couponApiData.data.gift_amount;
                    this.setState({gift_amount_new: couponApiData.data.gift_amount})
                    this.setState({loaderForCouponCode: false, giftCardApplied: true});
                    if (totalDiscount > 0) {
                        // dispatch coupon code action
                        toast.success("Gift Card code applied successfully");
                    }
                } else {
                    toast.error(couponApiData.message);
                }
            } catch (error) {
                console.log(error);
                toast.error(error.message);
                this.setState({loaderForCouponCode: false, giftCardApplied: false});
            }
        }

    };

    declareTaxesOfProduct = () => {
        const {cartItems} = this.props;
        var total = 0;
        var taxTypesSum;
        for (var i = 0; i < cartItems.length; i++) {
            taxTypesSum = 0;
            // if (declareOrderType(cartItems[i]) === "Retail") {
            //   if (cartItems[i].tax_price.length > 0) {
            //     for (var j = 0; j < cartItems[i].tax_price.length; j++) {
            //       if (cartItems[i].tax_price[j].tax_amount)
            //         taxTypesSum += parseInt(cartItems[i].tax_price[j].tax_amount);
            //     }
            //     total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
            //   }
            // } else {
            //   if (cartItems[i].wholesale_tax_price.length > 0) {
            //     for (var k = 0; k < cartItems[i].wholesale_tax_price.length; k++) {
            //       if (cartItems[i].tax_price[k].tax_amount)
            //         taxTypesSum += parseInt(
            //           cartItems[i].wholesale_tax_price[k].tax_amount
            //         );
            //     }
            //     total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
            //   }
            // }
            if (cartItems[i].tax_price.length > 0) {
                for (var j = 0; j < cartItems[i].tax_price.length; j++) {
                    if (cartItems[i].tax_price[j].tax_amount)
                        taxTypesSum += parseInt(cartItems[i].tax_price[j].tax_amount);
                }
                total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
            }
        }
        return total;
    };

    placeOrderwithPayumoney = async (tokenData) => {
        if (this.props.auth.isLoggedIn) {
            try {
                this.setState({loaderForPlaceOrder: true});

                var slotdata = [];
                this.props.cartItems.map((val, id) => {
                    if (val.product_slot && val.product_slot !== "") {
                        var slot_data = [];
                        JSON.parse(val.product_slot).map((value, key) => {
                            slot_data.push({
                                slot_no: key + 1,
                                slot_qty: value.qty,
                                slot_price: val.wholesale_price,
                                slot_total:
                                    parseFloat(val.wholesale_price) * parseInt(value.qty),
                                slot_date: value.date,
                                slot_tax: "0",
                            });
                        });
                        slotdata.push({
                            cart_id: val.cart_id,
                            product_id: val.id,
                            slot_data: [...slot_data],
                        });
                    }
                });

                let apiParams = {
                    transaction_id: tokenData && tokenData.id ? tokenData.id : "",
                    user_id: this.props.auth.userData.id,
                    delivery_address_id: this.state.delivery_address_id,
                    billing_address_id: this.state.billing_address_id,
                    is_billing_address: 0,
                    coupon_id:
                        this.props && this.props.cart_full && this.props.cart_full.coupon_id
                            ? this.props.cart_full.coupon_id
                            : "",
                    gift_id: this.state.gift_id,
                    gift_amount: this.state.gift_amount,
                    payment_id: this.state.payment === "COD" ? 1 : 2,
                    total:
                        this.props.total +
                        this.declareTaxesOfProduct() -
                        this.state.gift_amount_new -
                        this.props.coupon_discount +
                        parseFloat(
                            this.props.cart_full ? this.props.cart_full.total_ship_price : 0
                        ),
                    total_sp: 0,
                    gift_wrapping_id: 0,
                    slots:
                        slotdata && slotdata.length >= 1 ? JSON.stringify(slotdata) : "",
                };
                // console.log('apiParams',apiParams);
                // return false;
                let orderApiData = await userPayumoney_api(apiParams);
                if (orderApiData) {
                    this.setState({payumoney: orderApiData.data});
                    this.setState({loaderForPlaceOrder: false});
                }
            } catch (error) {
                console.log(error);
                toast.error(error.message);
                this.setState({loaderForPlaceOrder: false});
            }
        }
    };

    render() {

        // const { cartItems, symbol, total, coupon_discount } = this.props;
        const {
            cartItems,
            cart_full,
            symbol,
            total,
            coupon_discount,
            // address,
            // payumoney,
            // delivery_address_id,
            // billing_address_id,
        } = this.props;
        const {isLoggedIn} = this.props.auth;
        const payumoney = this.state.payumoney

        const address = JSON.parse(localStorage.getItem("addressData"))
        const delivery_address_id = localStorage.getItem("delivery_address_id")
        const billing_address_id = localStorage.getItem("billing_address_id")

        // Paypal Integration
        const onSuccess = (payment) => {
            console.log("The payment was succeeded!", payment);
            this.props.history.push({
                pathname: "/order-success",
                state: {
                    payment: payment,
                    items: cartItems,
                    orderTotal: total,
                    symbol: symbol,
                },
            });
        };

        const onCancel = (data) => {
            console.log("The payment was cancelled!", data);
        };

        const onError = (err) => {
            console.log("Error!", err);
        };

        const client = {
            sandbox:
                "AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_",
            production:
                "AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_",
        };

        let deliveryAddress = address.find(
            (item) => item.id == delivery_address_id
        );
        let deliveryaddress_type = "Home";
        if (deliveryAddress.address_type === 2) {
            deliveryaddress_type = "Office";
        } else if (deliveryAddress.address_type === 3) {
            deliveryaddress_type = "Site";
        }
        let billingAddress = address.find((item) => item.id == billing_address_id);
        let billingaddress_type = "Home";
        if (billingAddress.address_type === 2) {
            billingaddress_type = "Office";
        } else if (billingAddress.address_type === 3) {
            billingaddress_type = "Site";
        }

        if (payumoney.user) {
            var data = {
                payment: "",
                items: this.props.cartItems,
                orderTotal: this.props.total,
                symbol: this.props.symbol,
                address: address,
                delivery_address_id: delivery_address_id,
                billing_address_id: billing_address_id,
            };
            localStorage.setItem('state', JSON.stringify(data));
        }

        return (
            <section className="section-b-space payment-section-last">
                {/* <div className="container padding-cls"> */}
                <div className="container-fluid">

                    <div className="row">

                        {payumoney.user ?
                            <div className="col-12 d-none">
                                <form action={payumoney.action} id="payuform" method="post" name="payuForm">
                                    <input type="hidden" name="key" value={payumoney.MERCHANT_KEY}/>
                                    <input type="hidden" name="hash" value={payumoney.hash}/>
                                    <input type="hidden" name="txnid" value={payumoney.txnid}/>
                                    <table>
                                        <tr>
                                            <td>Amount:</td>
                                            <td><input name="amount" value={payumoney.posted.amount}/></td>
                                            <td>First Name:</td>
                                            <td><input name="firstname" id="firstname"
                                                       value={payumoney.posted.firstname}/></td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td><input name="email" id="email" value={payumoney.user.email}/></td>
                                            <td>Phone:</td>
                                            <td><input name="phone" value={payumoney.user.mobile_no}/></td>
                                        </tr>
                                        <tr>
                                            <td>Product Info:</td>
                                            <td colSpan="3"><textarea name="productinfo">test product </textarea></td>
                                        </tr>
                                        <tr>
                                            <td>Success URI:</td>
                                            <td colSpan="3"><input name="surl"
                                                                   value={payumoney.posted.surl}
                                                                   size="64"/></td>
                                        </tr>
                                        <tr>
                                            <td>Failure URI:</td>
                                            <td colSpan="3"><input name="furl"
                                                                   value={payumoney.posted.furl}
                                                                   size="64"/></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3"><input type="hidden" name="service_provider"
                                                                   value="payu_paisa"
                                                                   size="64"/></td>
                                        </tr>
                                        <tr>
                                            <td><b>Optional Parameters</b></td>
                                        </tr>
                                        <tr>
                                            <td>Last Name:</td>
                                            <td><input name="lastname" id="lastname" value={payumoney.user.last_name}/>
                                            </td>
                                            <td>Cancel URI:</td>
                                            <td><input name="curl" value={payumoney.posted.furl}/></td>
                                        </tr>
                                        <tr>
                                            <td>Address1:</td>
                                            <td><input name="address1" value={payumoney.posted.address1}/></td>
                                            <td>Address2:</td>
                                            <td><input name="address2" value={payumoney.posted.address2}/></td>
                                        </tr>
                                        <tr>
                                            <td>City:</td>
                                            <td><input name="city" value={payumoney.posted.city}/></td>
                                            <td>State:</td>
                                            <td><input name="state" value={payumoney.posted.state}/></td>
                                        </tr>
                                        <tr>
                                            <td>Country:</td>
                                            <td><input name="country" value={payumoney.posted.country}/></td>
                                            <td>Zipcode:</td>
                                            <td><input name="zipcode" value={payumoney.posted.zipcode}/></td>
                                        </tr>
                                        <tr>
                                            <td>UDF1:</td>
                                            <td><input name="udf1" value={payumoney.posted.udf1}/></td>
                                            <td>UDF2:</td>
                                            <td><input name="udf2" value=""/></td>
                                        </tr>
                                        <tr>
                                            <td>UDF3:</td>
                                            <td><input name="udf3" value=""/></td>
                                            <td>UDF4:</td>
                                            <td><input name="udf4" value=""/></td>
                                        </tr>
                                        <tr>
                                            <td>UDF5:</td>
                                            <td><input name="udf5" value=""/></td>
                                            <td>PG:</td>
                                            <td><input name="pg" value=""/></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4"><input type="submit" value="Submit"/></td>
                                        </tr>
                                    </table>
                                </form>
                                {
                                    setTimeout(() => {
                                        document.getElementById("payuform").submit()
                                    }, 100)
                                }
                            </div>
                            : ""}
                    </div>
                                {this.state.loaderForPlaceOrder ?           <div className='row'>
                        <div className='col-sm-12 text-center section-b-space mt-5 no-found mb-5'>
                            <div className='loading-cls'/>
                        </div>
                    </div> : <div className="row mx-md-5 mx-sm-1">
                        <div className="col-sm-12">
                            <div className="checkout-page">
                                <div className="checkout-form">
                                    {address.length > 0 &&
                                    delivery_address_id !== 0 && (
                                        <form>
                                            <div className="dashboard checkout row">
                                                <div className="col-lg-7 col-sm-12 col-xs-12" style={{display: "none"}}>
                                                    <div className="box mobBorder">
                                                        <div className="box-title noborder">
                                                            <h3
                                                                className="apply_giftcard"
                                                            >
                                                                Apply Giftcard <i
                                                                className="fa fa-angle-up theme_text_set "
                                                                aria-hidden="true"></i>
                                                            </h3>
                                                        </div>
                                                        <div className="row check-out">
                                                            <div className="col-md-12">
                                                                <div className="mt-3 leftSpace">
                                                                    <label className="paymentpage_input_title"
                                                                           htmlFor="superVisorName">
                                                                        Gift Card Code
                                                                    </label>
                                                                    <div className="flexBtnIN">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="superVisorName"
                                                                            placeholder="Enter Gift Card Code"
                                                                            value={this.state.gift_code}
                                                                            required=""
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    gift_code: e.target.value,
                                                                                    giftCardApplied: false
                                                                                })
                                                                            }
                                                                        />
                                                                        <span className="input-group-append">
                                                                            <button
                                                                                className="btn btn-solid"
                                                                                disabled={
                                                                                    this.state.loaderForCouponCode ||
                                                                                    this.state.couponCode === ""
                                                                                }
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleApplygiftcard(e)
                                                                                }}
                                                                            >
                                                                                {this.state.giftCardApplied ? "Applied" : "Apply"}
                                                                                {this.state.loaderForCouponCode && (
                                                                                    <i className="fa fa-spinner ml-3 fa-pulse"/>
                                                                                )}
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="mt-3">
                                                                    {/* <label className="paymentpage_input_title" htmlFor="superVisorName">
                                                                    Gift Card PIN
                                                                </label> */}
                                                                    <div className="form-group">
                                                                        <div
                                                                            className="input-group"
                                                                            style={{
                                                                                flexWrap: "unset",
                                                                            }}
                                                                        >

                                                                            {/* <input
                                                                            type="text"
                                                                            className="form-control coupon"
                                                                            placeholder="e.g. FIRST50"
                                                                            value={this.state.gift_pin}
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    gift_pin: e.target.value,
                                                                                })
                                                                            }
                                                                        /> */}
                                                                            {/* <span className="input-group-append">
                                                                            <button
                                                                                className="btn btn-solid"
                                                                                disabled={
                                                                                    this.state.loaderForCouponCode ||
                                                                                    this.state.couponCode === ""
                                                                                }
                                                                                onClick={(e) => {e.preventDefault(); this.handleApplygiftcard(e)}}
                                                                            >
                                                                                {this.state.giftCardApplied ? "Applied" : "Apply"}
                                                                                {this.state.loaderForCouponCode && (
                                                                                    <i className="fa fa-spinner ml-3 fa-pulse"/>
                                                                                )}
                                                                            </button>
                                                                        </span> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="offset-3 col-lg-6 col-sm-12 col-xs-6">
                                                    {/* <div className="checkout-details"> */}
                                                    <div
                                                        style={{
                                                            // paddingLeft: "40px",
                                                            paddingTop: "15px",
                                                        }}
                                                    >
                                                        <div className="p-2" style={{border: "1px solid #ced4da"}}>
                                                            <h4 className="font-weight-bold my-2 set_color_dark_1">PAYMENT
                                                                SUMMARY</h4>
                                                            <table
                                                                className="payment_summary_table"
                                                                style={{
                                                                    // tableLayout: "fixed",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <tbody>
                                                                <tr>
                                                                    <th className="py-2">Subtotal</th>
                                                                    <td className="py-2" style={{textAlign: "right"}}>
                                                                        {symbol} {(cart_full && cart_full.total) ? (parseFloat(cart_full.total).toFixed(2)) : 0}
                                                                    </td>
                                                                </tr>
                                                                {/*  <tr>
                                <th  className="py-2">Grand total excl. taxes</th>
                                <td  className="py-2"  style={{textAlign: "right"}}>
                                  {symbol} {(cart_full&&cart_full.total)?(cart_full.total):0}
                                </td>
                              </tr>*/}
                                                                {/*<tr>
                                <th  className="py-2" >Taxes</th>
                                <td  className="py-2"  style={{textAlign: "right"}}>
                                  {symbol} {(cart_full&&cart_full.total)?cart_full.total_tax_price:0}
                                </td>
                              </tr>*/}
                                                                {cart_full && cart_full.total_coupon_price && cart_full.total_coupon_price >= 1 ?
                                                                    <tr>
                                                                        <th className="py-2">Coupon Discount</th>
                                                                        <td className="py-2 text-danger"
                                                                            style={{textAlign: "right"}}>
                                                                            {symbol} {(cart_full && cart_full.total_coupon_price) ? parseFloat(cart_full.total_coupon_price).toFixed(2) : 0}
                                                                        </td>
                                                                    </tr> : ""}
                                                                <tr>
                                                                    <th className="py-2">Shipping</th>
                                                                    <td className="py-2 text-danger"
                                                                        style={{textAlign: "right"}}>
                                                                        {symbol} {(cart_full && cart_full.total_ship_price) ? parseFloat(cart_full.total_ship_price).toFixed(2) : 0}
                                                                    </td>
                                                                </tr>
                                                                {this.state.giftCardApplied ?
                                                                    <tr>
                                                                        <th className="py-2">Gift Card</th>
                                                                        <td className="py-2"
                                                                            style={{textAlign: "right"}}>
                                                                            {symbol} {(this.state.gift_amount_new && this.state.gift_amount_new) ? parseFloat(this.state.gift_amount_new).toFixed(2) : 0}
                                                                        </td>
                                                                    </tr>
                                                                    : ""
                                                                }
                                                                <tr>
                                                                    <th className="py-2 w-70 set_color_dark_1">Grand
                                                                        Total
                                                                    </th>
                                                                    <td className="py-2 w-30 theme_text_ligblue"
                                                                        style={{textAlign: "right"}}>
                                                                        {symbol}{" "}
                                                                        {(cart_full && cart_full.total) ? (parseFloat(cart_full.total_ship_price) + parseFloat(cart_full.total) - parseFloat(cart_full.total_coupon_price) - parseFloat(this.state.giftCardApplied ? this.state.gift_amount_new : 0)).toFixed(2) : 0}
                                                                    </td>
                                                                </tr>

                                                                </tbody>
                                                            </table>

                                                            <div className="payNowClass">
                                                                {/* {
                                                                    this.state.payment === "razorpay" ?
                                                                  
                                                                    :
                                                                    <button
                                                                    type="button"
                                                                    className="btn-solid btn w-100"
                                                                    onClick={() => this.StripeClick()}
                                                                    disabled={this.state.loaderForPlaceOrder}
                                                                >
                                                                    PAY NOW
                                                                    {this.state.loaderForPlaceOrder && (
                                                                        <i className="fa fa-spinner ml-3 fa-pulse fa-2x"/>
                                                                    )}
                                                                </button> */}

                                                                <Razorpay
                                                                    payment={(this.props.total + parseFloat(this.props.cart_full ? this.props.cart_full.total_ship_price : 0) + this.declareTaxesOfProduct() - this.props.coupon_discount - this.state.gift_amount_new) * 100}
                                                                    OrderAPi={this.placeOrder}/>

                                                            </div>

                                                        </div>

                                                        {/* <div
                                                        className="p-2 mt-2"
                                                        style={{border: "1px solid #ced4da"}}
                                                    >
                                                        <h4 className="font-weight-bold set_color_dark_1">
                                                            Delivery Address
                                                        </h4>
                                                        <div className="row mt-3">
                                                            <div className="col">

                                                                <strong className="set_color_dark_1">
                                                                    {deliveryAddress.user_name}  <span className="badge badge-primary  badge-custome">{deliveryaddress_type}</span>

                                                                </strong>{" "}
                                                                <br/>
                                                                {deliveryAddress.address_1} <br/>
                                                                <strong>Phone Number:</strong>{" "}
                                                                <span>{deliveryAddress.mobile_no}</span> <br/>
                                                                <strong>Supervisor Name:</strong>{" "}
                                                                <span>{deliveryAddress.supervision_name}</span>
                                                            </div>
                                                        </div>
                                                        <h4 className="font-weight-bold set_color_dark_1 mt-3">
                                                            Billing Address
                                                        </h4>
                                                        <div className="row mt-3">
                                                            <div className="col">

                                                                <strong className="set_color_dark_1">
                                                                    {billingAddress.user_name} <span className="badge badge-primary  badge-custome">{deliveryaddress_type}</span>

                                                                </strong>{" "}
                                                                <br/>
                                                                {billingAddress.address_1} <br/>
                                                                <strong>Phone Number:</strong>{" "}
                                                                <span>{billingAddress.mobile_no}</span> <br/>
                                                                <strong>Supervisor Name:</strong>{" "}
                                                                <span>{billingAddress.supervision_name}</span>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                        {/*<div className="payment-box mt-3">
                                                        <button
                                                            type="button"
                                                            className="btn-solid btn"
                                                            onClick={() =>
                                                                this.props.handlePaymentNextClick()
                                                            }
                                                        >
                                                            BACK
                                                        </button>
                                                    </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>}
                    
                </div>
            </section>
        );
    }
}


const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    cart_full: state.cartList.cart_full,
    symbol: state.data.symbol,
    total: getProductCartTotal(state.cartList.cart),
    coupon_discount: state.cartList.coupon_discount,
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    {applyCouponDiscount, setCartdata, setCartdatafull}
)(PaymentOptionAndGiftCard);



import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import Breadcrumb from "../common/breadcrumb";
import { getProductCartTotal } from "../../services";
import {
  removeFromCart,
  incrementQty,
  decrementQty,
  updateWithDeliverySLots,
  setCartdata,
  setCartdatafull,
  applyCouponDiscount
} from "../../actions";
import {
  declareProductPrice,
  declareOrderType,
  declareAvailability,
  declareTaxesOfProduct,
} from "../../utils/helper";
import { getCartList_api } from "../../api/auth";
import { toast } from "react-toastify";
import moment from "moment";

class SummaryCheckout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDeliverySLotsModal: false,
      singleProduct: null,
      rows: [],
      remainSlot: 0,

    };
  }

  componentDidMount() {
    this.setCartDatas()
  }

  setCartDatas = async (item = 0, code) => {
    try {
      console.log("this.props.cart_full", this.props.cart_full);
      this.setState({ loaderForCartList: true });
      // get cart list

      let apiParams = {
        user_id: this.props.auth.userData.id,
        code: localStorage.getItem("code_set"),
        address_id: localStorage.getItem("delivery_address_id"),
      };

      // localStorage.removeItem('code_set')

      let apiData = await getCartList_api(apiParams);

      if (apiData.success) {
        let data = JSON.parse(JSON.stringify(apiData.data));
        for (let index = 0; index < data.length; index++) {
          for (const x in data[index].product_option) {
            data[index].product_option[x].selected_options = [];
          }
          data[index].delivery_slots = [];
        }
        // set the cart data
        this.props.setCartdata(data);
        this.props.applyCouponDiscount(
          apiData.total_coupon_price ? apiData.total_coupon_price : 0
        );
        this.props.setCartdatafull(JSON.parse(JSON.stringify(apiData)));
      } else {
        toast.error(apiData.message);
      }
      this.setState({ loaderForCartList: false });
    } catch (error) {
      this.setState({ loaderForCartList: false });
      console.log(error);
      toast.error(error.message);
    }
  };


  onCloseModal = () => {
    this.setState({ openDeliverySLotsModal: false, singleProduct: null });
  };

  handleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    rows[idx] = {
      ...rows[idx],
      [name]: value,
    };
    this.setState(
      {
        rows,
      },
      () => {
        const rows = this.state.rows;
        let total = 0;
        for (let index = 0; index < rows.length; index++) {
          total += parseInt(rows[index].qty);
        }
        let remainSlot = parseInt(this.state.singleProduct.qty - total);
        this.setState({
          remainSlot: remainSlot > 0 ? remainSlot : 0,
        });
      }
    );
  };

  handleAddRow = () => {
    let rows = this.state.rows;
    if (rows.length > 0) {
      if (rows[rows.length - 1].qty === 0) {
        return false;
      }
    }
    const item = {
      qty: 0,
      date: "",
    };
    this.setState({
      rows: [...this.state.rows, item],
    });
  };

  handleRemoveSpecificRow = (idx) => () => {
    const rows = [...this.state.rows];
    rows.splice(idx, 1);
    this.setState({ rows }, () => {
      const rows = this.state.rows;
      let total = 0;
      for (let index = 0; index < rows.length; index++) {
        total += parseInt(rows[index].qty);
      }
      let remainSlot = parseInt(this.state.singleProduct.qty - total);
      this.setState({
        remainSlot: remainSlot > 0 ? remainSlot : 0,
      });
    });
  };

  handleUpdateDeliverySlot = () => {
    const rows = [...this.state.rows];
    let total = 0;
    let checkIsValid = false;
    const today = moment().format("YYYY-MM-DD");
    for (let index = 0; index < rows.length; index++) {
      total += parseInt(rows[index].qty);
      if (
        moment(rows[index].date).format("YYYY-MM-DD") < today ||
        rows[index].date === ""
      ) {
        checkIsValid = true;
      }
    }
    if (checkIsValid) {
      toast.error("Invalid date found");
      return false;
    }
    if (total !== this.state.singleProduct.qty) {
      toast.error(
        "Your slots quantity is not matched with actual quantity of order"
      );
      return false;
    }

    toast.success("Delivery slots updated");
    let indexOfCurrentProduct = this.props.cartItems.findIndex(
      (product) => product.product_id === this.state.singleProduct.product_id
    );
    if (indexOfCurrentProduct !== -1) {
      this.props.cartItems[
        indexOfCurrentProduct
      ].delivery_slots = this.state.rows;
    }
    this.props.updateWithDeliverySLots(
      this.props.cartItems[indexOfCurrentProduct]
    );
  };

  declareTaxesOfProduct = () => {
    const { cartItems } = this.props;
    var total = 0;
    var taxTypesSum;
    for (var i = 0; i < cartItems.length; i++) {
      taxTypesSum = 0;
      // if (declareOrderType(cartItems[i]) === "Retail") {
      //   if (cartItems[i].tax_price.length > 0) {
      //     for (var j = 0; j < cartItems[i].tax_price.length; j++) {
      //       if (cartItems[i].tax_price[j].tax_amount)
      //         taxTypesSum += parseInt(cartItems[i].tax_price[j].tax_amount);
      //     }
      //     total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
      //   }
      // } else {
      //   if (cartItems[i].wholesale_tax_price.length > 0) {
      //     for (var k = 0; k < cartItems[i].wholesale_tax_price.length; k++) {
      //       if (cartItems[i].tax_price[k].tax_amount)
      //         taxTypesSum += parseInt(
      //           cartItems[i].wholesale_tax_price[k].tax_amount
      //         );
      //     }
      //     total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
      //   }
      // }
      if (cartItems[i].tax_price.length > 0) {
        for (var j = 0; j < cartItems[i].tax_price.length; j++) {
          if (cartItems[i].tax_price[j].tax_amount)
            taxTypesSum += parseInt(cartItems[i].tax_price[j].tax_amount);
        }
        total += parseInt(cartItems[i].cart_quantity, 10) * taxTypesSum;
      }
    }
    return total;
  };

  handleDeliverySlotsModal = (item) => {
    let total = 0;
    for (let index = 0; index < item.delivery_slots.length; index++) {
      total += parseInt(item.delivery_slots[index].qty);
    }
    let remainSlot = parseInt(item.cart_quantity - total);
    this.setState({
      openDeliverySLotsModal: true,
      singleProduct: item,
      remainSlot: remainSlot > 0 ? remainSlot : 0,
      rows: item.delivery_slots,
    });
  };

  render() {
    const {
      cartItems,
      symbol,
      total,
      coupon_discount,
      // address,
      // delivery_address_id,
      // billing_address_id,
      cart_full,
    } = this.props;
    const { singleProduct, rows, remainSlot } = this.state;

    const address = JSON.parse(localStorage.getItem("addressData"))
    const delivery_address_id = localStorage.getItem("delivery_address_id")
    const billing_address_id = localStorage.getItem("billing_address_id")
    let deliveryAddress = address.find(
      (item) => item.id == delivery_address_id
    );
    let deliveryaddress_type = "Home";
    if (deliveryAddress.address_type === 2) {
      deliveryaddress_type = "Office";
    } else if (deliveryAddress.address_type === 3) {
      deliveryaddress_type = "Site";
    }
    let billingAddress = address.find((item) => item.id == billing_address_id);
    let billingaddress_type = "Home";
    if (billingAddress.address_type === 2) {
      billingaddress_type = "Office";
    } else if (billingAddress.address_type === 3) {
      billingaddress_type = "Site";
    }
    return (
      <>
        {(cartItems && cartItems.length > 0) ? (

          <section className="cart-section section-b-space paddingTop60">
            <div className="container-fluid">
              <div className="row mx-sm-3 mx-sm-1">
                <div className="col-sm-8 col-md-8 col-8 mb-3">

                  <table className="table cart-table table-responsive-md cart-d-none">
                    <thead>
                        <tr className='table-head'>
                          <th scope='col' className='text-left'>
                              Product Details
                          </th>
                          <th scope='col'>price</th>
                          <th scope='col'>quantity</th>
                          <th scope='col'>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody className='cart-table-res'>
                          {cartItems.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td className='text-left align-top'>
                                  <div className='d-flex'>
                                    <div>
                                      <div className='small_image_90 d-inline-block'>
                                        <Link
                                          to={`${process.env.PUBLIC_URL}/${item.product_slug}/p/${item.id}`}
                                        >
                                          <img
                                            className='w-100 small_image_borders'
                                            src={item.product_image}
                                            alt=''
                                          />
                                        </Link>
                                        <div className='w-100 small_image_borders mt-1 p-1'>
                                          <a
                                            href='javascript:void(0)'
                                            title='Add to Wishlist'
                                            onClick={() =>
                                              this.addToWishList(item)
                                            }
                                          >
                                            <span className='text-right'>
                                                <i className={item.is_in_wishlist == 1 ?"fa fa-heart fa-lg text-danger" : "fa fa-heart-o fa-lg"} aria-hidden="true">
                                                    {" "}
                                                    <strong
                                                    className='Add-to-Whishlist'
                                                    style={{ marginLeft: "4px" }}
                                                    >
                                                    Add to Wishlist
                                                    </strong>    
                                                </i>
                                              {/* <i
                                                className={
                                                  (item.is_in_wishlist == 1 &&
                                                    !not_is_wishlist.includes(
                                                      item.id
                                                    )) ||
                                                  is_wishlist.includes(item.id)
                                                    ? "fa fa-heart fa-lg d-inline-block text-danger"
                                                    : "fa fa-heart-o fa-lg d-inline-block"
                                                }
                                                aria-hidden='true'
                                              >
                                                {" "}
                                              </i> */}
                                                
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div
                                        className='d-inline-block  w-80 style_text_name pl'
                                        style={{ verticalAlign: "top" }}
                                      >
                                        <Link
                                          to={`${process.env.PUBLIC_URL}/${item.product_slug}/p/${item.id}`}
                                        >
                                          <b className='style_text_name cart-text-res'>
                                            {item.product_fullname
                                              ? item.product_fullname.replace(
                                                  "(Child)",
                                                  ""
                                                )
                                              : ""}
                                          </b>
                                        </Link>

                                        <div>
                                          <b>Availability: </b>
                                          <samp
                                            className={
                                              "check___" +
                                              declareAvailability(item)
                                            }
                                          >
                                            {declareAvailability(item)}
                                          </samp>
                                        </div>

                                        {item &&
                                        item.option_data &&
                                        item.option_data.length > 0
                                          ? item.option_data.map((opt, i) => {
                                              return (
                                                <div>
                                                  <b>
                                                    {opt.option_name
                                                      ? opt.option_name
                                                      : ""}
                                                    :{" "}
                                                  </b>
                                                  <samp>
                                                    {opt.option_name
                                                      ? opt.option_value_name
                                                      : ""}
                                                  </samp>
                                                </div>
                                              );
                                            })
                                          : ""}
                                        <div>
                                          <b>Order Type: </b>
                                          <span
                                            className={
                                              "check___" +
                                              declareOrderType(item)
                                            }
                                          >
                                            {" "}
                                            {declareOrderType(item)}{" "}
                                          </span>
                                        </div>
                                        <div>
                                          <b>Distance: </b>
                                          <span>
                                            {" "}
                                            {
                                              item.product_id_with_distance
                                            } KM{" "}
                                          </span>
                                        </div>
                                          <div>
                                            <span>
                                              Get it on by {" "}
                                            </span>
                                            <b>
                                              {moment(item.product_delivery_date).format("DD MMMM YYYY")}
                                            </b>
                                          </div> 
                                        {/* Deliver Slots div */}
                                        <div>
                                          {item.product_slot !=='' && (
                                            <hr className='mb-0' />
                                          )}
                                          {item.product_slot !=='' &&
                                            JSON.parse(item.product_slot)
                                              .filter((fltr) => fltr.qty > 0)
                                              .map((opt, i) => {
                                                return (
                                                  <span key={i}>
                                                    <br />
                                                    <span>
                                                      <strong>Slot:</strong>
                                                      {i + 1}{" "}
                                                      <strong>Quantity:</strong>
                                                      {opt.qty}{" "}
                                                      <strong>Date:</strong>
                                                      {opt.date}{" "}
                                                    </span>
                                                  </span>
                                                );
                                              })}
                                        </div>

                                        <div className='mobile-cart-content row'>
                                          <div className='col-xs-3'>
                                            <div className='qty-box'>
                                              <div className='input-group'>
                                                <input
                                                  type='text'
                                                  name='quantity'
                                                  className='form-control input-number'
                                                  defaultValue={
                                                    item.cart_quantity
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-xs-3'>
                                            <h2 className='td-color'>
                                              {symbol}
                                              {/* {item.product_price} */}
                                              {declareProductPrice(item)}
                                            </h2>
                                          </div>
                                          <div className='col-xs-3'>
                                            <h2 className='td-color'>
                                              <span
                                                className='icon'
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.removeFromCart(item);
                                                }}
                                              >
                                                <i className='icon-close' />
                                              </span>
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <h2 className='td-color'>
                                    {symbol}
                                    {/* {item.product_price} */}
                                    {declareProductPrice(item).replace(
                                      /\.0$/,
                                      ""
                                    )}{" "}
                                    {item.unit_name != ""
                                      ? "/" + item.unit_name
                                      : ""}
                                  </h2>
                                </td>
                                <td style={{ width: 150 }}>
                                  <div className='qty-box mb-2'>
                                    <div className='input-group'>
                                      <input
                                        type='text'
                                        name='quantity'
                                        value={item.cart_quantity}
                                        readOnly={true}
                                        className='form-control'
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <h2 className=''>
                                    {symbol}
                                    {/* {item.sum} */}
                                    {item.cart_quantity *
                                      declareProductPrice(item)}
                                  </h2>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                  </table>

                  <button
                    type="button"
                    className="btn-solid btn mt-5 cart-d-none cart-d-none-mobile"
                    // onClick={() => this.StripeClick()}
                    onClick={() => this.props.handleNextClick()}
                  >
                    BACK
                  </button>
                </div>
                <div className="col-4 col-md-4">
                  <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="p-2 payment-confirm-box mb-3" style={{border: "1px solid #ced4da"}}>
                    <h4 className="font-weight-bold my-2">PAYMENT SUMMARY</h4>
                    <table
                        className="payment_summary_table"
                        style={{
                          // tableLayout: "fixed",
                          width: "100%",
                        }}
                    >
                      <tbody>
                      <tr>
                        <th  className="py-2" >Subtotal</th>
                        <td  className="py-2" style={{textAlign: "right"}}>
                          {symbol} {(cart_full&&cart_full.total)?(parseFloat(cart_full.total).toFixed(2)):0}
                        </td>
                      </tr>
                      {/*  <tr>
                                <th  className="py-2">Grand total excl. taxes</th>
                                <td  className="py-2"  style={{textAlign: "right"}}>
                                  {symbol} {(cart_full&&cart_full.total)?(cart_full.total):0}
                                </td>
                              </tr>*/}
                      {/*<tr>
                                <th  className="py-2" >Taxes</th>
                                <td  className="py-2"  style={{textAlign: "right"}}>
                                  {symbol} {(cart_full&&cart_full.total)?cart_full.total_tax_price:0}
                                </td>
                              </tr>*/}


                      {cart_full && cart_full.total_coupon_price && cart_full.total_coupon_price >= 1?
                          <tr>
                            <th  className="py-2" >Coupon Discount</th>
                            <td  className="py-2 text-danger"  style={{textAlign: "right"}}>
                              {symbol} {(cart_full && cart_full.total_coupon_price)?parseFloat(cart_full.total_coupon_price).toFixed(2):0}
                            </td>
                          </tr>:""}
                      <tr>
                        <th  className="py-2" >Shipping</th>
                        <td  className="py-2 text-danger"  style={{textAlign: "right"}}>
                          {symbol} {(cart_full && cart_full.total_ship_price)?parseFloat(cart_full.total_ship_price).toFixed(2):0}
                        </td>
                      </tr>
                      <tr>
                        <th className="py-2 w-70 set_color_dark_1">Grand Total</th>
                        <td className="py-2 w-30 theme_text_ligblue" style={{textAlign: "right"}}>
                          {symbol}{" "}
                          {(cart_full&&cart_full.total)?(parseFloat(cart_full.total_ship_price)+parseFloat(cart_full.total)-parseFloat(cart_full.total_coupon_price)).toFixed(2):0}
                        </td>
                      </tr>

                      </tbody>
                    </table>
                      <div className="payNowClass">
                      <button
                      type="button"
                      className="btn-solid btn w-100"
                      // onClick={() => this.props.StripeClick()}
                      // onClick={() => this.props.handlePaymentNextClick()}
                      onClick={() => this.props.history.push({pathname: "/checkout-payment"})}
                    >
                      NEXT
                    </button>
                      </div>
                  </div>
                    </div>
                    <div className="col-12 col-md-12 address-confirm">
                    <div
                    className="p-2"
                    style={{ border: "1px solid #ced4da" }}
                  >
                    <h4 className="font-weight-bold">Delivery Address</h4>
                    <div className="row mt-3">
                      <div className="col">
                        <strong className="font-weight-bold text-black">
                          {deliveryAddress.user_name} <span className="badge badge-primary" style={{color: "#fff", backgroundColor: "#047bd5"}}>{deliveryaddress_type}</span>
                        </strong>{" "}
                        <br />
                        {`${deliveryAddress.flat_no != null  ? deliveryAddress.flat_no + ", " : ""}` +deliveryAddress.address_1} <br />
                        <strong>Phone Number:</strong>{" "}
                        <span>{deliveryAddress.mobile_no}</span> <br />
                        <strong>Supervisor Name:</strong>{" "}
                        <span>{deliveryAddress.supervision_name}</span> <br />
                        <strong>Supervisor Number:</strong>{" "}
                        <span>{deliveryAddress.supervision_mobile_no}</span>
                      </div>
                    </div>
                    <h4 className="font-weight-bold mt-3">Billing Address</h4>
                    <div className="row mt-3">
                      <div className="col">
                        <strong className="font-weight-bold text-black">
                          {billingAddress.user_name} <span className="badge badge-primary" style={{color: "#fff", backgroundColor: "#047bd5"}}>{billingaddress_type}</span> 
                        </strong>{" "}
                        <br />
                        {`${billingAddress.flat_no != null  ? billingAddress.flat_no + ", " : ""}` + billingAddress.address_1}  <br />
                        <strong>Phone Number:</strong>{" "}
                        <span>{billingAddress.mobile_no}</span> <br />
                        <strong>Supervisor Name:</strong>{" "}
                        <span>{billingAddress.supervision_name}</span> <br />
                        <strong>Supervisor Number:</strong>{" "}
                        <span>{billingAddress.supervision_mobile_no}</span>
                      </div>
                    </div>
                  </div>
                    </div>
                    
                  </div>
                  
                  {/* <div
                    className="p-2 mt-2"
                    style={{ border: "1px solid #ced4da" }}
                  >
                    <h4 className="font-weight-bold">Delivery Address</h4>
                    <div className="row mt-3">
                      <div className="col">
                        <strong className="font-weight-bold theme_text_set">
                          {deliveryAddress.user_name} <span className="badge badge-primary" style={{color: "#fff", backgroundColor: "#047bd5"}}>{deliveryaddress_type}</span>
                        </strong>{" "}
                        <br />
                        {deliveryAddress.address_1} <br />
                        <strong>Phone Number:</strong>{" "}
                        <span>{deliveryAddress.mobile_no}</span> <br />
                        <strong>Supervisor Name:</strong>{" "}
                        <span>{deliveryAddress.supervision_name}</span> <br />
                        <strong>Supervisor Number:</strong>{" "}
                        <span>{deliveryAddress.supervision_mobile_no}</span>
                      </div>
                    </div>
                    <h4 className="font-weight-bold mt-3">Billing Address</h4>
                    <div className="row mt-3">
                      <div className="col">
                        <strong className="font-weight-bold theme_text_set">
                          {billingAddress.user_name} <span className="badge badge-primary" style={{color: "#fff", backgroundColor: "#047bd5"}}>{billingaddress_type}</span> 
                        </strong>{" "}
                        <br />
                        {billingAddress.address_1} <br />
                        <strong>Phone Number:</strong>{" "}
                        <span>{billingAddress.mobile_no}</span> <br />
                        <strong>Supervisor Name:</strong>{" "}
                        <span>{billingAddress.supervision_name}</span> <br />
                        <strong>Supervisor Number:</strong>{" "}
                        <span>{billingAddress.supervision_mobile_no}</span>
                      </div>
                    </div>
                  </div> */}
                  <div className="payment-box">
                    <div className="upper-box mt-3">
                      {/* <div className="payment-options">
                        <ul>
                          <li>
                            <div className="radio-option stripe">
                              <input
                                type="radio"
                                name="payment-group"
                                id="payment-2"
                                defaultChecked={true}
                                onClick={() => this.checkhandle("stripe")}
                              />
                              <label htmlFor="payment-2">Stripe</label>
                            </div>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                    {/* <button
                      type="button"
                      className="btn-solid btn"
                      // onClick={() => this.props.StripeClick()}
                      onClick={() => this.props.handlePaymentNextClick()}
                    >
                      NEXT
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="cart-section section-b-space" style={{paddingTop: "0px"}}>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    <div className="col-sm-12 empty-cart-cls text-center">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/icon-empty-cart.png`}
                        className="img-fluid mb-4"
                        alt=""
                      />
                      <h3>
                        <strong>Your Cart is Empty</strong>
                      </h3>
                      <h4>Explore more shortlist some items.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  cart_full: state.cartList.cart_full,
  symbol: state.data.symbol,
  total: getProductCartTotal(state.cartList.cart),
  coupon_discount: state.cartList.coupon_discount,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { removeFromCart, incrementQty, decrementQty, applyCouponDiscount,updateWithDeliverySLots,setCartdata,setCartdatafull }
)(SummaryCheckout);

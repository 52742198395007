import Api from "./index";
import {getUserData} from "../utils/storageUtils";


// GET HOME PAGE BRANDS
export const getHomePageBrands_api = () => {
  return Api(`/getManufacture`, null, "POST", "");
};
export const getHomeFooterCats_api = () => {
  return Api(`/getCategoryFrontFooter`, null, "GET", "");
};
export const getHomeHeaderMainMenu_api = () => {
  return Api(`/getCategoryFrontHederMenu`, null, "GET", "");
};
export const getHomeSlider_api = () => {
  return Api(`/getSlider`, null, "POST", "");
};
export const getHomeSidebarCategories_api = () => {
  return Api(`/getSidebarNLevelCategory`, null, "GET", "");
};
export const getHomeDashboardModules_api = () => {
    return Api(`/v1/getCustomerDashboard?user_id=`+(getUserData()?getUserData().id:''), null, "GET", "");
};
export const getLatestProducts_api = () => {
  return Api(`/getLatestProducts`, null, "GET", "");
};
export const getOffersList_api = () => {
  return Api(`/OfferList`, null, "GET", "");
};
export const getservicehomepage_api = () => {
  return Api(`/v1/sp/dashboard`, null, "GET", "");
};
export const getserviceListById_api = (params) => {
  return Api(`/v1/sp/serviceList`, params, "POST", "");
};
export const getservicelist_api = () => {
  return Api(`/servicelist`, null, "GET", "");
};
export const getserviceprovider_by_id = (id) => {
  return Api(`/v1/sp/providers/list/${id}`, null, "GET", "");
};
export const getserviceproviders_api = (params) => {
  return Api(`/serviceproviderbyservice`, params, "POST", "");
};
export const getserviceproviderlist_api = (params) => {
  return Api(`/v1/sp/providers/list`, params, "POST", "");
};
export const getserviceprovidersdetails_api = (params) => {
  return Api(`/serviceproviderdetails`, params, "POST", "");
};
export const getserviceportfoliodetails_api = (params) => {
  return Api(`/portfoliodetails`, params, "POST", "");
};
export const getworkportfolio_api = (params) => {
  return Api(`/portfoliolistbyvendor`, params, "POST", "");
};
export const getspecialitylist_api = () => {
  return Api(`/v1/sp/specialities`, null, "POST", "");
};
export const getproductpositionlist_api = (params) => {
  return Api(`/getproductpositionlistv2?position=`+(params.position||''), null, "GET", "");
};
export const getServiceProviderType = (params) => {
  return Api('/v1/sp/getServiceProviderType', params, "POST", "")
}
// export const getserviceListById_api

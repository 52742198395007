import React, {Component} from 'react';
import Slider from "react-slick"
import Breadcrumb from "../../common/breadcrumb";
import {Slider6, Slider4} from "../../../services/script"
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {getHomeDashboardModules, getHomePageBrands, getHomeSliders, getservicehomepage} from "../../../actions";
import all_categories from '../../../access/icone/group-40.webp';
import { getspecialitylist_api } from '../../../api/home';
import { toast } from 'react-toastify';
class servicecategory extends Component {
  state = {
    specialityList: [],
}
componentDidMount() {
    this.specialityList()
}
specialityList = async () => {
    try {
        let specialityList = await getspecialitylist_api();
        console.log("specialityList",specialityList);
        if (specialityList.status) {
            this.setState({
                specialityList: specialityList.result.data,
            });
        } else {
            toast.error(specialityList.message);
        }
    } catch (error) {
        console.log(error);
        toast.error(error.message);
    }
  };
    render() {
        const {service} = this.props.service;
        return (
          <div>
            {/*Category One*/}
            <div className='container-fluid '>
              <section className='section-b-space psm0 spHomeCust' id='catSec'>
              <div className="row category-d-flex">
                            <div className="col responsive_row">
                                <Slider {...Slider6} className="slide-6 no-arrow">
                                    <div className="col-xl-2 col-lg-2 col-sm-6 col-xs-6  mb-2 ">
                                    <div className="category-block">
                                        <Link to={"/speciality-listing"}>
                                            <div className="category-image">
                                                <img src={all_categories} alt=""/></div>
                                        </Link>
                                        <div className="category-details">
                                            <Link to={"/speciality-listing"}>
                                                <h5>ALL SPECIALITIES</h5>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {this.state.specialityList &&
                      this.state.specialityList.length &&
                      this.state.specialityList.map((list) => {
                        return (
                          <div className='child-category category-block'>
                            <Link to={`/services/${list.name}/${list.id}/0`}>
                              <div className='category-image'>
                                <img src={list.image_url} alt='' />
                              </div>
                            </Link>
                            <div className='category-details'>
                              <Link to={`/services/${list.name}/${list.id}/0`}>
                                <h5>{list.name}</h5>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                                </Slider>
                            </div>
                        </div>
                <div className='element-category sphomeCustHide'>
                  <div className='parent-category'>
                    <div className='child-category category-block'>
                      <Link to={"/speciality-listing"}>
                        <div className='category-image'>
                          <img src={all_categories} alt='' />
                        </div>
                      </Link>
                      <div className='category-details'>
                        <Link to={"/speciality-listing"}>
                          <h5>ALL SPECIALITIES</h5>
                        </Link>
                      </div>
                    </div>
                    {service.category_list &&
                      service.category_list.length &&
                      service.category_list.map((value, index) => {
                        return (
                          <div className='child-category category-block'>
                            <Link to={`/services/${value.name}/${value.id}/0`}>
                              <div className='category-image'>
                                <img src={value.speciality_icon} alt='' />
                              </div>
                            </Link>
                            <div className='category-details'>
                              <Link to={`/services/${value.name}/${value.id}/0`}>
                                <h5>{value.speciality_name}</h5>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </section>
            </div>
          </div>
        );
    }
}
const mapStateToProps = (state) => ({
    service: state.service
});
export default connect(
    mapStateToProps,
    {
        getservicehomepage
    }
)(servicecategory);
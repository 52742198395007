import React, { Component } from "react";
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import { checkAndRedirectLogin } from "../../utils/helper";
import { toast } from "react-toastify";
import DahsboardSidebar from "./DahsboardSidebar";
import { Helmet } from "react-helmet";
import { setUserSignUpSuccess } from "../../actions";
import {
  getAllAddress_api,
  addNewAddress_api,
  editAddress_api,
  deleteAddress_api,
  setAsDefaultAddress_api,
} from "../../api/auth";
import * as storageUtils from "../../utils/storageUtils";
import countryData from "./countries.json";
import AddNewAddress from "./address/AddNewAddress";
import EditAddress from "./address/EditAddress";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class ManageAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaderForAddress: false,
      address: [],
      isShowAddAddressPage: false,
      isShowEditAddressPage: false,
      editAddress: null,
      showBack: true
    };
  }

  componentDidMount() {
    if (!checkAndRedirectLogin()) {
      toast.info("Please login to continue");
      this.props.history.push({
        pathname: "/login",
      });
    } else {
      this.setAddressList();
    }

    const query = new URLSearchParams(this.props.location.search);
    const is_address = query.get('is_address')
    if(is_address == 1)
    {
      this.setState({
        isShowAddAddressPage:true
      });
    }

  }

  setAddressList = async () => {
    try {
      this.setState({ loaderForAddress: true });
      const address = await getAllAddress_api();
      // console.log(address);
      // this.setState({
      //   // loaderForAddress: false,
      //   address: address.data,
      // });
      if(address.data.length == 1){
        await setAsDefaultAddress_api({
          address_id: address.data[0].id
        });
        const newAddress = await getAllAddress_api()
        this.setState({address: newAddress.data,loaderForAddress: false})
        // alert('true')
      }else{
        // alert('false')
        this.setState({address: address.data,loaderForAddress: false})
        // alert("fail")
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({ loaderForAddress: false });
    }
  };

  shoAddPage = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isShowAddAddressPage: !prevState.isShowAddAddressPage,
    }));
  };

  addNewAddress = async (data) => {
    try {
      this.setState({ loaderForAddress: true });
      const newAddressAdded = await addNewAddress_api(data); 
      if(this.props.history.location.search == ""){
        this.setState((prevState) => ({
          ...prevState,
          loaderForAddress: false,
          isShowAddAddressPage: false,
          address: [...prevState.address, newAddressAdded.data],
        }));
        toast.success("Address has been added successfully");
      }else {
        toast.success("Address has been added successfully");
        this.props.history.goBack()
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({ loaderForAddress: false });
    }
  };

  showEditPage = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isShowEditAddressPage: !prevState.isShowEditAddressPage,
    }));
  };

  handleEditClick = (e, item) => {
    e.persist();
    this.setState(
      {
        editAddress: item,
      },
      () => {
        this.showEditPage(e);
      }
    );
  };

  editAddressApi = async (data) => {
    try {
      this.setState({ loaderForAddress: true });
      data.address_id = this.state.editAddress.id;
      const newAddressAdded = await editAddress_api(data);
      this.setState((prevState) => ({
        loaderForAddress: false,
        isShowEditAddressPage: false,
        editAddress: null,
      }));
      toast.success("Address has been updated successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({ loaderForAddress: false });
    }
  };

  handleDeleteClick = async (e, item) => {
    e.preventDefault();
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure to delete this address?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteAddressCode(item),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  deleteAddressCode = async (item) => {
    try {
      this.setState({ loaderForAddress: true });
      await deleteAddress_api({
        address_id: item.id,
      });
      window.location.reload()
      // this.setAddressList()
      // const address = [...this.state.address];
      // let indexToRemove = address.findIndex(
        //   (product) => product.id === item.id
        // );
      // address.splice(indexToRemove, 1);
      // this.setState((prevState) => ({
        //   ...prevState,
        //   address,
        //   loaderForAddress: false,
      // }));
      toast.success("Address has been deleted successfully");
      // setTimeout
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({ loaderForAddress: false });
    }
  };

  handleDefaultChange = async (e, item) => {
    const { checked } = e.target;
    try {
      this.setState({ loaderForAddress: true });
      await setAsDefaultAddress_api({
        address_id: item.id,
      });
      const address = [...this.state.address];
      let indexToRemove = address.findIndex(
        (product) => product.id === item.id
      );
      address[indexToRemove].is_default = 1;
      this.setState((prevState) => ({
        ...prevState,
        address,
        loaderForAddress: false,
      }));
      toast.success("Default address has been updated successfully");
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({ loaderForAddress: false });
    }
  };

  render() {
    const { isLoggedIn } = this.props.auth;
    const {
      isShowAddAddressPage,
      isShowEditAddressPage,
      loaderForAddress,
      address,
      editAddress,
    } = this.state;
    let address_type;
    return (
      <div>
        <Helmet>
          <title>Address Book | </title>
          <meta name="description" content="" />
        </Helmet>

        <Breadcrumb showBack={this.state.showBack} title={"Address Book"} />

        {/*Dashboard section*/}
        <section className="section-b-space address_form_users">
          <div className="container">
            <div className="row">
              {/*{isLoggedIn && <DahsboardSidebar {...this.props} />}*/}
              {isLoggedIn && !isShowAddAddressPage && !isShowEditAddressPage && (
                <div className="col-lg-12">
                  <div className="dashboard dashboard-right register-page">
                    <div className="theme-card">
                      <form className="theme-form ">
                        <div className={(address && address.length == 0)?'text-center':""}>
                          <button
                            className="btn btn-solid"
                            onClick={(e) => this.shoAddPage(e)}
                          >
                            ADD
                          </button>
                        </div>
                        <div className="row check-out">
                          <div className="col-md-12">
                            {console.info("new Address render", address && address.map((value)=> value))}
                            {address && address.map((item, index) => {
                              address_type = "Home";
                              if (item.address_type === 2) {
                                address_type = "Office";
                              } else if (item.address_type === 3) {
                                address_type = "Site";
                              }
                              return (
                                <div
                                  className="row mt-3"
                                  key={index}
                                  style={{
                                    lineHeight: "25px",
                                  }}
                                >
                                  <div className="col-1">
                                    <input
                                      type="radio"
                                      name="address"
                                      defaultChecked={
                                        item.is_default == 1 ? true : false
                                      }
                                      onClick={(e) =>
                                        this.handleDefaultChange(e, item)
                                      }
                                    />
                                  </div>
                                  <div className="col-9">
                                    <div>
                                      {item.user_name} <span className="badge bg-primary-new text-white">{address_type}</span>
                                    </div>
                                   <div className="set-line-height1">{`${item.flat_no != null  ? item.flat_no + ", " : ""}` +item.address_1}</div>

                                    <div> <b>Landmark</b>: {item.landmark}</div>
                                    <div> <b>City</b>: {item.city_name}</div>
                                    <div> <b>State</b>: {item.state_id}</div>
                                    <div> <b>Country</b>: {item.country_id}</div>

                                   <div> <b>Phone Number</b>: {item.mobile_no}</div>
                                   <div> <b>Supervisor Name:</b> {item.supervision_name}</div>
                                  </div>
                                  <div className="col">
                                    <div className="box pull-right">
                                      <a
                                        href="javascript:;"
                                        onClick={(e) =>
                                          this.handleEditClick(e, item)
                                        }
                                      >
                                        Edit
                                      </a>{" "}
                                      |{" "}
                                      <a
                                        href="javascript:;"
                                        onClick={(e) =>
                                          this.handleDeleteClick(e, item)
                                        }
                                      >
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}


            </div>
          </div>

          <div className="container-fluid">
            <div className="row">

              {/* Add New Address Page */}
              {isLoggedIn && isShowAddAddressPage && (
                  <AddNewAddress
                      countryData={countryData}
                      shoAddPage={this.shoAddPage}
                      addNewAddress={this.addNewAddress}
                      loaderForAddress={loaderForAddress}
                  />
              )}

              {/* Edit Address Page */}
              {isLoggedIn && isShowEditAddressPage && (
                  <EditAddress
                      countryData={countryData}
                      editAddress={editAddress}
                      showEditPage={this.showEditPage}
                      editAddressApi={this.editAddressApi}
                      loaderForAddress={loaderForAddress}
                  />
              )}

            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  symbol: state.data.symbol,
});

  export default connect(
  mapStateToProps,
  { setUserSignUpSuccess }
)(ManageAddress);

import React, {Component} from "react";
import {Link} from "react-router-dom";
import {checkAndRedirectLogin, declareWishlistAvailability} from "../../utils/helper";
import Breadcrumb from "../common/breadcrumb";
import {addToCartAndRemoveWishlist, removeFromWishlist} from "../../actions";
import {deleteWishlist_api, listWishlist_api, removeAndAddToCart_api,} from "../../api/product";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";

class ProductWishList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isApiCalled: false,
            wishlists: [],
        };
    }

    componentDidMount() {
        if (!checkAndRedirectLogin()) {
            toast.info("Please login to continue");
            this.props.history.push({
                pathname: "/login",
            });
        } else {
            this.getWishlistData();
        }
    }

    getWishlistData = async () => {
        try {
            this.setState({isApiCalled: true});
            let apiParams = {
                language_id: 1,
            };
            let apiData = await listWishlist_api(apiParams);
            this.setState({isApiCalled: false, wishlists: apiData.data});
        } catch (error) {
            this.setState({isApiCalled: false});
            console.log(error);
            toast.error(error.message);
        }
    };

    removeFromWishlist = async (item) => {
        try {
            this.setState({isApiCalled: true});
            let apiParams = {
                wishlist_id: item.wishlist_id,
            };
            let apiData = await deleteWishlist_api(apiParams);
            if (apiData.success) {
                const wishlists = [...this.state.wishlists];
                let indexToRemove = wishlists.findIndex(
                    (product) => product.wishlist_id === item.wishlist_id
                );
                wishlists.splice(indexToRemove, 1);
                this.setState({wishlists});
                toast.success(apiData.message);
            }
            this.setState({isApiCalled: false});
        } catch (error) {
            this.setState({isApiCalled: false});
            console.log(error);
            toast.error(error.message);
        }
    };

    addToCartAndRemoveWishlist = async (item) => {
        try {
       
            if(item.is_out_of_stock === 0){
                this.setState({isApiCalled: true});
                let apiParams = new FormData();
                apiParams.append('wishlist_id',item.wishlist_id)
                apiParams.append('product_id', item.id);
                apiParams.append('user_id', this.props.auth.userData.id);
                apiParams.append('quantity', '1');
                apiParams.append('option_val', '[]');
                apiParams.append('session_id', null);

                let apiData = await removeAndAddToCart_api(apiParams);
                
                if (apiData.success) {
                const wishlists = [...this.state.wishlists];
                let indexToRemove = wishlists.findIndex(
                    (product) => product.wishlist_id === item.wishlist_id
                );
                wishlists.splice(indexToRemove, 1);
                this.setState({wishlists});
                this.props.addToCartAndRemoveWishlist(item, 1);
            }
                this.setState({isApiCalled: false});

            } else if(item.is_out_of_stock === 1){
                toast.error(`This product "OUT OF STOCK" you can't add to cart`); 
            }
            else{
                toast.error("something was wrong ");
            }
        
        } catch (error) {
            this.setState({isApiCalled: false});
            console.log(error);
            toast.error(error.message);
        }
    };

    changeQty = (e) => {
        this.setState({quantity: parseInt(e.target.value)});
    };

    render() {
        const {Items, symbol} = this.props;
        return (
            <div>
                <div className="wishlist-table-d-none ">
                    <Breadcrumb title={"Wishlist"}/>
                </div>

                {this.state.wishlists.length > 0 && !this.state.isApiCalled ? (
                        <section className="wishlist-section section-b-space">
                            <div className="container">
                                {this.state.wishlists.map((item, index) => {
                                return <div className="wishlist-d-flex"> <div className="row">
                                    <div className="col-4">
                                        <Link to={`${process.env.PUBLIC_URL}/${item.product_slug}/p/${item.id}`}>
                                            <img className="small_image_80" src={item.product_image} alt=""/>
                                        </Link>
                                    </div>
                                    <div className="col-8">
                                    <Link to={`${process.env.PUBLIC_URL}/${item.product_slug}/p/${item.id}`}>
                                        <p className="mb-1 "><b>{item.product_name}</b> </p>
                                        <p> Availability: <span
                                            className={'font-weight-bold check___' + declareWishlistAvailability(item)}>{declareWishlistAvailability(item)}</span>
                                        </p>
                                    </Link>
                                    <div className="col-12">
                                    <h5 className="theme_text_set pt-2">
                                    {item.special_price !== "" &&
                                                                item.special_price !== 0 && (
                                                                    <span className="money ">
                                                                        <del>
                                                                            {symbol}{item.product_price.toFixed(2)}
                                                                        </del>
                                                                        <br/>
                                                                    </span>
                                                                )}
                                                                {symbol}{" "}
                                                                {(item.product_price !== "" &&
                                                                item.product_price !== 0) && (item.wholesale_price == 0 || item.wholesale_price == "") 
                                                                    ? item.product_price.toFixed(2)
                                                                    : ""}
                                                                {(item.wholesale_price !== "" &&
                                                                item.wholesale_price !== 0) || (item.product_price == 0 || item.product_price == "")
                                                                    ? item.wholesale_price.toFixed(2)
                                                                    : ""}
                                                                    <span
                                                className="-peace">{item.unit_name ? '/ ' + item.unit_name : ""}</span>
                                    </h5>
                                    </div>
                                    <div className="row">
                                    <div className="col-6">
                                    <Link to="#" className="btn btn-solid wishlist-new-btn" 
                                    onClick={() => { this.addToCartAndRemoveWishlist(item);}}>
                                            Add to Cart
                                    </Link>
                                    </div>
                                    <div className="col-6">
                                    <Link to="#" className="btn btn-solid wishlist-new-btn1" onClick={() => {
                                                                                // this.props.removeFromWishlist(item)
                                                                                this.removeFromWishlist(item);
                                                                            }}>
                                            Remove
                                    </Link>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                <br/></div>
                                })}
                                <div className="row wishlist-table-d-none">
                                    <div className="col-sm-12">
                                        <table className="table cart-table table-responsive-xs wishlist-table-res">
                                            <thead>
                                            <tr className="table-head">
                                                <th  scope="col-2" className="text-left">product image</th>
                                                <th scope="col-2" className="text-center">product Name</th>
                                                <th scope="col-2" className="text-left">price</th>
                                                <th scope="col-2" className="text-left">availability</th>
                                                <th scope="col-3">action</th>
                                            </tr>
                                            </thead>
                                            { console.log(this.state.wishlists,"this.state.wishlists")
                                            
                                            }
                                            {this.state.wishlists.map((item, index) => {
                                                return (
                                                    <tbody key={index}>
                                                        {console.log(declareWishlistAvailability(item)) }
                                                    <tr>
                                                        <td className="text-left align-top" >
                                                            <div className="d-inline-block mx-2">
                                                                <Link to={`${process.env.PUBLIC_URL}/${item.product_slug}/p/${item.id}`}>
                                                                    <img className="small_image_80" src={item.product_image} alt=""/>
                                                                </Link>
                                                            </div>
                                                            
                                                        </td>
                                                        <td className="text-center">
                                                        <div className="d-inline-block text-left mx-2 align-top style_text_name w-auto">
                                                                <Link to={`${process.env.PUBLIC_URL}/${item.product_slug}/p/${item.id}`}>

                                                                    <p className="mb-1 "><b>{item.product_name}</b> </p>
                                                                    {/* <p> Availability: <span
                                                                        className={'check___' + declareWishlistAvailability(item)}>{declareWishlistAvailability(item)}</span>
                                                                    </p> */}
                                                                </Link>
                                                            </div>
                                                            <div className="mobile-cart-content row">

                                                                <div className="col-xs-3">
                                                                    <h2 className="td-color">
                                                                        {symbol}
                                                                        {item.special_price !== "" &&
                                                                        item.special_price !== 0
                                                                            ? item.special_price
                                                                            : item.product_price}{" "}
                                                                        {item.special_price !== "" &&
                                                                        item.special_price !== 0 && (
                                                                            <span className="money">
                                                                                <del>
                                                                                    {symbol}
                                                                                    {item.product_price}
                                                                                </del>
                                                                            </span>
                                                                        )}
                                                                    </h2>
                                                                </div>
                                                                <div className="col-xs-3">
                                                                    <h2 className="td-color">
                                                                        <a
                                                                            href="javascript:void(0)"
                                                                            className="icon"
                                                                            onClick={() => {
                                                                                // this.props.removeFromWishlist(item)
                                                                                this.removeFromWishlist(item);
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-times fa-3x"/>
                                                                        </a>
                                                                        {/*<a
                                                                            href="javascript:void(0)"
                                                                            className="cart"
                                                                            onClick={() => {
                                                                                // this.props.addToCartAndRemoveWishlist(item, 1)
                                                                                this.addToCartAndRemoveWishlist(item);
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-shopping-cart"/>
                                                                        </a>*/}
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-left">
                                                            <h2 className="theme_text_set">


                                                                {item.special_price !== "" &&
                                                                item.special_price !== 0 && (
                                                                    <span className="money ">
                                                                        <del>
                                                                            {symbol}{item.product_price.toFixed(2)}
                                                                        </del>
                                                                        <br/>
                                                                    </span>
                                                                )}
                                                                {symbol}{" "}
                                                                {(item.product_price !== "" &&
                                                                item.product_price !== 0) && (item.wholesale_price == 0 || item.wholesale_price == "") && (item.special_price == "") 
                                                                    ? item.product_price.toFixed(2)
                                                                    : ""}
                                                                {(item.special_price !== "") ? item.special_price : ""}
                                                                {(item.wholesale_price !== "") && (item.special_price == "") ? item.wholesale_price : ""}
                                                                    <span
                                                className="-peace">{item.unit_name ? '/ ' + item.unit_name : ""}</span>

                                                            </h2>
                                                        </td>
                                                        <td className="text-left">
                                                        <span className={'font-weight-bold check___' + declareWishlistAvailability(item)}>{declareWishlistAvailability(item)}</span>
                                                        </td>

                                                        <td className="action-col action-min-box">
                                                        <div className="action-box">
                                                            <div className="whitabCent">
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        className="icon"
                                                                        onClick={() => {
                                                                            // this.props.removeFromWishlist(item)
                                                                            this.removeFromWishlist(item);
                                                                        }} >
                                                                        <i class="fa fa-trash text-danger remove-btn" aria-hidden="true"></i>
                                                                    </a>
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        className="icon shopCartTiocn"
                                                                        onClick={() => {
                                                                            // this.props.removeFromWishlist(item)
                                                                            this.removeFromWishlist(item);
                                                                        }}
                                                                    >
                                                                        {/* <img src={`${process.env.PUBLIC_URL}/assets/images/icon/shopping-cart.png`} className="img-fluid" alt=""/> */}
                                                                    </a>
                                                            </div>

                                                                <div className="whitabCent" onClick={() => { this.addToCartAndRemoveWishlist(item); }}>
                                                                    <i className="fa fa-cart-arrow-down fa-3x add-cart-btn"/>
                                                                </div>
                                                            </div>
                                                            {/* <a
                                                                href="javascript:void(0)"
                                                                className="cart"
                                                                
                                                            > 
                                                               
                                                            {/* </a> */}
                                                        </td>

                                                       
                                                    </tr>
                                                    </tbody>
                                                );
                                            })}
                                        </table>
                                    </div>
                                </div>
                                {
                                <div className="row wishlist-buttons text-left wishlist-table-d-none">
                                    <div className="col-12">
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/`}
                                            className="btn btn-solid"
                                        >
                                            continue shopping
                                        </Link>
                                        {/* <Link
                                            to={`${process.env.PUBLIC_URL}/checkout`}
                                            className="btn btn-solid"
                                        >
                                            check out
                                        </Link> */}
                                    </div>
                                </div>
                                }
                            </div>
                        </section>
                    ) :
                    (this.state.isApiCalled) ?
                        <div className="row">
                            <div
                                className="col-sm-12 text-center section-b-space mt-5 mb-5 no-found">
                                <div className="loading-cls"/>
                            </div>
                        </div>
                        :

                        <section className="cart-section section-b-space">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div>
                                            <div className="col-sm-12 empty-cart-cls text-center">
                                                <img
                                                    src={`${
                                                        process.env.PUBLIC_URL
                                                    }/assets/images/empty-wishlist.png`}
                                                    className="img-fluid mb-4"
                                                    alt=""
                                                />
                                                <h3>
                                                    <strong>WhishList is Empty</strong>
                                                </h3>
                                                <h4>Explore more shortlist some items.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    Items: state.wishlist.list,
    symbol: state.data.symbol,
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    {addToCartAndRemoveWishlist, removeFromWishlist}
)(ProductWishList);

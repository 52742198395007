import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import '../../common/index.scss';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {getHomeDashboardModules, getHomePageBrands, getHomeSliders, getoffers_home,getProductPositionList} from "../../../actions/index";

// Import custom components
import Category from "../../features/theme/element-category";
import SpecialProducts from "../common/products";
import BlogSection from "../common/blogsection"
import Collection from "../../pages/collection";
// import LogoBlock from "../common/logo-block";
import BrandsLogo from "../home/BrandsLogo";

import LookBook from "../../pages/lookbook";
import ElementBanner from '../../features/theme/element-banner';
import CenterBanner from "./centerBanner";
import BottomBanner from "./BottomBanner";

import {
    getOffersList_api
} from "../../../api/home";
import {toast} from "react-toastify";

import Carousel from 'react-elastic-carousel'
import { video_help_api } from '../../../api/pages';
class Fashion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            video_list: [],
        
        };
    }

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#`);
        // get all home page sliders & brands
        this.props.getHomeSliders();
        // this.props.getHomePageBrands();
        this.props.getHomeDashboardModules();
        this.props.getProductPositionList();
        this.props.getoffers_home();
        this.VideoHelplist()

    }
    
    getofferlist = async () => {

        try {
            this.setState({loaderForPlaceOrder: true});
            let apiParams = {
                language_id: 3
            };
            // console.log(apiParams);
            let offerlist = await getOffersList_api(apiParams);
            if (offerlist.success) {
                this.setState({
                    offerlist: offerlist.data,
                });
            } else {
                toast.error(offerlist.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }

    };

    VideoHelplist = async () => {
        try {
            this.setState({loaderForPlaceOrder: true});
            let apiParams = {
                language_id: 3
            };

            let video_list = await video_help_api(apiParams);
            console.table("video_list",video_list,video_list.data);
            if (!video_list.success) {
                this.setState({
                    video_list: video_list.data,
                });
            } else {
                toast.error(video_list.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }

    };
    render() {
        const {sliders, dashboardModulesnew,product_top,product_center,product_bottom} = this.props;
        console.log(product_bottom, "product_bottom")

        console.log(sliders, "this.slideras")

        let url;
        const settings = {
            dots: true,
            autoplay: true,
            infinite: true,
            lazyLoad: true,
            speed: 1000,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        function checkData(sliders) {
            if(sliders.slider_image_web_url && sliders.category_id && sliders.slider_status == 1) {
                return true
            }   
        }

        // console.log(this.props,"this.props")

        return (
            <div>
                {/*Home Slider*/}

                <section className="p-0 ">
                    <Slider className=" slide-1 home-slider" {...settings}>
                        {sliders.map((slider, i) => {
                            if (slider.offer_id !== 0 && slider.category_id === 0)
                                url = `${process.env.PUBLIC_URL}/offer/` + slider.offer_id + `/products`
                            else if (slider.category_id !== 0) {
                                url = `category/${slider.category_id}/products`;
                            } else {
                                url = `product-/p/${slider.product_id}`;
                            }
                            console.log(slider, "this.filter")
                            return (
                                <div key={i}>
                                          <Link to={url}>
                                            {/* <img src={`${slider.slider_image_url}`} alt="" className="home text-center w-100"/> */}
                                             <div
                                            className="home text-center"
                                            style={{
                                                backgroundImage: `url(${slider.slider_image_web_url})`,
                                            }}>
                                        </div>
                                        {/* <div
                                            className="home text-center"
                                            style={{
                                                backgroundImage: `url(${slider.slider_image_web})`,
                                            }}
                                        >
                                        </div> */}
                                        </Link>
                                </div>
                            );
                        })}
                    </Slider>
                </section>
                {/*Home Section End*/}

                {/*collection banner*/}
                <section className="">
                    <Category/>
                </section>

                <section>
                    {/*Offers Section Center*/}
                    <LookBook position={"top"} />
                    {/*Offers Section Center*/}
                </section>
                {/*collection banner end*/}
                
                {(product_top && product_top) ?
                    <SpecialProducts show_count={10} items={product_top} type="Top" />
                : ""}

                {/*<Collection productTitle="COMBO DEALS ON"/>*/}

                <CenterBanner dashboardModulesnew={dashboardModulesnew}/>

                {/*Offers Section center*/}
                {/* <LookBook position={"center"} /> */}
                {/*Offers Section center*/}
                {(product_center && product_center) ?
                    <SpecialProducts show_count={10} items={product_center} type="Top" />
                    : ""} 

                {/*Parallax banner*/}

                <BottomBanner dashboardModulesnew={dashboardModulesnew}/>

                {/*Offers Section end*/}
                <LookBook position = "center"/>
                {/*Offers Section end*/}

                {/*Parallax banner End*/}

                {/* <SpecialProducts /> */}

                {/*service layout*/}

                {/*Blog Section end*/}
                {/* <Trending trendTitle="Special Products" type={'watch'}/> */}

                {/*<MultiSlider type={'women'} trendTitle="" />*/}

               {(product_bottom && product_bottom) ?
                    <SpecialProducts show_count={10} items={product_bottom} type="Top" />
                    : ""} 

                {/*temp hide*/}
                {/*<ElementBanner/>*/}

                <div className='container-fluid'>
                    <div className="col mb-2">
                                <div className="title1 section-t-space">
                                    <h2 className="title-inner1 font-size-text-30">Video Tutorials</h2>
                                </div>
                            </div>
                    <div className='row'>
                    {
                                     this.state.video_list &&   this.state.video_list.map((data,index)=>(
                                            data.status === 1 ? 
                                            <div className="col-sm-12 col-md-3 col-lg-3 video_help">
                                                <Link to={`${process.env.PUBLIC_URL}/video-help/${data.id}`} >
                                                        <div className="card bf-img-viddeo m-30">

                                                        <iframe src='https://www.youtube.com/embed/E7wJTI-1dvQ'
                                                                    allow='autoplay; encrypted-media'
                                                                    allowFullScreen
                                                                    title='video'
                                                                    height={400}
                                                            />

                                                                {/* <div className="card-body video-tutorial-box">
                                                                    <img src={`${data.thumbnail}`} alt="" className="home text-center w-100 videothumbnail"/> 
                                                                </div> */}
                                                                <div className="card-footer bf-img-viddeo-footer bg-theme-blue">
                                                                    <div className="p-2">
                                                                        <h6 className="my-0 text-white d-block video-name">{data.name}</h6>
                                                                        <h6 className="my-0 text-white d-block video-info mt-2">{data.description.slice(0,40)}...</h6>
                                                                    </div>
                                                                </div>
                                                        </div> 
                                                </Link>
                                            </div> 
                                        : <></>
                                        ))
                                    }
                      
                    </div>
                </div>
                {/*logo section*/}
                <LookBook position={"bottom"} />
                <BrandsLogo  {...this.props}/>
                {/*logo section end*/}

                {/*<TopCollection ItemTitle="" type={'women'} />*/}
                {(dashboardModulesnew && dashboardModulesnew.recent_view_product) ?
                    <SpecialProducts  show_count={10} items={dashboardModulesnew.recent_view_product} type="fix" classname="responsive-recent"/>
                : ""} 
            </div>
        )


    }
}

const mapStateToProps = (state) => ({
    brands: state.home.brands,
    sliders: state.home.sliders,
    dashboardModules: state.home.dashboardModules,
    dashboardModulesnew: state.home.dashboardModulesnew,
    product_top: state.home.product_top,
    product_center: state.home.product_center,
    product_bottom: state.home.product_bottom,
});

export default connect(
    mapStateToProps,
    {
        // getHomePageBrands,
        getHomeSliders,
        getHomeDashboardModules,
        getProductPositionList,
        getoffers_home
    }
)(Fashion);

import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {faqList_api} from "../../api/pages";
import {toast} from "react-toastify";
import {checkAndRedirectLogin} from "../../utils/helper";

class Faq extends Component {

    constructor(props) {
        super(props)
        this.state = {
            FAQlist: [],
            show: 0,
        };
    }

    componentDidMount() {
        this.FAQlist()
    }

    FAQlistshow(index) {
        this.setState({show: index});
    }

    FAQlist = async () => {

        try {
            this.setState({loaderForPlaceOrder: true});
            let apiParams = {
                language_id: 1
            };
            // console.log(apiParams);
            let faqList = await faqList_api(apiParams);
            if (faqList.success) {
                this.setState({
                    FAQlist: faqList.data,
                });
            } else {
                toast.error(faqList.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }

    };

    render() {

        document.getElementById("footer_id").setAttribute("style","display:none");
        document.getElementById("header_id").setAttribute("style","display:none");
        
        return (
            <div>
                <Breadcrumb title={'Faq'}/>


                {/*Dashboard section*/}
                <section className="faq-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="accordion theme-accordion" id="accordionExample">
                                    {
                                        (this.state.FAQlist && this.state.FAQlist.length > 0) ?
                                            this.state.FAQlist.map((values, index) =>
                                                (
                                                    <div className="card">
                                                        <div className="card-header" id="headingOne" onClick={() => {
                                                            this.FAQlistshow(index)
                                                        }}>
                                                            <h5 className="mb-0">
                                                                <button className="btn btn-link" type="button"
                                                                        data-toggle="collapse"
                                                                        data-target={"#collapseOne" + index}
                                                                        aria-expanded={(this.state.show === index)}
                                                                        aria-controls={"collapseOne" + index}
                                                                        >
                                                                    {values.faq_question}
                                                                </button>
                                                            </h5>
                                                        </div>

                                                        <div id={"collapseOne" + index} className={this.state.show === index?"show collapse":"collapse"}
                                                             aria-labelledby="headingOne"
                                                             data-parent="#accordionExample">
                                                            <div className="card-body">
                                                                {values.faq_answer}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            ) : ""
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Faq
import React, {Component} from "react";
import Breadcrumb from "../common/breadcrumb";
import {Slider2, svgFreeShipping, Team4} from "../../services/script"
import Slider from 'react-slick';
import {Slider6} from "../../services/script";
import {useSelector, useDispatch} from "react-redux";
import { connect } from 'react-redux';
import {
    getAboutUspage,
} from "../../actions/index";


class aboutUs extends Component {
    componentDidMount() {
        this.props.getAboutUspage({'content_type':2});


        try {
            if(window.location.href.search("sp") >= 1)
            {
            document.getElementById("footer_id").setAttribute("style","display:none");
            document.getElementById("header_id").setAttribute("style","display:none");
            document.getElementById("breadcrumb_id").setAttribute("style","display:none");
            }
          }
          catch(err) {
            
          }


    }

    constructor (props) {
        super (props)
    }

    render (){

        const {aboutUs} = this.props;

        return (
            <div>
                { window.location.href.search("sp") <= 1 ? <Breadcrumb title={aboutUs.page_name}/> : "" }
                {/*about section*/}
                <section className="about-page  section-b-space">
                    <div className="container">
                        <div className="row">
                            {/*<div className="col-lg-12">
                                <div className="banner-section">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/about/about%20us.jpg`} className="img-fluid" alt=""/>
                                </div>
                            </div>*/}
                            <div className="col-sm-12">
                                <div dangerouslySetInnerHTML={{ __html: aboutUs.page_content }} />
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}


const mapStateToProps = state => ({
    aboutUs: state.pages.aboutUs,
});

const mapDispatchToProps = {getAboutUspage};

export default connect(mapStateToProps, mapDispatchToProps)(aboutUs);
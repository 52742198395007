import React, {Component} from 'react'
import {Link} from 'react-router-dom'

export default class ServiceHomePageComp1 extends Component {
    render() {
        const {servicelist} = this.props;

        console.log(servicelist, "servicelist data")
        return (
            <>
                {
                    (servicelist && servicelist) ? servicelist.map((data, index) => {
                        return <>
                            {(data && data.services && data.services.length >= 1) ?
                                <>
                                <div className="container servProT">
                                    <div className='title1 section-t-space row' style={{paddingTop: "0px"}} id='proTitle'>
                                        <div className="col-8 col-lg-12">
                                            <h2 className='title-inner1 font-size-text-30'>{data.title}</h2>
                                        </div>
                                        <div className="col-4 my-auto col-lg-12">
                                        <Link to={`/services/${data.title}/${data.id}`}>
                                            <h6 className='new_view_all_ title-inner1 font-weight-bold pl-1'>
                                                view all
                                            </h6>
                                        </Link>
                                        </div>
                                    </div>
                                </div>
                                    <section className='section-b-space servprovP2'>
                                        <div className='container-fluid '>
                                            <div className='row mx-md-2 mx-xl-5'>
                                                <div className='d-flex flex-wrap w-100 justify-content-start box_res_mob'>
                                                    {
                                                        (data && data.services) ? data.services.map((value, index) => {
                                                            return <div className='setproducts'>
                                                                <div className='product-box product-wrap mx-2 my-3'>
                                                                    <div className='img-wrapper'>
                                                                        <Link
                                                                            to={`${process.env.PUBLIC_URL}/service-providers/${value.id}`}>
                                                                            <div className='front'>
                                                                                <div
                                                                                    className='img-fluid'
                                                                                    style={{
                                                                                        backgroundImage: `url(${value.image_url})`,
                                                                                    }}
                                                                                ></div>
                                                                            </div>
                                                                        </Link>
                                                                        {/* <div className='cart-info cart-wrap'>
                                                                            <a
                                                                                href='javascript:void(0)'
                                                                                title='Add to Wishlist'
                                                                            >
                                                                                <i
                                                                                    className='fa fa-heart'
                                                                                    aria-hidden='true'
                                                                                ></i>
                                                                            </a>
                                                                            <a
                                                                                href='javascript:void(0)'
                                                                                data-toggle='modal'
                                                                                data-target='#quick-view'
                                                                                title='Quick View'
                                                                            >
                                                                                <i
                                                                                    className='fa fa-search'
                                                                                    aria-hidden='true'
                                                                                ></i>
                                                                            </a>
                                                                        </div> */}
                                                                    </div>


                                                                    <div className='media-body align-self-center'>
                                                                        <Link
                                                                            to={`${process.env.PUBLIC_URL}/service-providers/${value.id}`}
                                                                        >
                                                                            <h5>{value.service_name}</h5>
                                                                        </Link>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }) : ""
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </> : ""}
                        </>
                    }) : ""
                }
            </>

        )
    }
}

import React, { useCallback } from "react";
import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";

function Razorpay({ name,email,phone,payment,OrderAPi }) {
  const Razorpay = useRazorpay();
  const handlePayment = useCallback(() => {
    console.log(payment,"payment")
    
    const options = {
      key: "rzp_test_0FDKYlrG8MBQ8Q",
      amount: payment,
      currency: "INR",
      name: "Acme Corp",
      description: "Test Transaction",
      image: "https://beta.infrawheel.com/admin/public/stores/store_logo1652797331_2yr0wn.webp",
      order_id: "",
      handler: (res) => {
        if(res.razorpay_payment_id){
            OrderAPi({id:res.razorpay_payment_id})
        }else{
            toast.error("Payment failed");
        }    
      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);

  return (
    <div className="App">
      <button
        type="button"
        className="btn-solid btn w-100"
        onClick={()=>handlePayment()}>
        PAY NOW
      </button>
    </div>
  );
}
export default Razorpay;

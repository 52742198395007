import React, { Component } from 'react'
import ProductListing from "../collection/common/DashboardProductListing";
import ServiceProductListing from '../collection/common/ServiceProductListing';
import {connect} from "react-redux";
import {
    getAllDashboardProducts,
    filterSort,
    filterBrand,
    getAllDashboardProductFilters,
    resetFilterAndfSort,
    resetDashboardProducts,
} from "../../actions";
import Breadcrumb from "../common/breadcrumb";
import { getServiceProviderType } from '../../api/home';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';


class ServiceProviderTypes extends Component {
    state = {
        data : [],
        serviceId: this.props.match.params.id

    };
    
    componentDidMount() {
        // check whether sidebar is open or not
        this.closeNav();
        this.getProviderType()
    }
    closeNav() {
        var closemyslide = document.getElementById("mySidenav");
        if (closemyslide) closemyslide.classList.remove("open-side");
    }

    getProviderType = async () => {

        try {
            let response = await getServiceProviderType();

            console.log("provider type",response);

            this.setState({
                data: response.result.data
            })
         
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
  
      };
    render() {
        return (
            <div>
                <Breadcrumb title={"ServiceProvider type"} />
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        <div className="mx-2 prolistTop">
                            {/* <ServiceProductListing dashboardId={this.state.dashboardId} colSize={this.state.layoutColumns}/> */}

                            <div className='service_provider_wrapper'>
                                <div className='service_provider_wrap'>
                                    <ul className='service_provider_block'>
                                        <li>
                                            <Link to={ this.state.data[0]? `/service-providers/${this.state.serviceId}/${this.state.data[0].id}`: ""}>
                                            <div className='service_provider_sec'>
                                                <div className='service_title'>
                                                    <h2>{this.state.data[0] && this.state.data[0].title}</h2>
                                                    <div className='service_img'>
                                                        <img src={this.state.data[0] ? this.state.data[0].image_url : ""} />
                                                    </div>
                                                </div>
                                                <div className='service_wrap'>
                                                    {
                                                        this.state.data[0] && this.state.data[0].web_type.map((item) => {
                                                            return    <div className='service_block'>
                                                            <h3>{item.subtitle}</h3>
                                                            <p>{item.text}</p>
                                            
                                                        </div>
                                                        })
                                                    }
                                                 
                                                    {/* <div className='service_block'>
                                                        <h3>EXPERIENCE</h3>
                                                        <p>3 years + experience</p>
                                                    </div>
                                                    <div className='service_block'>
                                                        <h3>TEAM</h3>
                                                        <p>1</p>
                                                    </div>
                                                    <div className='service_block'>
                                                        <h3>EXPERTIZE</h3>
                                                        <p>small works for individual homes.</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                            </Link>
                                        </li>
                                        <li>
                                        <Link to={ this.state.data[1]? `/service-providers/${this.state.serviceId}/${this.state.data[1].id}`: ""}>
                                            <div className='service_provider_sec'>
                                                <div className='service_title purple_color'>
                                                <h2>{this.state.data[1] && this.state.data[1].title}</h2>
                                                    
                                                    <div className='service_img'>
                                                    <img src={this.state.data[1] ? this.state.data[1].image_url : ""} />
                                                        
                                                    </div>
                                                </div>
                                                <div className='service_wrap'>
                                                {
                                                        this.state.data[1] && this.state.data[1].web_type.map((item) => {
                                                            return    <div className='service_block'>
                                                            <h3>{item.subtitle}</h3>
                                                            <p>{item.text}</p>

                                                        </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            </Link>
                                        </li>
                                        <li>
                                        <Link to={ this.state.data[2]? `/service-providers/${this.state.serviceId}/${this.state.data[2].id}`: ""}>
                                            <div className='service_provider_sec'>
                                                <div className='service_title blue_color'>
                                                    <h2>{this.state.data[2] && this.state.data[2].title}</h2>
                                                    <div className='service_img'>
                                                        <img src={this.state.data[2] ? this.state.data[2].image_url : ""} />
                                                    </div>
                                                </div>
                                                <div className='service_wrap'>
                                                {
                                                        this.state.data[2] && this.state.data[2].web_type.map((item) => {
                                                            return    <div className='service_block'>
                                                            <h3>{item.subtitle}</h3>
                                                            <p>{item.text}</p>
                                              
                                                        </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            </Link>
                                        </li>
                                        <li>
                                        <Link to={ this.state.data[3]? `/service-providers/${this.state.serviceId}/${this.state.data[3].id}`: ""}>
                                            <div className='service_provider_sec'>
                                                <div className='service_title green_color'>
                                                    <h2>{this.state.data[3] && this.state.data[3].title}</h2>
                                                    <div className='service_img'>
                                                        <img src={this.state.data[3] ? this.state.data[3].image_url : ""} />
                                                    </div>
                                                </div>
                                                <div className='service_wrap'>
                                                {
                                                        this.state.data[3] && this.state.data[3].web_type.map((item) => {
                                                            return    <div className='service_block'>
                                                            <h3>{item.subtitle}</h3>
                                                            <p>{item.text}</p>
                                                        
                                                        </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            </Link>
                                        </li>
                                        <li>
                                        <Link to={ this.state.data[4]? `/service-providers/${this.state.serviceId}/${this.state.data[4].id}`: ""}>
                                            <div className='service_provider_sec'>
                                                <div className='service_title yellow_color'>     
                                                    <h2>{this.state.data[4] && this.state.data[4].title}</h2>
                                                    <div className='service_img'>
                                                        <img src={this.state.data[4] ? this.state.data[4].image_url : ""} />
                                                    </div>
                                                </div>
                                                <div className='service_wrap'>
                                                {
                                                        this.state.data[4] && this.state.data[4].web_type.map((item) => {
                                                            return    <div className='service_block'>
                                                            <h3>{item.subtitle}</h3>
                                                            <p>{item.text}</p>
                                                        
                                                        </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filters: state.filters,
    fetchDashboardProductLoader: state.data.fetchDashboardProductLoader,
    __barnd_name: state.data.__barnd_name,
    __list_name: state.data.__list_name,
});

export default connect(
    mapStateToProps,
    {
        getAllDashboardProducts,
        filterSort,
        getAllDashboardProductFilters,
        filterBrand,
        resetFilterAndfSort,
        resetDashboardProducts,
    }
)(ServiceProviderTypes);
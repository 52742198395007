import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
  getSingleOrder_api,
  setCancelledOrder_api,
  setReturnOrders_api,
  setReview_api,
} from "../../api/product";
import moment from "moment";
import { toast } from "react-toastify";
import { Steps, Modal } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import { editProfileImage_api } from "../../api/auth";
import addimage from "../../access/addimage.png";
import { connect } from "react-redux";
import ApiConstants from "../../api/ApiConstants";

import download_icon from "../../assets/images/download.webp";
import cross from "../../assets/images/cross.webp";

const ADDRESS_TYPE = ["", "Home", "Office", "Site"];
class NewOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderForOrderDetail: false,
      disable_cancel_btn: false,
      orderData: null,
      rangeValue: 0,
      reason_text: "",
      review_text: "",
      review_star: "",
      order_id: "",
      rating: 1,
      popup: 0,
      trackingModal: 0,
      showBack: true,
      orderId: "",
      productId: "",
      trackData: null
    };
  }

  componentDidMount() {
    let { id } = this.props.match.params;
    if (id) {
      this.getOrderDetail();
    }
  }
  
  getOrderDetail = async () => {
    try {
      this.setState({ loaderForOrderDetail: true });
      let apiParams = {
        user_id: this.props.userId,
        id: this.props.match.params.id,
      };
      const address = await getSingleOrder_api(apiParams);
      // console.log(address);
      this.setState({
        loaderForOrderDetail: false,
        orderData: address.data,
        trackData: address.data
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({ loaderForOrderDetail: false });
    }
  };

  addreview = async (e) => {
    try {
      let apiParams = {
        user_id: this.props.userId,
        product_id: this.state.productId,
        order_id: this.state.orderId,
        rating_count: this.state.rating,
        rating_comment: this.state.review_text,
      };

      var rs = await setReview_api(apiParams);

      if (rs.success) {
        toast.success(rs.message);
        this.setState({
          review_text: "",
          review_star: "",
          order_id: "",
          rating: 1,
          popup: 0,
        });
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({ loaderForOrderDetail: false });
    }
  };

  onChange = async (event) => {
    try {
      const files = event.target.files;
      const formData = new FormData();
      this.setState({ imgs: event.target.files });
      formData.append("image", files[0]);
    } catch (error) {
      toast.error(error.message);
    }
  };

  cancelandretunorders = async (e) => {
    try {
      this.setState({disable_cancel_btn: true})
      const formData = new FormData();
      formData.append("user_id", this.props.userId);
      formData.append("order_detail_id", this.state.orderId);
      if (this.state.popup == 1)
        formData.append("cancel_reason_text", this.state.reason_text);
      if (this.state.popup == 2)
        formData.append("return_reason_text", this.state.reason_text);

      // if (this.state.imgs && this.state.imgs[0]) {
      //   formData.append("images", this.state.imgs[0]);
      // }

      console.log("formData", formData);

      if (this.state.popup == 1) var rs = await setCancelledOrder_api(formData);
      if (this.state.popup == 2) var rs = await setReturnOrders_api(formData);
      if (this.state.popup == 0) {
        this.setState({
          reason_text: "",
          popup: 0,
          reviewpopup: 0,
        });
        return false;
      }
      console.log("rs", rs);
      if (rs.success) {
        toast.success(rs.message);
        this.setState({
          reason_text: "",
          popup: 0,
        });
        this.setState({disable_cancel_btn: false})
      } else {
        this.setState({disable_cancel_btn: false})
        console.log("asd");
      }
      setTimeout(() => {
        window.location.reload();

      }, 2000);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      this.setState({ loaderForOrderDetail: false });
      this.setState({disable_cancel_btn: false})
    }
  };

  onCloseModal = (id, orderId) => {
    this.setState({ popup: id, orderId: orderId });
  };

  onCloseModalreview = (id, orderId, productId) => {
    this.setState({ reviewpopup: id, orderId: orderId, productId: productId });
  };

  Orderstatus(status, id, orderData) {
    const returnDate = new Date(moment( new Date(orderData.delivery_date).setDate(new Date(orderData.delivery_date).getDate() + parseInt(orderData.product_details.return_day[0]) )).format("DD-MMMM-YYYY")).getTime();
    const todayDate = new Date().getTime()

    console.log(orderData, "orderData in")
    if ([1, 2, 3, 8, 9, 10, 11, 12].includes(parseInt(status))) {
      return (
          <button
              className="btn btn-light bg-danger w-100 text-white"
              onClick={() => {
                this.onCloseModal(1, id);
              }}
          >
            {/* <h4 className=" mx-auto w-100"  >
        </h4> */}
            Cancel
          </button>
      );
    } else if (parseInt(status) == 5 && returnDate >= todayDate  ) {
      return (
          <div className="row">
            <div className="col-md">
              <button
                  className="btn btn-light bg-danger w-100 text-white return"
                  onClick={() => {
                    this.onCloseModal(2, id);
                  }}
               
              >
                {"Return Or Replace"}
              </button>
            </div>
          </div>
      );
    }
  }
  Orderstatus2(status, id) {
 
    if ([1, 2, 3, 8, 9, 10, 11, 12].includes(parseInt(status))) {
      return (
          <button
              className="btn btn-light bg-danger w-100 text-white"
              onClick={() => {
                this.onCloseModal(1, id);
              }}
          >
            {/* <h4 className=" mx-auto w-100"  >
        </h4> */}
            Cancel
          </button>
      );
    } else if ((parseInt(status) == 5 || parseInt(status) == 7)   ) {
      return (
          <div className="row">
            <div className="col-md">
              <button
                  className="btn btn-light bg-danger w-100 text-white return"
                  onClick={() => {
                    this.onCloseModal(2, id);
                  }}
              >
                {"Return Or Replace"}
              </button>
            </div>
          </div>
      );
    }
  }
  Ordernumber(status) {
    if ([1, 2].includes(parseInt(status))) {
      return 0;
    } else if ([3].includes(parseInt(status))) {
      return 1;
    } else if ([4].includes(parseInt(status))) {
      return 2;
    } else if (parseInt(status) == 5 || parseInt(status) == 7) {
      return 3;
    }
  }

  Orderstatus_text(status, statusnmae) {
    return (
        <strong className="d-block">
          {" "}
          Order Status: <b className="texrt">{statusnmae}</b>{" "}
        </strong>
    );
  }

  setTrackingDates = (date, days) => {
    const orderDate = new Date(Number(date));
    orderDate.setDate(date.getDate() + days);
    return orderDate.toDateString();
  };
  render() {
    const { loaderForOrderDetail, orderData, trackData } = this.state;
    let total = 0;
    let _ship_price = orderData
        ? orderData.group_by.reduce(
            (partial_sum, a) => partial_sum + a.product_ship_price,
            0
        )
        : 0;
    let tax_amount = orderData ? orderData.total_tax_price : 0;
    let orderDeliveryDate = new Date(orderData ? orderData.order_time : "");
    // console.info("Add days logg",moment(this.addDays(orderDeliveryDate, 10)).format("YYYY-MM-DD"))
    return (
        <div>
          <Breadcrumb showBack={this.state.showBack} title={"Order Details"} />
          <section className="section-b-space blog-page px-md-5 px-sm-1 pt-0 order-details-page orderDetailTop">
            {orderData && !loaderForOrderDetail && (
                <>
                  <div className="container cart-d-none-mobile">
                    <div className="row">
                      <div className="col-12">
                    <span className="font-weight-bold float-left mb-3 set_color_dark_1">
                      Order Details
                    </span>
                      </div>
                      {orderData.product_detail &&
                      orderData.product_detail.length > 0
                          ? orderData.product_detail.map((item, index) => {
                            total = total + parseInt(item.product_price_with_tax);
                            return (
                                <div className="container dashboard_orders">
                                  <div className="row">
                                    <div className="col-4">
                                      <Link
                                          to={`${process.env.PUBLIC_URL}/${orderData.product_details.product_slug}/p/${orderData.product_id}`}
                                      >
                                        <img
                                            src={
                                              orderData.product_details.product_image
                                            }
                                            id="my-order-img"
                                            width="100px"
                                            height="100px"
                                            alt=""
                                        />
                                      </Link>

                                      <img
                                          src={orderData.product_details.product_image}
                                          height="100"
                                          width="100"
                                      />
                                    </div>
                                    <div className="col-8">
                                      <ul className="order_details_class_set">
                                        <li>
                                          <strong className="set_color_dark_1">
                                            {orderData.product_details.product_name}
                                          </strong>
                                        </li>
                                        <li>
                                          {orderData &&
                                          orderData.option_data &&
                                          orderData.option_data.length > 0
                                              ? orderData.option_data.map((opt, i) => {
                                                return (
                                                    <strong className="set_color_dark_1 d-block">
                                                      {opt.option_name
                                                          ? opt.option_name +
                                                          ": " +
                                                          opt.option_value_name
                                                          : ""}
                                                    </strong>
                                                );
                                              })
                                              : ""}
                                        </li>
                                        <li>
                                          <strong className="set_color_dark_1">
                                            Quantity :{" "}
                                            {orderData.product_details.order_quantity}
                                          </strong>
                                        </li>
                                        <li>
                                          <strong className="theme_text_set float-right">
                                            {this.props.symbol}
                                            {orderData.total_product_price}
                                            {orderData.product_price_total}
                                          </strong>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="container">
                                      <div className="row">
                                        <div className="col-12 ">
                                          <div className="my-orders">
                                            <ul>
                                              <li>
                                                <strong className="set_color_dark_1">
                                                  <span>Order Number :</span>{" "}
                                                  {orderData.order_id}
                                                </strong>
                                              </li>
                                              <li>
                                                <strong className="set_color_dark_1">
                                                  <span>Order Date :</span>{" "}
                                                  {moment(
                                                      item.product_order_time
                                                  ).format("YYYY-MM-DD")}
                                                </strong>
                                              </li>
                                              <li>
                                                <strong className="set_color_dark_1">
                                                  <span>Estimated Delivery :</span>{" "}
                                                  {moment(item.product_order_time)
                                                      .add(parseInt(10), "d")
                                                      .format("YYYY-MM-DD")}
                                                </strong>
                                              </li>
                                              <li>
                                                <strong className="set_color_dark_1">
                                                  {
                                                    orderData.product_details
                                                        .product_name
                                                  }
                                                </strong>
                                              </li>
                                              <li>
                                                <strong className="set_color_dark_1">
                                                  <span>RETURN UP TO :</span>{" "}
                                                  {moment(
                                                      this.setTrackingDates(
                                                          orderDeliveryDate,
                                                          20
                                                      )
                                                  ).format("YYYY-MM-DD")}
                                                </strong>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="col-12 ">
                                          <div className="delivery-adress">
                                            <strong className="set_color_dark_1">
                                              DELIVERY ADDRESS
                                            </strong>
                                            <ul>
                                              <li>
                                                <strong className="text_black">
                                                  {orderData.bill_address.user_name}
                                                  <span
                                                      className="badge badge-primary ml-2"
                                                      style={{
                                                        color: "#fff",
                                                        backgroundColor: "#047bd5",
                                                      }}
                                                  >
                                              {orderData.bill_address.address_type === 1
                                                  ? "Home"
                                                  : ""}
                                                    {orderData.bill_address.address_type === 2
                                                        ? "Office"
                                                        : ""}
                                                    {orderData.bill_address.address_type === 3
                                                        ? "Site"
                                                        : ""}
                                            </span>
                                                  <p>
                                                  {`${orderData.bill_address.flat_no != null  ? orderData.bill_address.flat_no + ", " : ""}` +orderData.bill_address.address_1}{" "}
                                                  </p>
                                                </strong>
                                              </li>
                                              <li>
                                                <strong>Landmark :</strong>
                                                <p>{orderData.bill_address.landmark}</p>
                                              </li>
                                              <li>
                                                <strong>Pincode :</strong>
                                                <p>{orderData.bill_address.pin_code}</p>
                                              </li>
                                              <li>
                                                <strong className="font-weight-bold">
                                                  Phone Number :{" "}
                                                  <b>{orderData.bill_address.mobile_no}</b>
                                                </strong>
                                              </li>
                                              <li style={{ marginRight: "0px" }}>
                                                <strong className="font-weight-bold">
                                                  Supervisor :
                                                </strong>
                                                <span>         {orderData.bill_address.supervision_name +
                                "(" +
                                orderData.bill_address.supervision_mobile_no +
                                ")"}</span>
                                              </li>
                                            </ul>
                                            <ul>
                                              <strong className="set_color_dark_1">
                                                BILLING ADDRESS
                                              </strong>
                                              <li>
                                                <strong className="text_black">
                                                  {orderData.deli_address.user_name}
                                                  <span
                                                      className="badge badge-primary ml-2"
                                                      style={{
                                                        color: "#fff",
                                                        backgroundColor: "#047bd5",
                                                      }}
                                                  >
                                              {orderData.deli_address.address_type ===
                                              1
                                                  ? "Home"
                                                  : ""}
                                                    {orderData.deli_address.address_type ===
                                                    2
                                                        ? "Office"
                                                        : ""}
                                                    {orderData.deli_address.address_type ===
                                                    3
                                                        ? "Site"
                                                        : ""}
                                            </span>
                                                  <p>{`${orderData.deli_address.flat_no != null  ? orderData.deli_address.flat_no + ", " : ""}` +orderData.deli_address.address_1}</p>
                                                </strong>
                                              </li>
                                              <li>
                                                <strong>Landmark :</strong>
                                                <p>{orderData.deli_address.landmark}</p>
                                              </li>
                                              <li>
                                                <strong>Pincode :</strong>
                                                <p>{orderData.deli_address.pin_code}</p>
                                              </li>
                                              <li>
                                                <strong className="font-weight-bold">
                                                  Phone Number :{" "}
                                                  <b>{orderData.deli_address.mobile_no}</b>
                                                </strong>
                                              </li>
                                              {/* <li>
                                    <strong className='font-weight-bold'>
                                      Supervisor : <b>{orderData.billing_mobile_no}</b>
                                    </strong>
                                  </li> */}
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="payment-summary">
                                            <div className="d-flex title">
                                              <strong className="set_color_dark_1">
                                                Payment Summary
                                              </strong>
                                              <strong className="float-right text-success font-weight-bold">
                                                {item.order_status_name}
                                              </strong>
                                            </div>

                                            <div className="row">
                                              <div className="col-6">
                                                <p>Subtotal</p>
                                              </div>
                                              <div className="col-6">
                                                <p>
                                                  {this.props.symbol}{" "}
                                                  {parseFloat(
                                                      orderData.product_price_without_tax
                                                  ) *
                                                  parseFloat(
                                                      orderData.product_details
                                                          .order_quantity
                                                  )}
                                                </p>
                                              </div>
                                              <div className="col-6">
                                                <p>Shipping </p>
                                              </div>
                                              <div className="col-6">
                                                <p>
                                                  {this.props.symbol}{" "}
                                                  {orderData.product_ship_price}
                                                </p>
                                              </div>
                                              <div className="col-6">
                                                <p>Coupon Discount</p>
                                              </div>
                                              <div className="col-6">
                                                <p>
                                                  {this.props.symbol}{" "}
                                                  {orderData.coupon_total_new}
                                                </p>
                                              </div>
                                              <div className="col-6">
                                                <p>
                                                  <b>Grand Total</b>
                                                </p>
                                              </div>
                                              <div className="col-6">
                                                <p className="text-success">
                                                  {this.props.symbol}{" "}
                                                  {parseFloat(orderData.total_amount)}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="order-tracking">
                                            <h5 className="font-weight-bold">
                                              ORDER TRACKING
                                            </h5>
                                            {orderData &&
                                            orderData.product_details &&
                                            orderData.product_details.slot_details &&
                                            orderData.product_details.slot_details
                                                .length > 0 ? (
                                                orderData.product_details.slot_details.map(
                                                    (val, kay) => {
                                                      return (
                                                          <div className="mt-3">
                                                            <Steps
                                                                current={this.Ordernumber(
                                                                    orderData.product_details
                                                                        .order_status_id
                                                                )}
                                                                vertical
                                                            >
                                                              <Steps.Item
                                                                  title="Order Placed"
                                                                  description={moment(
                                                                      orderData.order_time
                                                                  ).format("YYYY-MM-DD")}
                                                              />
                                                              <Steps.Item
                                                                  title="Order Packed"
                                                                  description={moment(
                                                                      this.setTrackingDates(
                                                                          orderDeliveryDate,
                                                                          2
                                                                      )
                                                                  ).format("YYYY-MM-DD")}
                                                              />
                                                              <Steps.Item
                                                                  title="Order Shipped"
                                                                  description={moment(
                                                                      this.setTrackingDates(
                                                                          orderDeliveryDate,
                                                                          4
                                                                      )
                                                                  ).format("YYYY-MM-DD")}
                                                              />
                                                              <Steps.Item
                                                                  title="Order Delivered"
                                                                  description={moment(
                                                                      this.setTrackingDates(
                                                                          orderDeliveryDate,
                                                                          10
                                                                      )
                                                                  ).format("YYYY-MM-DD")}
                                                              />
                                                            </Steps>
                                                          </div>
                                                      );
                                                    }
                                                )
                                            ) : (
                                                <div className="mt-3">
                                                  <Steps
                                                      current={this.Ordernumber(
                                                          orderData.product_details
                                                              .order_status_id
                                                      )}
                                                      vertical
                                                  >
                                                    <Steps.Item
                                                        title="Order Placed"
                                                        description={moment(
                                                            orderData.order_time
                                                        ).format("YYYY-MM-DD")}
                                                    />
                                                    <Steps.Item
                                                        title="Order Packed"
                                                        description={moment(
                                                            this.setTrackingDates(
                                                                orderDeliveryDate,
                                                                2
                                                            )
                                                        ).format("YYYY-MM-DD")}
                                                    />
                                                    <Steps.Item
                                                        title="Order Shipped"
                                                        description={moment(
                                                            this.setTrackingDates(
                                                                orderDeliveryDate,
                                                                4
                                                            )
                                                        ).format("YYYY-MM-DD")}
                                                    />
                                                    <Steps.Item
                                                        title="Order Delivered"
                                                        description={moment(
                                                            this.setTrackingDates(
                                                                orderDeliveryDate,
                                                                10
                                                            )
                                                        ).format("YYYY-MM-DD")}
                                                    />
                                                  </Steps>
                                                </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="button-block">
                                            {orderData.product_details.return_day !==
                                            "0" &&
                                            this.Orderstatus2(
                                                orderData.product_details
                                                    .order_status_id
                                            )}
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/pages/contact`}
                                            >
                                              Contact support
                                            </Link>
                                          </div>
                                        </div>
                                        {/* {orderData.product_details.order_status_id == 7 ?
                                <div className='col-md-12'>
                                  <div className='addreview-btn'>
                                      <button className='btn btn-solid' style={{ width: "100%" }} >
                                    <h5 className=" mx-auto w-100 " onClick={() => { this.onCloseModalreview(1) }} > Add Review  </h5>
                                  </button>
                                  </div>
                                </div> : ""} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            );
                          })
                          : ""}
                    </div>
                  </div>
                  <div className="container-fluid cart-d-none">
                    <>
                      <div
                          className="row pt-1 outerBox"
                          // style={{
                          //   border: "1px solid rgba(0, 0, 0, 0.2)",
                          //   padding: "1rem",
                          // }}
                      >
                        <div className="col-md-12">
                          <div className="innerBox">
                            <div className="orderGrid">
                              <div className="orderGridItems">
                                <div className="orderRow">
                                  <div className="orderCol orderSpan">
                                    <div className="orderRow orderInnerFontTop">
                                  <span className="orderInnerSpanTop">
                                    {/* {item.order_status === null ? "Order Placed" : item.order_status} */}
                                    {"Order ID"}
                                  </span>
                                    </div>
                                    <div className="orderRow orderInnerFontBase">
                                  <span className="orderInnerSpanBase">
                                    {orderData.order_id}
                                    {/* {moment(item.order_time).format(
                                                "DD MMMM YYYY"
                                              )} */}
                                  </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="orderRow">
                                  <div className="orderCol orderSpan">
                                    <div className="orderRow orderInnerFontTop">
                                  <span className="orderInnerSpanTop">
                                    No. Of Products
                                  </span>
                                    </div>
                                    <div className="orderRow orderInnerFontBase">
                                  <span className="orderInnerSpanBase">
                                    {orderData.group_by &&
                                    orderData.group_by.length}
                                  </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="orderRow">
                                  <div className="orderCol orderSpan">
                                    <div className="orderRow orderInnerFontTop">
                                  <span className="orderInnerSpanTop">
                                    Grand Total
                                  </span>
                                    </div>
                                    <div className="orderRow orderInnerFontBase">
                                  <span className="orderInnerSpanBaseShip">
                                    {this.props.symbol}{" "}
                                    {orderData.total_amount.toFixed(2)}
                                  </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="orderRow">
                                  <div className="orderCol orderSpan">
                                    <div className="orderRow orderInnerFontTop">
                                  <span className="orderInnerSpanTop">
                                    Order Date
                                  </span>
                                    </div>
                                    <div className="orderRow orderInnerFontBase">
                                  <span className="orderInnerSpanBaseShip">
                                    {moment(orderData.order_time).format(
                                        "DD MMMM YYYY"
                                    )}
                                  </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="orderGridRight">
                                  <div
                                      className="orderRow orderInnerFontTop"
                                      style={{ padding: "0 0 0.8em" }}
                                  >
                                <span className="orderInnerSpanTop">
                                  {/* <button className='btn btn-solid2' onClick={() => ""}>INVOICE</button> */}
                                  {/* <a
                                      className="btn btn-solid2"
                                      href={`https://beta.infrawheel.com/admin/sellerorder/generateInvoice/${orderData.id}`}
                                      target={"_blank"}
                                  >
                                    INVOICE
                                  </a> */}
                                </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {orderData.group_by && orderData.group_by.length > 0
                            ? orderData.group_by.map((item, index) => {
                              total = total + parseInt(item.product_price_with_tax);
                              return (
                                  <div className="col-md-12 bg-white">
                                    {item.product_detail.map((value) => (
                                        <div className="shipmentBox">
                                          <div className="shipmentBoxInner">
                                            <div className="orderRow shipmentInner">
                                              <div className="row">
                                                <div className="col-md-1">
                                                  <Link
                                                      to={`${process.env.PUBLIC_URL}/${item.product_details.product_slug}/p/${item.product_id}`}
                                                  >
                                                    <img
                                                        src={value.product_image}
                                                        id="my-order-img"
                                                        width="100px"
                                                        height="100px"
                                                        alt=""
                                                    />
                                                  </Link>
                                                </div>

                                                <div className="col-md-2 col-lg ml-3">
                                                  <strong className="d-block">
                                                    {value.product_name}
                                                  </strong>{" "}
                                                  {/* <strong>Size: L</strong> <br />
                                        <strong>Order type: Retail</strong> <br />
                                        <strong>Type: OPC</strong> <br />
                                        <strong>Grade: 53 Grade</strong> */}
                                                  {item &&
                                                  item.option_data &&
                                                  item.option_data.length > 0
                                                      ? item.option_data.map(
                                                          (opt, i) => {
                                                            return (
                                                                <strong className="d-block">
                                                                  {opt.option_name
                                                                      ? opt.option_name +
                                                                      ": " +
                                                                      opt.option_value_name
                                                                      : ""}
                                                                </strong>
                                                            );
                                                          }
                                                      )
                                                      : ""}
                                                  <strong className="">
                                                    Order Type:{" "}
                                                    <span
                                                        className={
                                                          item.product_details
                                                              .is_retail == 1
                                                              ? "check___Retail"
                                                              : "check___Wholesale"
                                                        }
                                                    >
                                              {item.product_details
                                                  .is_retail == 1
                                                  ? "Retail"
                                                  : "Wholesale"}
                                            </span>
                                                  </strong>
                                                  {/* {this.Orderstatus_text(
                                                      value.order_status_id,
                                                      value.order_status_name
                                                  )} */}
                                                  <strong className="d-block order-list-list-optoin">
                                                    Shipping Price:{" "}
                                                    <span
                                                        className={
                                                          "order-list-list-optoin"
                                                        }
                                                    >
                                              {item.product_details
                                                  .product_ship_price != 0
                                                  ? this.props.symbol +
                                                  " " +
                                                  item.product_details
                                                      .product_ship_price
                                                  : "Free"}
                                            </span>
                                                  </strong>
                                                  <strong className="d-block order-list-list-optoin">
                                       
                                       <span className='text_bold'>
                                                  {parseInt(item.product_details.return_day[0]) === 0 ? "Non-Returnable Product" : `Returnable Product in ${item.product_details.return_day} Day(s)`}
                                                  </span></strong>
                                                 
                                       
                                     

                                                </div>

                                                <div className="col-md-1 text-center my-auto text_color">
                                                  <strong className="d-block text-uppercase">
                                                    Quantity
                                                  </strong>
                                                  <span className="d-block">
                                            {value.order_quantity}
                                          </span>
                                                </div>

                                                <div className="col-md-2 text-center my-auto ">
                                                  <strong className="d-block text-uppercase">
                                                    Unit Price
                                                  </strong>
                                                  <span className="d-block">
                                            {this.props.symbol}{" "}
                                                    {parseFloat(
                                                        item.product_details
                                                            .product_main_price
                                                    ).toFixed(2)}/{item.product_details
                                                      .unit_name}
                                          </span>
                                                </div>
                                                
                                                <div className="col-md-2 text-center my-auto text_color">
                                                  <strong className="d-block text-uppercase">
                                                    Price
                                                  </strong>
                                                  <span className="d-block">
                                            {this.props.symbol}{" "}
                                                    {parseFloat(
                                                        item.product_details
                                                            .product_price_total
                                                    ).toFixed(2)}
                                          </span>
                                                </div>

                                                <div className="col-md-2 text-center my-auto mr-5 ">
                                                  {value.order_status_id == 5 ||
                                                  value.order_status_id == 7 ? (
                                                      <button
                                                          className={
                                                            value.can_rated == 0
                                                                ? "btn btn-solid2 btn-sm text-uppercase"
                                                                : "btn btn-solid2 btn-sm text-uppercase disabled"
                                                          }
                                                          onClick={() =>
                                                              value.can_rated == 0
                                                                  ? this.onCloseModalreview(
                                                                  1,
                                                                  value.id,
                                                                  value.product_id
                                                                  )
                                                                  : ""
                                                          }
                                                      >
                                                        Give Review
                                                      </button>
                                                  ) : (
                                                      ""
                                                  )}
                                                  <strong className="d-block text-uppercase">
                                                    Order Status
                                                  </strong>
                                                  <span className="d-block">
                                            {value.order_status_name}
                                          </span>
                                                </div>
                                                {item.isOrderCancel == "false" ||
                                                item.isOrderCancel == "" ? (
                                                    <div className="col-md-2 text-center my-auto">
                                                      <div className="row">
                                                        <div className="col-md-12 p-2">
                                                          <button
                                                              className="btn btn-light theme_text_set w-100"
                                                              onClick={() =>
                                                                  this.setState({
                                                                    trackingModal: 1,
                                                                    trackData: item
                                                                  })
                                                                  // console.log("first", orderData, item)
                                                              }
                                                          >
                                                            TRACK ORDER
                                                          </button>
                                                        </div>
                                                        <div className="col-md-12 p-2">
                                                          {/* <button className='btn btn-light bg-danger w-100 text-white'>
                                            CANCEL
                                          </button> */}
                                                          {value.return_day !== "0" &&
                                                          this.Orderstatus(
                                                              value.order_status_id,
                                                              value.id,
                                                              item
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {value.product_cancel_reason_text
                                                    .length > 0 ? (
                                                    <div
                                                        className="col-md-3 "
                                                        style={{ marginLeft: "29rem" }}
                                                    >
                                                      <strong className="d-block text-uppercase">
                                                        Cancel Reason
                                                      </strong>
                                                      <p>
                                                        {value.product_cancel_reason_text}
                                                      </p>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    ))}
                                  </div>
                              );
                            })
                            : ""}

                        <div
                            className="col-md-12"
                            style={{ backgroundColor: "#F0F2F2 !important" }}
                        >
                          {orderData.group_by.map((value) =>
                              value.product_detail.map((item) =>
                                  item.slot_details.length > 0 ? (
                                      <>
                                        <div className="row p-1">
                                          <div className="col-md-2">
                                            <strong>Delivery Slot :</strong>
                                          </div>
                                          {item &&
                                          item.slot_details.map((value) => {
                                            return (
                                                <div className="col-md-1 text-center">
                                                  {value.slot_no}
                                                </div>
                                            );
                                          })}
                                        </div>
                                        <div className="row p-1">
                                          <div className="col-md-2">
                                            <strong>Quantity :</strong>
                                          </div>
                                          {item &&
                                          item.slot_details.map((value) => {
                                            return (
                                                <div className="col-md-1 text-center">
                                                  {value.slot_quantity}
                                                </div>
                                            );
                                          })}
                                        </div>
                                        <div className="row p-1">
                                          <div className="col-md-2">
                                            <strong>Date :</strong>
                                          </div>
                                          {item &&
                                          item.slot_details.map((value) => {
                                            return (
                                                <div className="col-md-1">
                                                  {moment(value.delivery_date).format(
                                                      "DD-MM-YYYY"
                                                  )}
                                                </div>
                                            );
                                          })}
                                        </div>
                                      </>
                                  ) : (
                                      ""
                                  )
                              )
                          )}
                        </div>
                      </div>

                      <div class="row">
                        {/* {orderData &&
                          orderData.product_details &&
                          orderData.product_details.slot_details &&
                          orderData.product_details.slot_details.length > 0 ? (
                          orderData.product_details.slot_details.map((val, kay) => {
                            return (
                              <div
                                class='col-md'
                                style={{
                                  border: "1px solid rgba(0, 0, 0, 0.2)",
                                  marginTop: "1rem",
                                }}
                              >
                                <h4
                                  className='font-weight-bold'
                                  style={{ padding: "0.5rem" }}
                                >
                                  ORDER TRACKING
                                </h4>
                                <div>
                                  <Steps current={this.Ordernumber(orderData.product_details.order_status_id)} vertical>
                                    <Steps.Item
                                      title='Order Placed'
                                      description={moment(orderData.order_time).format("YYYY-MM-DD")}
                                    />
                                    <Steps.Item
                                      title='Order Packed'
                                      description={moment(this.setTrackingDates(orderDeliveryDate, 2)).format("YYYY-MM-DD")}
                                    />
                                    <Steps.Item
                                      title='Order Shipped'
                                      description={moment(this.setTrackingDates(orderDeliveryDate, 4)).format("YYYY-MM-DD")}
                                    />
                                    <Steps.Item
                                      title='Order Delivered'
                                      description={moment(this.setTrackingDates(orderDeliveryDate, 10)).format("YYYY-MM-DD")}
                                    />
                                  </Steps>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div
                            class='col-md'
                            style={{
                              border: "1px solid rgba(0, 0, 0, 0.2)",
                              marginTop: "1rem",
                            }}
                          >
                            <h4
                              className='font-weight-bold'
                              style={{ padding: "0.5rem" }}
                            >
                              ORDER TRACKING
                            </h4>
                            <div>

                              <Steps current={this.Ordernumber(orderData.product_details.order_status_id)} vertical>
                                <Steps.Item
                                  title='Order Placed'
                                  // description={moment(
                                  //   orderData.product_delivery_date
                                  // ).format("YYYY-MM-DD")}
                                  description={moment(orderData.order_time).format("YYYY-MM-DD")}
                                />
                                <Steps.Item
                                  title='Order Packed'
                                  description={moment(this.setTrackingDates(orderDeliveryDate, 2)).format("YYYY-MM-DD")}
                                />
                                <Steps.Item
                                  title='Order Shipped'
                                  description={moment(this.setTrackingDates(orderDeliveryDate, 4)).format("YYYY-MM-DD")}
                                />
                                <Steps.Item
                                  title='Order Delivered'
                                  description={moment(this.setTrackingDates(orderDeliveryDate, 10)).format("YYYY-MM-DD")}
                                />
                              </Steps>
                            </div>
                          </div>
                        )} */}

                        <div
                            class="col-md"
                            style={{
                              border: "1px solid rgba(0, 0, 0, 0.2)",
                              margin: "1rem 0rem 0rem 0rem",
                            }}
                        >
                          <h4
                              className="font-weight-bold"
                              style={{ paddingTop: "0.5rem" }}
                          >
                            DELIVERY ADDRESS
                          </h4>
                          <ul className="range-steps">
                            <li>
                            <strong className="text_black">
                                                  {orderData.bill_address.user_name}
                                                  <span
                                                      className="badge badge-primary ml-2"
                                                      style={{
                                                        color: "#fff",
                                                        backgroundColor: "#047bd5",
                                                      }}
                                                  >
                                              {orderData.bill_address.address_type === 1
                                                  ? "Home"
                                                  : ""}
                                                    {orderData.bill_address.address_type === 2
                                                        ? "Office"
                                                        : ""}
                                                    {orderData.bill_address.address_type === 3
                                                        ? "Site"
                                                        : ""}
                                            </span>
                                                  <p>
                                                  {`${orderData.bill_address.flat_no != null  ? orderData.bill_address.flat_no + ", " : ""}` +orderData.bill_address.address_1}{" "}
                                                  </p>
                                                </strong>
                            </li>
                            <li>
                                                <strong>Landmark :</strong>
                                                <span>{orderData.bill_address.landmark}</span>
                                              </li>
                            <li>
                              <strong>Pincode :</strong>{" "}
                              <span>{orderData.bill_address.pin_code} </span>
                            </li>
                            <li>
                              <strong className="font-weight-bold">
                                Phone Number : <b>{orderData.bill_address.mobile_no}</b>
                              </strong>
                            </li>
                            <li style={{ marginRight: "0px" }}>
                              <strong className="font-weight-bold">
                                Supervisor :
                              </strong>
                              <span>
                            {" "}
                                {orderData.bill_address.supervision_name +
                                "(" +
                                orderData.bill_address.supervision_mobile_no +
                                ")"}
                          </span>
                            </li>
                          </ul>
                        </div>
                        <div
                            className="col-md"
                            style={{
                              border: "1px solid rgba(0, 0, 0, 0.2)",
                              margin: "1rem 0rem 0rem 1rem",
                            }}
                        >
                          <h4
                              className="font-weight-bold"
                              style={{ paddingTop: "0.5rem" }}
                          >
                            BILLING ADDRESS
                          </h4>
                          <ul className="range-steps">
                            <li>
                            <strong className="text_black">
                                                  {orderData.deli_address.user_name}
                                                  <span
                                                      className="badge badge-primary ml-2"
                                                      style={{
                                                        color: "#fff",
                                                        backgroundColor: "#047bd5",
                                                      }}
                                                  >
                                              {orderData.deli_address.address_type ===
                                              1
                                                  ? "Home"
                                                  : ""}
                                                    {orderData.deli_address.address_type ===
                                                    2
                                                        ? "Office"
                                                        : ""}
                                                    {orderData.deli_address.address_type ===
                                                    3
                                                        ? "Site"
                                                        : ""}
                                            </span>
                                                  <p>{`${orderData.deli_address.flat_no != null  ? orderData.deli_address.flat_no + ", " : ""}` +orderData.deli_address.address_1}</p>
                                                </strong>
                            </li>
                            <li>
                              <strong>Landmark : </strong>
                              <span>{orderData.deli_address.landmark}</span>
                            </li>
                            <li>
                              <strong>Pincode : </strong>
                              <span>{orderData.deli_address.pin_code}</span>
                            </li>
                            <li>
                              <strong className="font-weight-bold">
                                Phone Number : <b>{orderData.deli_address.mobile_no}</b>
                              </strong>
                            </li>
                            {/* <li style={{ marginRight: "0px" }}>
                              <strong className="font-weight-bold">
                                Supervisor :{" "}
                              </strong>
                              <span>
                            {orderData.billing_supervision_name +
                            "(" +
                            orderData.billing_supervision_mobile_no +
                            ")"}
                          </span>
                            </li> */}
                          </ul>
                        </div>
                        <div
                            class="col-md"
                            style={{
                              border: "1px solid rgba(0, 0, 0, 0.2)",
                              margin: "1rem 0rem 0rem 1rem",
                            }}
                        >
                          <div className="row">
                            <div className="col-8">
                              <h4
                                  className="font-weight-bold"
                                  style={{
                                    paddingTop: "0.5rem",
                                    paddingBottom: "1.5rem",
                                  }}
                              >
                                PAYMENT SUMMARY
                              </h4>
                            </div>
                            {/* <div className="col-4">
                              {(orderData && orderData.product_details && orderData.product_details.slot_details && orderData.product_details.slot_details.length === 0 || orderData.product_details.slot_details.length > 0) ?
                                <h6 className="pt-2 pb-4" id="invoice"
                                  onClick={() => window.open(ApiConstants.BASE_URL + "/sellerorder/generateInvoice/" + orderData.id, "_blank")}>Invoice</h6>
                                : ""}
                            </div> */}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <p style={{ paddingBottom: "0.5rem" }}>Subtotal</p>
                            </div>
                            <div className="col-md-6">
                              <p className="float-right">
                                {this.props.symbol}
                                {orderData &&
                                orderData.product_details &&
                                orderData.product_details.total_product_price
                                    ? parseFloat(
                                        orderData.product_details.total_product_price
                                    ).toFixed(2)
                                    : ""}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p style={{ paddingBottom: "0.5rem" }}>Shipping </p>
                            </div>
                            <div className="col-md-6">
                              <p className="float-right">
                                {this.props.symbol}{" "}
                                {parseFloat(_ship_price || 0).toFixed(2)}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p style={{ paddingBottom: "0.5rem" }}>
                                Coupon Discount
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p className="float-right">
                                {this.props.symbol}{" "}
                                {orderData.coupon_total_new.toFixed(2)}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <b style={{ paddingBottom: "0.5rem" }}>Grand Total</b>
                            </div>
                            <div className="col-md-6">
                              <p className="float-right text-success">
                                {this.props.symbol}{" "}
                                {parseFloat(orderData.total_amount).toFixed(2)}
                              </p>
                            </div>

                            {/* <div className='col-12 text-center ' style={{ paddingTop: "2rem" }}>

                              {this.Orderstatus(orderData.product_details.order_status_id)}

                              <Link to={`${process.env.PUBLIC_URL}/pages/contact`}>Contact support</Link>
                              <Link to={`${process.env.PUBLIC_URL}/${orderData.product_details.product_name}/p/${orderData.product_id}`}>
                                <h4 className="mx-auto">Re-Order</h4>
                              </Link>

                            </div> */}

                            {/* {orderData.product_details.order_status_id == 5 ?
                              <div
                                className='col-md-12 text-center'
                                style={{ paddingTop: "2rem" }}
                              >
                                <button className='btn btn-solid' style={{ width: "100%" }} >
                                  <h5 className=" mx-auto w-100 " onClick={() => { this.onCloseModalreview(1) }} > Add Review  </h5>
                                </button>
                              </div> : ""} */}
                          </div>
                        </div>
                      </div>
                    </>
                  </div>

                  <Modal
                      size="lg"
                      show={this.state.popup}
                      onHide={() => {
                        this.onCloseModal(0, "");
                      }}
                      
                  >
                    <Modal.Header>
                      <Modal.Title>
                        {" "}
                        <h5 className="modal-title">
                          Reason for{" "}
                          {this.state.popup == 1 ? "Cancellation" : "Return"}
                        </h5>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-sm-12">
                     
                          <div className="w-100">
                            <form>
                              {/* {this.state.popup == 2 ? (
                                  <div className="form-group">
                                    <label>
                                      {this.state.imgs && [...this.state.imgs] ? (
                                          [...this.state.imgs].map((file) => (
                                              <img
                                                  className="img-thumbnail"
                                                  style={{ width: 100, height: 100 }}
                                                  src={URL.createObjectURL(file)}
                                              />
                                          ))
                                      ) : (
                                          <img
                                              className="img-thumbnail"
                                              style={{ width: 100, height: 100 }}
                                              src={addimage}
                                          />
                                      )}

                                      <input
                                          className="d-none"
                                          id="upload"
                                          ref="file"
                                          type="file"
                                          accept="image/x-png,image/gif,image/jpeg"
                                          onChange={this.onChange}
                                      />
                                      <p
                                          htmlFor="upload"
                                          style={{ cursor: "pointer" }}
                                          className="browse_file_buttone"
                                      >
                                        Browse
                                      </p>
                                    </label>
                                  </div>
                              ) : (
                                  ""
                              )} */}
                              <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">
                                  Reason{" "}
                                </label>

                                <textarea
                                    placeholder="Reason .."
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    onChange={(e) => {
                                      this.setState({ reason_text: e.target.value });
                                    }}
                                    rows="5"
                                ></textarea>
                                <span> minimum 20 letters requirement</span>
                              </div>
                            </form>

                            <button
                                className="btn btn-danger"
                                disabled={
                                  this.state.reason_text
                                      .replace(/\n/g, "")
                                      .replace(/\s/g, "").length <= 19 || this.state.disable_cancel_btn
                                }
                                onClick={(e) => {
                                  this.cancelandretunorders(e);
                                }}
                              
                            >
                              {" "}
                              Submit{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <Modal
                      className="orderDetMod"
                      size="lg"
                      show={this.state.trackingModal}
                      onHide={() => {
                        this.setState({ trackingModal: 0 });
                      }}
                  >
                    {/* <Modal.Header>
                  <Modal.Title> <h5 className="modal-title">Reason for new modal {this.state.popup == 1 ? "Cancellation" : "Return"}</h5></Modal.Title>
                </Modal.Header> */}
                    <Modal.Body>
                    <div className="close_btn" onClick={() => this.setState({ trackingModal: 0 })}>
                        <img src={cross} alt="" />
                      </div>
                      <div className="row">
                        { trackData&&
                        trackData.product_details &&
                        trackData.product_details.slot_details &&
                        trackData.product_details.slot_details.length > 0 ? (
                            <div
                                class="col-md-3 ml-3 ordleft"
                                style={{
                                  border: "1px solid rgba(0, 0, 0, 0.2)",
                                }}
                            >
                              <h4
                                  className="font-weight-bold"
                                  style={{ padding: "0.5rem" }}
                              >
                                ORDER TRACKING
                              </h4>
                              <div>
                                <Steps
                                    current={this.Ordernumber(
                                        trackData.product_details.order_status_id
                                    )}
                                    vertical
                                >
                                  <Steps.Item
                                      title="Order Placed"
                                      description={moment(trackData.order_time).format(
                                          "YYYY-MM-DD"
                                      )}
                                  />
                                  <Steps.Item
                                      title="Order Packed"
                                      description={
                                        trackData.order_tracking_details &&
                                        trackData.order_tracking_details.length > 0
                                            ? trackData.order_tracking_details[1] &&
                                            moment(
                                                trackData.order_tracking_details[1]
                                                    .date_added
                                            ).format("YYYY-MM-DD")
                                            : ""
                                      }
                                  />
                                  <Steps.Item
                                      title="Order Shipped"
                                      description={
                                        trackData.order_tracking_details &&
                                        trackData.order_tracking_details.length > 0
                                            ? trackData.order_tracking_details[2] &&
                                            moment(
                                                trackData.order_tracking_details[2]
                                                    .date_added
                                            ).format("YYYY-MM-DD")
                                            : ""
                                      }
                                  />
                                  <Steps.Item
                                      title="Order Delivered"
                                      description={
                                        trackData.order_tracking_details &&
                                        trackData.order_tracking_details.length > 0
                                            ? trackData.order_tracking_details[3] &&
                                            moment(
                                                trackData.order_tracking_details[3]
                                                    .date_added
                                            ).format("YYYY-MM-DD")
                                            : ""
                                      }
                                  />
                                </Steps>
                              </div>
                            </div>
                        ) : (
                            <div
                                class="col-md-3 ml-3 ordleft"
                                style={{
                                  border: "1px solid rgba(0, 0, 0, 0.2)",
                                }}
                            >
                              <div className="ordeLeftUp">
                                <h4
                                    className="font-weight-bold"
                                    style={{ padding: "0.5rem" }}
                                >
                                  ORDER TRACKING
                                </h4>
                                <div>
                                  <Steps
                                      current={this.Ordernumber(
                                          trackData.product_details.order_status_id
                                      )}
                                      vertical
                                  >
                                    <Steps.Item
                                        title="Order Placed"
                                        // description={moment(
                                        //   orderData.product_delivery_date
                                        // ).format("YYYY-MM-DD")}
                                        // description={moment(
                                        //     trackData.order_time
                                        // ).format("YYYY-MM-DD")}
                                    />
                                    <Steps.Item
                                        title="Order Packed"
                                        // description={moment(this.setTrackingDates(orderDeliveryDate, 2)).format("YYYY-MM-DD")}
                                        // description={
                                        //   trackData.order_tracking_details &&
                                        //   trackData.order_tracking_details.length > 0
                                        //       ? trackData.order_tracking_details[1] &&
                                        //       moment(
                                        //           trackData.order_tracking_details[1]
                                        //               .date_added
                                        //       ).format("YYYY-MM-DD")
                                        //       : ""
                                        // }
                                    />
                                    <Steps.Item
                                        title="Order Shipped"
                                        // description={
                                        //   trackData.order_tracking_details &&
                                        //   trackData.order_tracking_details.length > 0
                                        //       ? trackData.order_tracking_details[2] &&
                                        //       moment(
                                        //           trackData.order_tracking_details[2]
                                        //               .date_added
                                        //       ).format("YYYY-MM-DD")
                                        //       : ""
                                        // }
                                    />
                                    <Steps.Item
                                        title="Order Delivered"
                                        // description={
                                        //   trackData.order_tracking_details &&
                                        //   trackData.order_tracking_details.length > 0
                                        //       ? trackData.order_tracking_details[3] &&
                                        //       moment(
                                        //           trackData.order_tracking_details[3]
                                        //               .date_added
                                        //       ).format("YYYY-MM-DD")
                                        //       : ""
                                        // }
                                    />
                                  </Steps>
                                </div>
                              </div>
                            </div>
                        )}
                        <div
                            className="col-md-9 ml-3 ordright"
                            style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
                        >
                          <div className="ordeRightUp">
                            <div className="order_top">
                                <div>
                                      <p>Delivery OTP</p>
                                      <h3>{trackData.product_detail[0].otp}</h3>
                                </div>
                                <div className="order_top_btn">
                                    <a download target="_blank" href={trackData.invoice_main_link}> <img src={download_icon} alt="download_icon" /> INVOICE </a>
                                </div>
                            </div>
                            <h4
                                className="font-weight-bold"
                                style={{ padding: "0.5rem" }}
                            >
                              ORDER CANCEL / RETURN / REPLACE DETAILS
                            </h4>
                            <table className="table table-borderless orderDet">
                              <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Refund</th>
                                <th scope="col">Status</th>
                                <th scope="col">Reason</th>
                              </tr>
                              </thead>
                              <tbody>
                              {trackData.order_tracking_details &&
                              trackData.order_tracking_details.length > 0
                                  ? trackData.order_tracking_details.map((item) => {
                                    return (
                                        <tr>
                                          <td scope="row">
                                            {moment(item.date_added).format(
                                                "DD-MM-YYYY"
                                            )}
                                          </td>
                                          <td>-</td>
                                          <td className="text-danger">
                                            {item.name}
                                          </td>
                                          <td>
                                            <p>{item.comment}</p>
                                          </td>
                                        </tr>
                                    );
                                    // <tr>
                                    //   <td scope="row">27-11-2021</td>
                                    //   <td>-</td>
                                    //   <td>Picked-up</td>
                                    //   <td>-</td>
                                    // </tr>
                                    // <tr>
                                    //   <td scope="row">29-11-2021</td>
                                    //   <td>719.12</td>
                                    //   <td className='text-success'>Refunded</td>
                                    //   <td>-</td>
                                    // </tr>
                                  })
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <Modal
                      className="modal-RateIN"
                      size="md"
                      show={this.state.reviewpopup}
                      onHide={() => {
                        this.onCloseModalreview(0, "", "");
                      }}
                  >
                    <Modal.Header>
                      <Modal.Title>
                        {" "}
                        <h5 className="modal-title">Product Review</h5>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="rateIn">
                            <form>
                              <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">
                                  {" "}
                                  Review{" "}
                                </label>

                                <textarea
                                    placeholder="Review .."
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    onChange={(e) => {
                                      this.setState({ review_text: e.target.value });
                                    }}
                                    rows="5"
                                ></textarea>
                                <span> minimum 2 letters requirement</span>
                              </div>

                              <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">
                                  {" "}
                                  Rating{" "}
                                </label>
                                <ReactStars
                                    count={5}
                                    onChange={(e) => {
                                      this.setState({ rating: e });
                                    }}
                                    size={40}
                                    activeColor="#ffd700"
                                    value={this.state.rating}
                                />
                                <button
                                    className="btn btn-solid"
                                    disabled={
                                      this.state.review_text
                                          .replace(/\n/g, "")
                                          .replace(/\s/g, "").length <= 1
                                    }
                                    onClick={(e) => {
                                      this.addreview(e);
                                    }}
                                >
                                  {" "}
                                  Submit{" "}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </>
            )}
            {this.state.loaderForOrderDetail && (
                <div className="row">
                  <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                    <div className="loading-cls" />
                    <p></p>
                  </div>
                </div>
            )}
          </section>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.auth.userData.id,
  symbol: state.data.symbol,
});

export default connect(mapStateToProps)(NewOrderDetails);

import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {getAboutUspage} from "../../actions";
import {connect} from "react-redux";
import {Askme_api} from "../../api/pages";
import {toast} from "react-toastify";
import * as storageUtils from "../../utils/storageUtils";

class Contact extends Component {

    componentDidMount() {
        this.props.getAboutUspage({'content_type':6});


        try {
            if(window.location.href.search("sp") >= 1)
            {
            document.getElementById("footer_id").setAttribute("style","display:none");
            document.getElementById("header_id").setAttribute("style","display:none");
            document.getElementById("breadcrumb_id").setAttribute("style","display:none");
            }
          }
          catch(err) {
            
          }


    }

    constructor(props) {
        super(props);

        this.state = {
            otp: "sdas",
        };
    }

    ContactSubmit = async (e) => {
        e.preventDefault()
        try {
            this.setState({updateProfileLoader: true});
            const Ask = await Askme_api({
                name: (this.state.first_name||"")+" "+(this.state.last_name||""),
                phone: this.state.phone_number||"",
                email: this.state.email||"",
                message: this.state.Message||"",
            });

            if(Ask.success)
            {

                this.setState({
                    first_name: "",
                    last_name: "",
                    phone_number: "",
                    email: "",
                    Message: "",
                });

                document.getElementById("askme").reset();
            }
            toast.success(Ask.message);
        } catch (error) {
            toast.error(error.message);
        }
    };

    render (){

        const {aboutUs} = this.props;
        const {store_address,store_phone,store_email,store_name,instagram_link,youtube_link,twitter_link,facebook_link,footer_text} = this.props.settings;

        return (
            <div>

                { window.location.href.search("sp") <= 1? <Breadcrumb title={'Contact Us'}/>:"" }
                
                {/*Forget Password section*/}
                <section className=" contact-page section-b-space">
                    <div className="container">
                        <div className="row section-b-space cart-d-none">
                            <div className="col-lg-7 map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d50059.12775918716!2d72.78534673554945!3d21.16564923510817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1533793756956"
                                    allowFullScreen></iframe>
                            </div>
                            <div className="col-lg-5">
                                <div className="contact-right">
                                    <ul>
                                        <li>
                                            <div className="contact-icon">
                                                {/* <img src={`${process.env.PUBLIC_URL}/assets/images/icon/phone.png`} alt="Generic placeholder image" /> */}
                                                <i class="fa fa-phone fa-2x" aria-hidden="true"></i>
                                                    <h6>Contact Us</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>{store_phone}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                <h6>Address</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>{store_address}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                                {/* <img src={`${process.env.PUBLIC_URL}/assets/images/icon/email.png`} alt="Generic placeholder image" /> */}
                                                    <h6>Email</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>{store_email}</p>
                                            </div>
                                        </li>
                                        {/* <li>
                                            <div className="contact-icon">
                                                <i className="fa fa-fax" aria-hidden="true"></i>
                                                <h6>Fax</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>Support@Shopcart.com</p>
                                                <p>info@shopcart.com</p>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row cart-d-none-mobile">
                            <div className="col-12">

                            <iframe width="100%" height="300px"
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d50059.12775918716!2d72.78534673554945!3d21.16564923510817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1533793756956"
                                    allowFullScreen></iframe>
                            </div>
                            <div className="col-12 mt-3 mb-2">
                                <div className="row">
                                    <div className="col-4 text-center my-auto">
                                        <i class="fa fa-phone fa-2x theme_text_set" aria-hidden="true"></i>
                                        <h6 className="theme_text_set">Contact Us</h6>
                                    </div>
                                    <div className="col-8">
                                        <div className="contact-right">
                                            <ul>
                                                <li>
                                                <div className="media-body">
                                                    <p>+91 123 - 456 - 7890</p>
                                                    <p>+86 163 - 451 - 7894</p>
                                                </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-4 text-center my-auto">
                                        <i class="fa fa-map-marker fa-2x theme_text_set" aria-hidden="true"></i>
                                        <h6 className="theme_text_set">Address</h6>
                                    </div>
                                    <div className="col-8">
                                        <div className="contact-right">
                                            <ul>
                                                <li>
                                                <div className="media-body">
                                                    <p>ABC Complex,Near xyz, New York</p>
                                                    <p>USA 123456</p>
                                                </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-4 text-center my-auto">
                                        <i class="fa fa-envelope fa-2x theme_text_set" aria-hidden="true"></i>
                                        <h6 className="theme_text_set">Email</h6>
                                    </div>
                                    <div className="col-8">
                                        <div className="contact-right">
                                            <ul>
                                                <li>
                                                <div className="media-body">
                                                    <p>Support@Shopcart.com</p>
                                                    <p>info@shopcart.com</p>
                                                </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">

                                <form id="askme" className="theme-form" onSubmit={(e)=>{this.ContactSubmit(e)}}>
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <label htmlFor="name">First Name</label>
                                            <input type="text" className="form-control"
                                                   onBlur={(e) =>this.setState({first_name: e.target.value})}
                                                   id="name"
                                                   placeholder="Enter Your name"
                                                   required="" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="email">Last Name</label>
                                            <input type="text" onBlur={(e) =>this.setState({last_name: e.target.value})} className="form-control" id="last-name" placeholder="Last Name" required="" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="review">Phone number</label>
                                            <input type="text" className="form-control" onBlur={(e) =>this.setState({phone_number: e.target.value})} id="review" placeholder="Enter your number" required="" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" className="form-control" onBlur={(e) =>this.setState({email: e.target.value})} id="email" placeholder="Email" required="" />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="review">Write Your Message</label>
                                            <textarea className="form-control" onBlur={(e) =>this.setState({Message: e.target.value})} placeholder="Write Your Message" id="exampleFormControlTextarea1" rows="6"></textarea>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-solid w-100 rounded" type="submit">Send Your Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    aboutUs: state.pages.aboutUs,
    settings : state.settings,

});

const mapDispatchToProps = {getAboutUspage};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
